import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import MarketingContext from '../Context/Marketing/MarketingContext';
import AdvertisingLayout from '../Components/Layouts/AdvertisingLayout';
import ContractsDashboardWrapper from '../Components/Contracts/components/DashBoard/ContractsDashboardWrapper';
import ContractsPage from '../Components/Contracts/ContractsPage/ContractsPage';
import PerformanceDashboard from '../Components/Sales/components/PerformanceDashboard/PerformanceDashboard';
import PublicationProductsExport from '../Components/Publications/components/PublicationDetails/PublicationProductsExport';
import PublicationContextV2 from '../Context/Marketing/PublicationContextV2';
import MediaKitContext from '../Components/Manufacturers/components/MediaKit/MediaKitContext';
import MediaKit from '../Components/Manufacturers/components/MediaKit/MediaKit';
import Deposits from '../Components/Manufacturers/components/Deposits/Deposits';
import PublicationDetails from '../Components/Publications/components/PublicationDetails/PublicationDetails';
import PublicationsV2 from '../Components/Publications/PublicationsV2';
import ContractCreatedEmail from '../Components/Contracts/components/ContractCreatedEmail';

const AdvertisingRoutes = () => {
  // INIT's
  const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <>
      <MarketingContext>
        <AdvertisingLayout>
          <Routes>
            <Route path='/contracts' element={<ContractsPage />} />
            <Route path='/product/performance-dashboard' element={<PerformanceDashboard />} />

            {userPermission[986] && (
              <Route
                path='/media-kit'
                element={
                  <PublicationContextV2>
                    <MediaKitContext>
                      <MediaKit />
                    </MediaKitContext>
                  </PublicationContextV2>
                }
              />
            )}

            <Route path='/contracts/created-email' element={<ContractCreatedEmail />} />

            <Route
              path='/publications/publication-products'
              element={<PublicationProductsExport />}
            />
            {userPermission[985] && <Route path='/deposits' element={<Deposits />} />}

            <Route path='/view-publication/:pub_id' element={<PublicationDetails />} />

            {userPermission[987] && <Route path='/publications/v2' element={<PublicationsV2 />} />}
          </Routes>
        </AdvertisingLayout>
      </MarketingContext>
    </>
  );
};

export default AdvertisingRoutes;
