import { AttachMoney } from '@mui/icons-material';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ContractPaymentForm from './ContractPaymentForm';
import { MarketingContextState } from '../../../../../../Context/Marketing/MarketingContext';

// MUI STYLE OBJ
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const ContractPayButton = ({ item }) => {
  // CONTEXT STATE
  const { setRefreshContractData } = useContext(MarketingContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [open, setOpen] = React.useState(false);

  // INIT's
  const theme = useTheme();

  // FUNCTIONS
  const process = async () => {
    setLoading(false);
  };

  const handleClose = (res) => {
    setRefreshContractData((prev) => prev + 1); //alert(`All's good.... ${res.InsertId}`);
    setOpen(false);
    console.log('res on close of modal: ', res);
    //res.affectedRows > 0 && setRefreshContractData((prev) => prev + 1); //alert(`All's good.... ${res.InsertId}`);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  // USE EFFECT
  useEffect(() => {
    process();
  }, [item]);

  if (loading) {
    return <div>... loading</div>;
  }

  return (
    <>
      {item.ss_publication_payment_status_id === 1 ? (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            sx={{
              fontSize: '.8em',
              color: theme.palette.delete.main,
              backgroundColor: '#ffe2dd',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              textShadow: '1px 1px 0px rgba(0,0,0,.3)',
              letterSpacing: '.1em',
            }}
            endIcon={<AttachMoney />}
            onClick={(e) => handleOpen(e)}
          >
            Make Payment
          </Button>
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: '1em',
            color: theme.palette.save.main,
            fontWeight: 'bold',
            textShadow: '1px 1px 0px rgba(0,0,0,.3)',
            letterSpacing: '.1em',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          Paid
        </Typography>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ContractPaymentForm item={item} handleModalClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default ContractPayButton;
