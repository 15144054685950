import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from './PerformanceDashboardContext';
import { SlGameController } from 'react-icons/sl';

const ManufacturerBanner = () => {
  const {
    rank,
    selectedValue,
    selectedYear,
    loading,
    sum,
    pieFilter,
    monthFilter,
    currentYear,
    lastYear,
    yoy = [],
  } = useManufacturerContext();

  const theme = useTheme();
  const rank1 = rank;
  const differencePercent = Math.round((100 - rank) * 100) / 100;

  const filteredArray =
    Array.isArray(yoy) && yoy.length > 0
      ? yoy.filter((item) => item.s_year === selectedYear || item.s_year === selectedYear - 1)
      : []; // Default to an empty array if yoy is not valid

  const difference =
    filteredArray?.[1]?.sales != null && filteredArray?.[0]?.sales != null
      ? filteredArray[1].sales - filteredArray[0].sales
      : 0; // Default to 0 if values are missing

  const differencePercentYoy =
    filteredArray?.[0]?.sales != null && sum != null
      ? Math.round((sum / filteredArray[0].sales) * 100) - 100
      : 0; // Default to 0 if values are missing

  const safeSum = isNaN(sum) || sum == null ? 0 : sum;
  const formattedTotal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(safeSum);

  const formattedDiff = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(Math.abs(difference));

  // below handles the dynamic text for the banner
  const differenceText =
    difference > 0
      ? `${selectedValue} is up ${formattedDiff} (${differencePercentYoy}%)`
      : difference !== null
      ? `${selectedValue} is down ${formattedDiff} (${differencePercentYoy}%)`
      : 'No data for previous year';

  const categoryText = pieFilter ? ` for ${pieFilter.label} from ` : ' across all categories from ';
  const monthText = monthFilter ? ` ${monthFilter.axisValue}` : '';
  const yearText = difference !== null ? ` ${selectedYear} to ${selectedYear - 1}` : '';

  // if (loading === true) {
  //   return (
  //     <Box>
  //       <Box
  //         sx={{
  //           padding: '30px',
  //           background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
  //           display: 'flex', // Use flexbox for the parent container
  //           justifyContent: 'space-between', // Distribute the items across the container
  //           alignItems: 'center', // Vertically align items in the center
  //           color: '#ffffff',
  //           textAlign: 'center',
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             marginLeft: '5%',
  //             width: '28%',
  //             borderRadius: '8px', // Rounded corners for a modern look
  //             boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)', // Light shadow for depth
  //             padding: '20px', // Padding for internal spacing
  //           }}
  //         >
  //           <Skeleton variant='text' width='80%' height={50} />
  //           <Skeleton variant='text' width='60%' height={30} />
  //         </Box>
  //         <Box
  //           sx={{
  //             width: '28%',
  //             borderRadius: '8px',
  //             boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
  //             padding: '20px',
  //           }}
  //         >
  //           <Skeleton variant='text' width='80%' height={50} />
  //           <Skeleton variant='text' width='60%' height={30} />
  //         </Box>
  //         <Box
  //           sx={{
  //             marginRight: '5%',
  //             width: '28%',
  //             borderRadius: '8px',
  //             boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
  //             padding: '20px',
  //           }}
  //         >
  //           <Skeleton variant='text' width='80%' height={50} />
  //           <Skeleton variant='text' width='60%' height={30} />
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // }

  if (loading === true) {
    return (
      <Box>
        <Box
          sx={{
            padding: '30px',
            background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
            display: 'flex', // Use flexbox for the parent container
            justifyContent: 'space-between', // Distribute the items across the container
            alignItems: 'center', // Vertically align items in the center
            color: '#ffffff',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              marginLeft: '5%',
              width: '28%',
              borderRadius: '8px', // Rounded corners for a modern look
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)', // Light shadow for depth
              padding: '20px', // Padding for internal spacing
            }}
          >
            <Skeleton variant='text' width='80%' height={50} />
            <Skeleton variant='text' width='60%' height={30} />
          </Box>
          <Box
            sx={{
              width: '28%',
              borderRadius: '8px',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
              padding: '20px',
            }}
          >
            {selectedValue && (
              <Typography sx={{ fontSize: '20px' }}>
                Sports South has distributed {formattedTotal}
                {pieFilter ? ` worth of ${pieFilter.label}` : ''} for {selectedValue} in{' '}
                {monthFilter ? `${monthFilter.axisValue}` : ''} {selectedYear}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              marginRight: '5%',
              width: '28%',
              borderRadius: '8px',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
              padding: '20px',
            }}
          >
            {selectedValue && (
              <Typography sx={{ fontSize: '20px' }}>
                {differenceText}
                {categoryText}
                {monthText}
                {yearText}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          padding: '30px',
          background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
          display: 'flex', // Use flexbox for the parent container
          justifyContent: 'space-between', // Distribute the items across the container
          alignItems: 'center', // Vertically align items in the center
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            marginLeft: '5%',
            width: '28%',
            borderRadius: '8px', // Rounded corners for a modern look
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)', // Light shadow for depth
            padding: '20px', // Padding for internal spacing
          }}
        >
          {selectedValue && rank && (
            <Typography sx={{ fontSize: '20px' }}>
              {selectedValue} outperforms {differencePercent}% of manufacturers at Sports South
              {rank > 0
                ? ` ranking them in the top ${rank}% of manufacturers`
                : ` ranking them as our number one manufacturer`}
            </Typography>
          )}
          {/* {!rank && (
            <Typography sx={{ fontSize: '20px' }}>
              Ranking is disabled while performance mode is active
            </Typography>
          )} */}
        </Box>
        <Box
          sx={{
            width: '28%',
            borderRadius: '8px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
            padding: '20px',
          }}
        >
          {selectedValue && (
            <Typography sx={{ fontSize: '20px' }}>
              Sports South has distributed {formattedTotal}
              {pieFilter ? ` worth of ${pieFilter.label}` : ''} for {selectedValue} in{' '}
              {monthFilter ? `${monthFilter.axisValue}` : ''} {selectedYear}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            marginRight: '5%',
            width: '28%',
            borderRadius: '8px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
            padding: '20px',
          }}
        >
          {selectedValue && (
            <Typography sx={{ fontSize: '20px' }}>
              {differenceText}
              {categoryText}
              {monthText}
              {yearText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ManufacturerBanner;
