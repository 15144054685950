import React, { useContext, useEffect, useState, useRef } from "react";
import { ProductDataContextState } from "../../../Context/Marketing/ProductData/ProductDataContext"; 
import { Autocomplete, Breadcrumbs, Button, ButtonGroup, Collapse, Drawer, Grid2, Link, List, Paper, Stack, TextField, Typography, styled } from "@mui/material";
import DashboardDrawer from "./components/DashboardDrawer";
import Dashboard from "./components/Dashboard";
import ProductDataGeneralTable from "../GeneralTable/GeneralTable";
import FacetOptionReplaceTable from "../FORTable/FacetOptionReplaceTable";

export default function PDM(){

    // CONTEXT STATE
    const { userId, products, getDesiredSkuInfo, allTypes, allCategories, allManufacturers } = useContext(ProductDataContextState)

    // LOCAL STATE
    const [page, setPage] = useState('main') 
    const [data, setData] = useState(products)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const pageTitle = useRef('')
    // Types of Pages: 
    //    'main': Initial landing page with widgets, 
    //    'generalTable': Table that shows data that the user has searched
    //    'productData': page that shows the entire data for a certain product
    //    'facetOptionReplace': page for replacing or adding facet options from items, both in mass and singular

    // VARIABLES

    useEffect(() => {
        setData(products)
    }, [products])

    const _getDesiredSkuInfo = async (type, value) => {
        console.log('type: ' + type + ' value: ' + value )
        const result = await getDesiredSkuInfo(type, value);
        console.log(result)
        setData(result);
      };
    // USE EFFECT

    // useEffect(() => {
    //     const fetch =  () => {
    //         setItem(products.find(item => item.product_code === '148355'))            
    //     }
    //     fetch()
    // }, [products])


    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    const switchPage = (newPage, dataType, value) => {
        console.log('switching page')
        console.log(data)
        if(newPage === 'generalTable'){
            //creates the title for the general table
            let tempPageTitle = 'Search for '
            switch(dataType){
                case 'nocategory':
                    tempPageTitle+='Products with No Category'
                    break
                case 'noattr':
                    tempPageTitle+='Products with no Attributes'
                    break
                case 'worn':
                    tempPageTitle+='Worn or Dented Products'
                    break
                case 'myflag':
                    tempPageTitle+='Products I Flagged'
                    break
                case 'allflag':
                    tempPageTitle+='Products Everyone Flagged'
                    break
                case 'itemsw/few':
                    tempPageTitle+='Products with Few Atrributes'
                    break
                case 'nocoo':
                    tempPageTitle+='Products without Country of Origin Set'
                    break
                case 'notv':
                    tempPageTitle+='Products missing True Value'
                    break
                case 'nomaxcap':
                    tempPageTitle+='Products missing Max Capacity'
                    break
                case 'dept':
                    tempPageTitle+='Products'
                default:
                    tempPageTitle = 'Unset Title'
            }
            pageTitle.current = tempPageTitle
        setData(_getDesiredSkuInfo(dataType, value))
        }
        if(newPage === 'facetReplacementTable' || newPage === 'main'){
            setData(products)
        }
        console.log(newPage + ' ' + dataType)
        setPage(newPage)
    }
    
    const BreadcrumbNav = () => {
        switch(page){
            case 'main':
                return(
                    <Breadcrumbs separator=">">
                        <Link
                            padding={2}
                            underline="hover"
                            color="primary"
                        >
                            Dashboard
                        </Link>
                    </Breadcrumbs>    
                    )
            case 'generalTable':
                return(
                    <Breadcrumbs separator=">">
                        <Link
                            onClick={() => {
                                switchPage('main', '', 0)
                            }}
                            padding={2}
                            underline="hover"
                            color="secondary"
                        >
                            Dashboard
                        </Link>
                        <Link
                            padding={2}
                            underline='hover'
                            color='primary'
                        >
                            General Product Table
                        </Link>
                    </Breadcrumbs>    
                        )
            case 'facetOptionReplace':
                return(
                    <Breadcrumbs separator=">">
                        <Link
                            onClick={() => {
                                switchPage('main', '', 0)
                            }}
                            padding={2}
                            underline="hover"
                            color="secondary"
                        >
                            Dashboard
                        </Link>
                        <Link
                            padding={2}
                            underline='hover'
                            color='primary'
                        >
                            Facet Option Editor
                        </Link>
                    </Breadcrumbs>    
                        )
            case 'productPageFromFOR':
                return(
                    <Breadcrumbs separator=">">
                    <Link
                        onClick={() => {
                            switchPage('main', '', 0)
                        }}
                        padding={2}
                        underline="hover"
                        color="secondary"
                    >
                        Dashboard
                    </Link>
                    <Link
                        onClick={() => {
                            switchPage('facetOptionReplace', '', 0)
                        }}
                        padding={2}
                        underline='hover'
                        color='secondary'
                    >
                        Facet Option Editor
                    </Link>
                    <Link
                        padding={2}
                        underline='hover'
                        color='primary'
                    >
                        Product Page
                    </Link>
                </Breadcrumbs> 
                )
            case 'productPageFromGeneralTable':
                break
            default:
                return(
                    <Breadcrumbs separator=">">
                        <Link
                            onClick={() => {
                                switchPage('main', '', 0)
                            }}
                            padding={2}
                            underline="hover"
                            color="primary"
                        >
                            BreadCrumb Error: Return to Main
                        </Link>
                    </Breadcrumbs>    
                    ) 
        }
    }

    // VARIABLES

    return(<div style={{flex:1 , paddingTop:'5px'}}>
        <BreadcrumbNav/>
        <div style={{textAlign:'right'}}>
            <Button onClick={openDrawer}  sx={{textAlign:'right', flexDirection:'row-reverse'}}>Options</Button>
        </div>
        <DashboardDrawer drawerOpen={drawerOpen} closeDrawer={closeDrawer} switchPage={switchPage}/>
        {page == 'main' ? <Dashboard switchPage={switchPage}/> : '' }
        {page == 'generalTable' ?
            <div>
                <ProductDataGeneralTable switchPage={switchPage} data={data} pageTitle={pageTitle}/> 
            </div>
        : ''}
        {page == 'facetOptionReplace' ? 
        <FacetOptionReplaceTable switchPage={switchPage} data={data} categories={allCategories}/> : ''}
        
    </div>)
}