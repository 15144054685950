import { Alert, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';

const SnackBarV3 = ({ message, forceOpen = 0 }) => {
  // LOCAL STATE
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (forceOpen > 0) {
      handleOpen(true);
    }
  }, [forceOpen]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={() => handleClose()}
      severity='success'
      variant='filled'
      autoHideDuration={4000}
    >
      <Alert onClose={handleClose} severity='success' variant='filled'>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarV3;
