import { Route, Routes } from 'react-router-dom';
import React from 'react';
import PDM from '../Components/ProductDataManager/Dashboard/PDM';
import ProductDataContext from '../Context/Marketing/ProductData/ProductDataContext';
import ProductDataGeneralTable from '../Components/ProductDataManager/GeneralTable/GeneralTable';
import ValueCorrections from '../Components/ProductDataManager/Archive/Components/ValueCorrections/ValueCorrections';

const ProductDataManagerRoute = () => {

	return (
		<ProductDataContext>
			<Routes>
				<Route path='/*' element={<PDM/>} />
			</Routes>
		</ProductDataContext>
	);

};

export default ProductDataManagerRoute;
