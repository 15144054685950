import { Box, Button, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextV2State } from '../../../../../../Context/Marketing/PublicationContextV2';
import { insertOrUpdatePublication } from '../../../../PublicationMiddleware';

const SaveButton = ({ formData, categoryData }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  const { setAllPublications } = useContext(PublicationContextV2State);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [message, setMessage] = useState(false);

  // FUNCTIONS
  const handleClick = async () => {
    const res = await insertOrUpdatePublication(formData, categoryData);
    if (res.data.affectedRows > 0) {
      setMessage(true);
      setAllPublications();
    } else {
      setMessage(false);
    }
    setShowSaveMessage(true);
    setTimeout(() => {
      setShowSaveMessage(false);
    }, 6000);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ gridColumn: { xs: 'span 1', md: 'span 2', lg: 'span 4' } }}>
      <Box
        sx={{
          gridColumn: 'span 2',
          textAlign: 'right',
          borderRadius: '4px',
          backgroundColor: 'rgba(100,100,150,.1)',
          padding: '16px',
        }}
      >
        <Button
          variant='contained'
          color='success'
          sx={{ fontWeight: 'bold' }}
          onClick={() => handleClick()}
        >
          Save
        </Button>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            padding: '8px 0 0 0',
            width: '100%',
            color: message ? theme.palette.success.main : theme.palette.error.main,
            textAlign: 'center',
            fontWeight: 'bold',
            transition: 'opacity 1000ms ease-in-out', // Specify transitions for both transform and opacity
            opacity: showSaveMessage ? 1 : 0, // Corrected opacity to use numeric values
          }}
        >
          {message ? 'Successfully Saved!' : 'Sorry, something went wrong!'}
        </Box>
      </Box>
    </Box>
  );
};

export default SaveButton;
