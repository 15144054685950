import { Edit, RequestQuote } from '@mui/icons-material';
import { Box, Button, Drawer, IconButton, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react';
import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import ContractForm from '../../../../Contracts/components/ManageContracts/components/ContractForm';
import ContactsForEntity from '../../../../Contracts/components/ManageContracts/components/ContactsForEntity/ContactsForEntity';
import ViewContractsAction from './ViewContractsAction';

const RowEditAction = ({ id, item }) => {
  const { toggleUpdateEntityDrawerOpener, addSelectedEntityId } = useContext(EntityContextState);

  // LOGOL STATE
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClick = () => {
    addSelectedEntityId(id);
    toggleUpdateEntityDrawerOpener(true);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip title={`Edit Entity ${id}`}>
          <Button
            variant='contained'
            sx={{ borderRadius: '14px' }}
            size='small'
            color='warning'
            startIcon={<Edit />}
            onClick={() => handleClick()}
          >
            <span style={{ fontWeight: 'bold' }}>Edit</span>
          </Button>
        </Tooltip>

        <ContactsForEntity entityId={id} />

        <Tooltip title={`View Contracts`}>
          <>
            <ViewContractsAction item={item} />
            {/* <IconButton color='success' onClick={() => setDrawerOpen(true)}>
						<RequestQuote />
					</IconButton> */}
          </>
        </Tooltip>
      </Box>
      {/* <Drawer
				anchor={'right'}
				open={drawerOpen}
				onClose={() => {
					setDrawerOpen(false);
				}}
			>
				<ContractForm
					contractId={0}
					userid={}
					initialData={[
						{
							ss_publication_product_id: 99,
							entity_id: item.parent_entity_id > 0 ? item.parent_entity_id : id,
							product_manufacturers: item.entity_type_id === 2 ? [item.entity_id] : [],
						},
					]}
				/>
			</Drawer> */}
    </>
  );
};

export default RowEditAction;
