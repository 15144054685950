import axios from 'axios';
import dayjs from 'dayjs';
import { utilGetUserId } from './utilityFunctions';

export async function getEntitiesIdByUserId(userId) {
  try {
    const idArray = await axios.get(`/entities/getEntitiesIdByUserId/${userId}`);
    const allPublications = await axios.get(`/publications/getAllPublications`);
    const allContracts = await axios.get(`/contracts/getAllContracts`);
    const allEntities = await axios.get('/entities/getAllEntities');
    const allPublicationProducts = await axios.get('/publications/getAllPublicationProducts');
    const allEntityContacts = await axios.get('/entity-contacts/getAllEntityContacts');
    const allContractStatuses = await axios.get('/contracts/getAllStatuses');
    const finalData = allContracts.data
      .filter((e) => idArray.data.includes(e.entity_id))
      .map((ele, idx) => ({
        ...ele,
        entity: allEntities.data.find((ee) => ee.entity_id === ele.entity_id).business_name,
        publication: {
          ...allPublications.data.find((ee) => ee.ss_publication_id === ele.ss_publication_id),
        },
        pub_product: allPublicationProducts.data.find(
          (ee) => ee.ss_publication_product_id === ele.ss_publication_product_id
        ).description,
        contact_first_name: allEntityContacts.data.find((ee) => ee.user_id === ele.user_id)
          .first_name,
        contact_last_name: allEntityContacts.data.find((ee) => ee.user_id === ele.user_id)
          .last_name,
        contract_status: allContractStatuses.data.find(
          (ee) => ee.ss_publication_contract_status_id === ele.ss_publication_contract_status_id
        ).description,
        manufacturers: ele.product_manufacturers.split(',').map((manufacturerId) => ({
          manufacturer_entity_id: parseInt(manufacturerId),
          manufacturer_name:
            allEntities.data.find((e) => e.entity_id === parseInt(manufacturerId))?.business_name ||
            null,
        })),
      }));
    return finalData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export const getAllContractsData = async () => {
  const user_id = await utilGetUserId();
  const data = await axios.get(`/contracts/getAllContractsData/${user_id}`);

  const tempArray = data.data.map((e) => ({
    ...e,
    contract_due_date: dayjs(e.ss_publication.start_date).add(30, 'day').format('MMM DD, YYYY'),
    days_until_due: Math.ceil(
      dayjs(e.ss_publication.start_date).add(30, 'day').diff(dayjs(), 'day')
    ),
  }));

  return tempArray;
};

export const updateContractPayment = async (status_id = 0, date = 0, contract_id = 0) => {
  const data = await axios.post(`/contracts/updateContractPayment`, {
    status_id: status_id,
    date: date,
    contract_id: contract_id,
  });
  return data.data;
};

export const getAllContracts = async () => {
  const data = await axios.get(`/contracts/getAllContracts`);
  return data.data;
};

export const getAllPaymentStatuses = async () => {
  const data = await axios.get(`/contracts/getAllPaymentStatuses`);
  return data.data;
};

export const getContractCreditMemo = async (contract_id) => {
  const data = await axios.get(`/contracts/getContractCreditMemo/${contract_id}`);
  return data.data;
};

export const updatePaymentStatus = async (contract_id, status_id) => {
  const data = await axios.post(`/contracts/updatePaymentStatus`, {
    contract_id: contract_id,
    status_id: status_id,
  });
  console.log('updatePaymentStatus: ', data);
  return data.data;
};

export const deleteContracts = async (contract_ids) => {
  const data = await axios.post(`/contracts/deleteContracts`, {
    contract_ids: contract_ids,
  });
  console.log('deleteContracts: ', data);
  return data.data;
};

export const updateContractsProductId = async (
  publication_product_id,
  contract_id,
  standard_price
) => {
  const data = await axios.post(`/contracts/updateContractsProductId`, {
    ss_publication_product_id: publication_product_id,
    ss_publication_contract_id: contract_id,
    standard_price: standard_price,
  });

  return data.data;
};

export const createContract = async (obj) => {
  console.log('createContract abc: ', obj);
  let contractBrands = obj.product_manufacturers.split(',');
  const data = await axios.post(`/contracts/insertContract`, obj);

  if (data.data.insertId > 0) {
    contractBrands.map((e) =>
      insertContractBrands({ ss_publication_contract_id: data.data.insertId, entity_id: e })
    );
  }
  return data.data;
};

export const insertContractBrands = async (values = []) => {
  const data = await axios.post(`/contracts/insertContractBrands`, values);
  return data;
};

export const deleteContractBrands = async (deleteIds = {}) => {
  console.log('deleteIds: ', deleteIds);
  const data = await axios.post(`/contracts/deleteContractBrands`, deleteIds);
  return data.data;
};
