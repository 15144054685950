import { Box } from '@mui/material';
import React from 'react';
import ContractButton from './components/ContractButton';
import EditButton from './components/EditButton';
import DigitalFlyerButton from './components/DigitalFlyerButton';
import PublicationImageManager from './components/PublicationImageManager/PublicationImageManager';

const Actions = ({ publicaitonId, publicationData }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2, alignItems: 'center' }}>
      <EditButton publicationData={publicationData} />
      <ContractButton publicationId={publicaitonId} publicationData={publicationData} />
      <DigitalFlyerButton digitalFlyerURL={publicationData.flyer_url} />
      <PublicationImageManager publicationId={publicaitonId} />
    </Box>
  );
};

export default Actions;
