import React, { useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts';
import { Box, Typography, Button, Select, MenuItem } from '@mui/material';
import { useManufacturerContext } from './PerformanceDashboardContext';
import { useTheme } from '@mui/material/styles';

const ManufacturerPie = () => {
  const {
    pieSeries = [],
    pieFilter,
    setPieFilter,
    monthFilter,
    selectedValue,
    selectedYear,
    slice,
    setSlice,
    skipAnimation,
    pieDropDown,
    setPieDropDown,
  } = useManufacturerContext();
  const theme = useTheme();

  const baseColors = [
    //theme.palette.primary.main,
    'rgba(0, 50, 90, 1)', //main theme
    'rgba(50, 90, 120, 1)', // Less Saturated 1
    'rgba(75, 115, 145, 1)', // Less Saturated 2
    'rgba(120, 150, 180, 1)', // Less Saturated 3
    'rgba(160, 180, 200, 1)', // Less Saturated 4
  ];

  const [pieData, setPieData] = useState([]);
  const [dropDown, setDropDown] = useState([]);

  //ensure both pie aob's are sorted
  useEffect(() => {
    const roundedPieSeries = Object.entries(pieSeries).reduce((acc, [index, obj]) => {
      acc[index] = Object.entries(obj).reduce((innerAcc, [key, value]) => {
        innerAcc[key] = typeof value === 'number' ? Math.round(value) : value;
        return innerAcc;
      }, {});
      return acc;
    }, {});

    const roundedPieData = Object.values(roundedPieSeries);

    setDropDown([...pieSeries].sort((a, b) => a.label.localeCompare(b.label)));
    setPieData([...roundedPieData].sort((a, b) => b.value - a.value).slice(0, 5)); // Grab the top 5 items
  }, [monthFilter, pieSeries]);

  const itemIdToColorMap = {};
  pieData.forEach((item, index) => {
    itemIdToColorMap[item.id] = baseColors[index % baseColors.length];
  });

  const pieChartColors = pieData.map((item) => {
    if (slice === item.id) {
      return `${theme.palette.accent.main}90`; // Highlight color for selected slice
    }
    return itemIdToColorMap[item.id];
  });

  const handleClick = (event, spice, params) => {
    const spiceIndex = spice.dataIndex;
    if (slice === params.id) {
      setPieFilter(null);
      setSlice(null);
      setPieDropDown('');
    } else {
      setSlice(params.id);
      setPieDropDown(params.id);
      setPieFilter(pieData[spiceIndex]);
    }
  };

  const handleLegendClick = (event, spice, index) => {
    const itemId = spice.itemId;
    const spiceIndex = index;
    if (slice === itemId) {
      setPieFilter(null);
      setSlice(null);
      setPieDropDown('');
    } else {
      setSlice(itemId);
      setPieDropDown(itemId);
      setPieFilter(pieData[spiceIndex]);
    }
  };

  const handleDropDown = (e) => {
    //below is still selecting pieSeries so I have all categories from that brand
    const selectedItem = dropDown.find((item) => item.id === e.target.value);
    if (selectedItem) {
      setPieDropDown(selectedItem.id); // Use `id` to match `MenuItem` values
      setPieFilter(selectedItem);
      setSlice(selectedItem.id);
    }
  };

  //this needs to stay dropDown to have the ability to map outside the top 5 items
  const labels = dropDown.map((item) => item.label);
  const maxLength = Math.max(...labels.map((label) => label.length));
  const piePadding = maxLength < 20 ? 15 : -5;
  const pieMargin = maxLength < 20 ? 150 : 260;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.85em',
            color: theme.palette.primary.main,
          }}
        >
          Top Categories
          {monthFilter ? ` for ${monthFilter.axisValue} ${selectedYear}` : ` ${selectedYear}`}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          padding: '24px',
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Select
          labelId='dropdown-label'
          id='dropdown'
          onChange={handleDropDown}
          value={pieDropDown || ''}
          autoWidth
          sx={{
            display: 'flex',
            textAlign: 'center',
            fontSize: '1.5rem',
            marginLeft: 'auto',
            maxWidth: 250,
            marginBottom: -8.3,
            zIndex: 3,
          }}
        >
          {[{ id: 0, label: 'View All' }, ...dropDown].map((e, index) => (
            <MenuItem key={index} value={e.id}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <PieChart
          skipAnimation={skipAnimation}
          margin={{ top: 1, bottom: 1, left: 0, right: pieMargin }}
          sx={{
            zIndex: 1,
            '.MuiPieArc-root': { stroke: theme.palette.accent.main, strokeWidth: 2 },
            '.MuiPieArcLabel-root': { fill: 'white', fontSize: '1.2em' },
          }}
          colors={pieChartColors}
          series={[
            {
              data: pieData,
              arcLabel: (item) => `$${item.value.toLocaleString()}`,
              arcLabelMinAngle: 35,
              arcLabelRadius: '55%',
              highlightScope: { fade: 'global', highlight: 'item' },
            },
          ]}
          height={350}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: piePadding,
              onItemClick: handleLegendClick,
            },
          }}
          onItemClick={handleClick}
        />
      </Box>
    </>
  );
};
export default ManufacturerPie;
