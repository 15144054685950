import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getSalesByMonth,
  allManufacturers,
  mfgRanking,
} from '../../../_GlobalFunctions/salesFunctions';
import { getContactDetailsByUserId } from '../../../_GlobalFunctions/contactFunctions';
import { getSubBrandsByContactId } from '../../../_GlobalFunctions/entityFunctions';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { gridColumnGroupsUnwrappedModelSelector } from '@mui/x-data-grid';

// 1. Create the context
const ManufacturerContext = createContext();

// 2. Provider Component
export const ManufacturerProvider = ({ children }) => {
  // State
  const [loading, setLoading] = useState(true);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [seriesData, setSeriesData] = useState([]);
  const [pieSeries, setPieSeries] = useState([]);
  const [rank, setRank] = useState([]);
  const [pieFilter, setPieFilter] = useState(null);
  const [sbmData, setSbmData] = useState(null);
  const [categorySeries, setCategorySeries] = useState([]);
  const [topItems, setTopItems] = useState([]);
  const [sum, setSum] = useState(0);
  const [yoy, setYoy] = useState(0);
  const [yoyComp, setYoyComp] = useState(0);
  const [yoyProducts, setYoyProducts] = useState('2024');
  const [salesByItem, setSalesByItem] = useState([]);
  const [monthFilter, setMonthFilter] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [model, setModel] = useState(null);
  const [threeYears, setThreeYears] = useState(null);
  const [lastYear, setLastYear] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [salesSeries, setSalesSeries] = useState([]);
  const [slice, setSlice] = useState(null);
  const [skipAnimation, setSkipAnimation] = useState(false);
  const [pieDropDown, setPieDropDown] = useState([]);
  const [performanceMode, setPerformanceMode] = useState(false);
  const [alignment, setAlignment] = useState('2024');
  const userId = useSelector((state) => state.userInformation.value.userID);

  useEffect(() => {
    const initalLoad = async () => {
      try {
        const dynamicYear = dayjs().subtract(1, 'month').year();
        const dynamicMonth = dayjs().subtract(1, 'month').month() + 1;
        const dynamicLastYear = dayjs().subtract(1, 'year').subtract(1, 'month').year();
        const dynamicThreeYears = dayjs().subtract(3, 'year').subtract(1, 'month').year();
        setCurrentYear(dynamicYear);
        setCurrentMonth(dynamicMonth);
        setSelectedYear(dynamicYear);
        setAlignment(dynamicYear.toString());
        setLastYear(dynamicLastYear);
        setThreeYears(dynamicThreeYears);

        const contactDetails = await getContactDetailsByUserId(userId);
        //const contactId = contactDetails[0].entity_contact_id;
        const mfg = await getSubBrandsByContactId(userId);
        setManufacturers(mfg);

        const defaultManufacturer = mfg[0];
        setSelectedValue(defaultManufacturer.description);

        const mfgId = defaultManufacturer.product_manufacturer_id;
        const sData = await getSalesByMonth(dynamicThreeYears, dynamicYear, mfgId);
        setSbmData(sData);

        const rankData = await mfgRanking(mfgId);
        setRank(rankData.map((e) => e.percentile));
      } catch (error) {
        console.error('Failed on initialLoad:', error);
      }
    };
    initalLoad();
  }, []);

  // Fetch manufacturers on component mount
  useEffect(() => {
    if (!sbmData) return;
    const mainFunction = async () => {
      try {
        //all data
        const allData = sbmData.map((item) => ({
          ...item,
          sales: parseFloat(item.sales), // Ensure sales is a number
        }));

        //data filtered by selected year
        const updatedData = sbmData
          .map((item) => ({
            ...item,
            sales: parseFloat(item.sales),
          }))
          .filter((item) => item.s_year === selectedYear);

        //data filtered by category and selected year
        const filteredData =
          pieFilter === null
            ? updatedData
            : updatedData.filter((item) => item.product_category_id === pieFilter.id);

        //data filtered by category has both years
        const filteredData2 =
          pieFilter === null
            ? allData
            : allData.filter((item) => item.product_category_id === pieFilter.id);

        //filtered by category and model has both years
        const trendingLineData =
          model === null ? filteredData2 : filteredData2.filter((item) => item.model === model);

        //restict trending chart to 3 years
        const trendingLineData2 = trendingLineData.filter((e) => e.s_year !== currentYear - 3);

        const monthTranslation = (monthName) => {
          const date = new Date(`${monthName} 1, 2000`);
          if (!isNaN(date)) {
            return date.getMonth() + 1; // getMonth() returns 0-11, so add 1
          }
          return null; // Return null if monthName is invalid
        };

        //filtered by month and category with both years
        const filteredData3 =
          monthFilter === null
            ? filteredData2
            : filteredData2.filter(
                (item) => item.s_month === monthTranslation(monthFilter.axisValue)
              );

        const modelYearFilters =
          model === null ? filteredData3 : filteredData3.filter((item) => item.model === model);

        console.log(modelYearFilters);

        //filtered by month and category with only selected year to be replaced with filteredData3 once the table logic for seperating the years is in place!!!!!!!!!!!!!!!!!!
        //only thing using the below code is bannerData
        const catAndYear =
          monthFilter === null
            ? filteredData
            : filteredData.filter(
                (item) => item.s_month === monthTranslation(monthFilter.axisValue)
              );

        // disabled banner from filtering by model because other banner doesn't filter by model
        // const BannerData =
        //   model === null ? catAndYear : catAndYear.filter((item) => item.model === model);

        const pieData =
          monthFilter === null
            ? updatedData
            : updatedData.filter(
                (item) => item.s_month === monthTranslation(monthFilter.axisValue)
              );

        // Trending Sales First Chart

        const trendingSeries = trendingLineData2.reduce((acc, item) => {
          const month = item.s_month;
          const year = item.s_year;
          const existingEntry = acc.find(
            (entry) => entry.s_month === month && entry.s_year === year
          );

          if (!existingEntry) {
            acc.push({ s_month: month, s_year: year, sales: item.sales });
          } else {
            existingEntry.sales += item.sales;
          }
          return acc;
        }, []);

        setSalesSeries(trendingSeries);

        // // TopItems (second chart)
        // const topItemFilter = filteredData.map(({ as400_description, s_month, sales }) => ({
        //   id: as400_description,
        //   s_month,
        //   sales,
        // }));

        // const transformedTopItems = topItemFilter.reduce((acc, { id, s_month, sales }) => {
        //   const monthIndex = acc.findIndex((entry) => entry.s_month === s_month);

        //   if (monthIndex === -1) {
        //     acc.push({ s_month, [id]: sales });
        //   } else {
        //     acc[monthIndex][id] = Math.round(sales);
        //   }

        //   return acc;
        // }, []);
        // setTopItems(transformedTopItems);

        // // Sales By Category (third chart)
        // const gbc = updatedData.map(({ category_description, s_month, sales }) => ({
        //   id: category_description,
        //   s_month,
        //   sales,
        // }));

        // const transformedData = gbc.reduce((acc, { id, s_month, sales }) => {
        //   const entryIndex = acc.findIndex((entry) => entry.s_month === s_month);

        //   if (entryIndex === -1) {
        //     acc.push({ s_month, [id]: sales });
        //   } else {
        //     if (!acc[entryIndex][id]) {
        //       acc[entryIndex][id] = sales;
        //     } else {
        //       acc[entryIndex][id] += sales;
        //     }
        //   }
        //   return acc;
        // }, []);
        // setCategorySeries(transformedData);

        //Top Categories Pie Chart
        const pieMap2 = pieData.map(({ product_category_id, category_description, sales }) => ({
          id: product_category_id,
          value: sales,
          category_description,
        }));

        const pieAcc = pieMap2.reduce((acc, item) => {
          if (!acc[item.id]) {
            acc[item.id] = { id: item.id, value: 0, label: item.category_description };
          }
          acc[item.id].value += item.value;
          return acc;
        }, {});

        const newPieSeries = Object.values(pieAcc);
        setPieSeries(newPieSeries);

        //Year over Year Model Data
        const sbmDataData4 = filteredData3.map(
          ({ model_facet_option_id, model, sales, s_year }) => ({
            id: model_facet_option_id,
            value: sales,
            model,
            s_year,
          })
        );

        // Group Model data by year and reduce for each year
        const sbmAcc = sbmDataData4.reduce((acc, item) => {
          if (!acc[item.s_year]) {
            acc[item.s_year] = {}; // Create an object for the year if it doesn't exist
          }
          if (!acc[item.s_year][item.id]) {
            acc[item.s_year][item.id] = {
              id: item.id,
              value: 0,
              label: item.model,
              year: item.s_year,
            };
          }
          acc[item.s_year][item.id].value += item.value;
          return acc;
        }, {});

        // Convert the '2023' and '2024' objects into arrays to use map and find
        const firstYearArray = Object.values(sbmAcc[selectedYear - 1] || {});
        const secondYearArray = Object.values(sbmAcc[selectedYear] || {});

        //Top Models for selected year
        const sumYoy = secondYearArray
          .map((item2024) => {
            const sumSelectedYear = item2024.value;
            return {
              id: item2024.id,
              label: item2024.label,
              sum: sumSelectedYear,
            };
          })
          .filter((item) => item.id !== null);

        setYoyProducts(sumYoy);

        //Year over Year report for models
        const differences = secondYearArray
          .map((item2024) => {
            const item2023 = firstYearArray.find((item) => item.label === item2024.label);
            const value2023 = item2023 ? item2023.value : 0; // Use 0 if item2023 doesn't exist
            const difference = item2024.value - value2023;
            const percentChange =
              value2023 === 0
                ? 'New' // Assume 100% growth for new items
                : ((difference / value2023) * 100).toFixed(2); // Rounded to 2 decimal places

            return {
              id: item2024.id,
              label: item2024.label,
              difference: difference,
              percentChange: percentChange, // Store the percentage change
            };
          })
          .filter((item) => item.id !== null);

        // Set both the differences and percentage changes
        setYoyComp(differences);

        // Banner Total Sum
        const sumFilter = catAndYear.map(({ as400_description, sales, category_description }) => ({
          as400_description,
          sales,
          category: category_description,
        }));
        const totalSales = sumFilter.reduce((acc, { sales }) => acc + sales, 0);
        setSum(totalSales);

        // Banner YOY comparison
        const yoyRed = filteredData3.reduce((acc, { s_year, sales }) => {
          if (!acc[s_year]) {
            acc[s_year] = { s_year, sales: 0 };
          }
          acc[s_year].sales += sales;
          return acc;
        }, {});
        const yoySales = Object.values(yoyRed);
        setYoy(yoySales);

        //table items
        const tableData = modelYearFilters.map(
          ({ as400_description, sales, category_description, s_year, qtyoh, RESRV }) => ({
            as400_description,
            sales,
            category: category_description,
            qtyoh,
            RESRV,
            s_year,
          })
        );

        const uniqueYears = [...new Set(tableData.map(({ s_year }) => s_year))];
        const sumItems = tableData.reduce(
          (acc, { as400_description, sales, category, qtyoh, RESRV, s_year }) => {
            if (!acc[as400_description]) {
              acc[as400_description] = {
                as400_description,
                category,
                qtyoh,
                RESRV,
              };
              uniqueYears.forEach((year) => {
                acc[as400_description][`sales_${year}`] = 0;
              });
            }
            acc[as400_description][`sales_${s_year}`] += sales;
            return acc;
          },
          {}
        );
        const sumItems2 = Object.values(sumItems);
        setSalesByItem(sumItems2);

        //this is the end of setting all of the chart data
      } catch (error) {
        console.error('Failed to fetch manufacturers:', error);
      }
    };
    mainFunction();
  }, [pieFilter, monthFilter, selectedYear, model, sbmData]);

  // Handle manufacturer change
  const handleChange = async (e) => {
    setLoading(true);
    setMonthFilter(null);
    setSelectedYear(currentYear);
    setModel(null);
    setAlignment(currentYear.toString());
    const selectedDescription = e.target.value;
    setSelectedValue(selectedDescription);

    const match = manufacturers.find((m) => m.description === selectedDescription);
    const mfgId = match ? match.product_manufacturer_id : selectedDescription;

    const allData = await getSalesByMonth(threeYears, currentYear, mfgId);
    setSbmData(allData);

    setPieFilter(null);
    setSlice(null);

    if (!performanceMode) {
      const rankData = await mfgRanking(mfgId);
      setRank(rankData.map((e) => e.percentile));
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [rank]);

  // Context value
  const value = {
    manufacturers,
    categorySeries,
    pieFilter,
    setPieFilter,
    selectedValue,
    handleChange,
    seriesData,
    pieSeries,
    rank,
    loading,
    topItems,
    sum,
    salesByItem,
    monthFilter,
    setMonthFilter,
    selectedYear,
    setSelectedYear,
    salesSeries,
    slice,
    setSlice,
    skipAnimation,
    setSkipAnimation,
    pieDropDown,
    setPieDropDown,
    performanceMode,
    setPerformanceMode,
    alignment,
    setAlignment,
    yoy,
    currentYear,
    lastYear,
    yoyComp,
    model,
    setModel,
    yoyProducts,
  };

  // Render
  return <ManufacturerContext.Provider value={value}>{children}</ManufacturerContext.Provider>;
};

export const useManufacturerContext = () => {
  return useContext(ManufacturerContext);
};
