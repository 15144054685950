import React, { useEffect, useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme, alpha } from '@mui/material/styles';
import { useManufacturerContext } from './PerformanceDashboardContext';
import { Category } from '@mui/icons-material';

const DivergentBarChart = () => {
  const {
    yoyComp = [],
    model,
    setModel,
    pieFilter,
    selectedYear,
    monthFilter,
  } = useManufacturerContext();
  const [labelMap, setLabelMap] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [percent, setPercent] = useState([]);
  const [labelSpace, setLabelSpace] = useState(100);
  const theme = useTheme();

  useEffect(() => {
    const fetchyoyComp = () => {
      if (yoyComp) {
        const top5 = yoyComp
          .sort((a, b) => Math.abs(b.difference) - Math.abs(a.difference))
          .slice(0, 7);
        const top5Map = top5.map((e) => e.label);
        const chartMap = top5.map((e) => Math.round(e.difference));
        const percentMap = top5.map((e) => e.percentChange);
        setPercent(percentMap);
        setLabelMap(top5Map);
        setChartValues(chartMap);
        const maxLength = Math.max(...top5Map.map((e) => e.length));
        setLabelSpace(
          maxLength > 20
            ? 180
            : maxLength > 15
            ? 120
            : maxLength > 8
            ? 100
            : maxLength > 5
            ? 65
            : 50
        );
      }
    };
    fetchyoyComp();
  }, [yoyComp]);

  const handleBarClick = (i) => {
    if (labelMap[i.dataIndex] === model) {
      setModel(null);
    } else {
      setModel(labelMap[i.dataIndex]);
    }
  };

  const chartSetting = {
    yAxis: [
      {
        data: labelMap,
        scaleType: 'band',
      },
    ],
    xAxis: [
      {
        colorMap: {
          type: 'piecewise',
          thresholds: [0],
          colors: [`${alpha(theme.palette.accent.main, 0.9)}`, theme.palette.primary.main],
        },
      },
    ],
    width: 700,
    height: 450,
    borderRadius: 5,
    margin: { top: 20, right: 70, bottom: 40, left: labelSpace },
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '1.85em',
          color: theme.palette.primary.main,
          marginBottom: 1,
        }}
      >
        {pieFilter && !monthFilter ? `Year-over-Year for ${pieFilter.label} ` : ''}
        {pieFilter && monthFilter ? `YoY for ${pieFilter.label} ${monthFilter.axisValue} ` : ''}
        {monthFilter && !pieFilter ? ` Year-over-Year for ${monthFilter.axisValue} ` : ''}
        {!monthFilter && !pieFilter ? ` Year-over-Year for ` : ''}
        {selectedYear}
      </Typography>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <BarChart
          sx={{
            '.MuiBarLabel-root': {
              fill: '#fff',
              fontSize: '1.2em',
            },
          }}
          series={[
            {
              data: chartValues,
              highlightScope: {
                highlight: 'item',
              },
            },
          ]}
          layout='horizontal'
          onItemClick={(e, i) => handleBarClick(i)}
          {...chartSetting}
          grid={{
            horizontal: true,
          }}
          barLabel={(item, bar) => {
            if (bar.bar.width < 150 && bar.bar.width >= 60 && percent[item.dataIndex] > 0) {
              var label = `${Math.round(percent[item.dataIndex])}%`;
            } else if (bar.bar.width < 60) {
              var label = '';
            } else if (percent[item.dataIndex] > 0) {
              var label = `${item.value.toLocaleString()} (${Math.round(
                percent[item.dataIndex]
              )}%)`;
            } else if (bar.bar.width < 150 && bar.bar.width >= 60) {
              var label = `${percent[item.dataIndex]}`;
            } else {
              var label = `${item.value.toLocaleString()} (${percent[item.dataIndex]})`;
            }

            return label;
          }}
          slotProps={{
            legend: {
              position: {},
              itemMarkWidth: 0,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DivergentBarChart;
