import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextV2State } from '../../../../Context/Marketing/PublicationContextV2';
import useColumns from './Columns';
import { Box, useTheme } from '@mui/material';

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from 'material-react-table';
import AddNewPublicationButton from './AddNewPublicationButton';
import FilterTableByPubType from './FilterTableByPubType';
import TempMarketingMenu from '../../../_GlobalComponents/TempMarketingMenu';

const PublicationsTable = () => {
  // INITs
  const theme = useTheme();
  const columns = useColumns();

  // CONTEXT STATE
  const { allPublications } = useContext(PublicationContextV2State);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  // FUNCTIONS
  const filterTableByTypeId = (id) => {
    const data =
      id === 0 ? allPublications : allPublications.filter((e) => e.ss_publication_type_id === id);
    setTableData(data);
  };

  // USE EFFECTS
  useEffect(() => {
    setTableData([...allPublications]);
    setLoading(false);
  }, [allPublications]);

  // TABLE OPTIONS
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    initialState: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 100 },
      showGlobalFilter: true,
      //columnOrder: ['entity_id', 'contact_name', 'address', 'emails', 'phone_numbers', 'active', 'associated'],
    },
    enableColumnResizing: true,
    enableRowSelection: false,
    // state: { rowSelection: selectedRowIds },
    // onRowSelectionChange: setSelectedRowIds,
    layoutMode: 'grid',
    muiTablePaperProps: { sx: { backgroundColor: 'white', padding: '18px', borderRadius: '18px' } },
    muiTableContainerProps: { sx: { maxHeight: '700px' } },
    muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'white',
        pt: 2,
        fontWeight: 'bold',
        height: 'auto',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        mb: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottomColor: theme.palette.secondary.main,
        pb: 2,
      },
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search Publications',
      sx: { minWidth: '500px' },
      variant: 'outlined',
    },
    renderTopToolbar: () => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '8px',
        }}
      >
        <Box sx={{ gridColumn: 'span 1' }}>
          <MrtGlobalFilterTextField table={table} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '36px',
            gridColumn: 'span 2',
          }}
        >
          <FilterTableByPubType func={filterTableByTypeId} />
          <AddNewPublicationButton />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MrtToggleDensePaddingButton table={table} />
            <MrtToggleFiltersButton table={table} />
            <MrtShowHideColumnsButton table={table} />
            <MrtToggleFullScreenButton table={table} />
            <TempMarketingMenu />
          </Box>
        </Box>
      </Box>
    ),
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      className='ManagePublications'
      sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default PublicationsTable;
