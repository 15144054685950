import axios from 'axios';

export const UploadFile = async (
  file,
  mediaAssetId,
  primaryId,
  displayName,
  locationCode,
  mediaTypeCode
) => {
  const formData = new FormData();

  // Append the actual file object
  formData.append('files', file);

  // Append other values
  formData.append('identifyingId', primaryId);
  formData.append('locationCode', locationCode);
  formData.append('displayName', displayName);
  if (mediaAssetId) formData.append('mediaAssetId', mediaAssetId);
  formData.append('mediaTypeCode', mediaTypeCode);

  // Debugging: Log the FormData contents (optional)
  for (const [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  try {
    // Make the POST request
    const res = await axios.post('/upload/public', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Return the response data
    return res.data;
  } catch (err) {
    // Return error message or the entire error object for debugging
    console.error('Upload failed:', err);
    throw new Error(err.response?.data?.message || err.message || 'Upload failed');
  }
};
