import { Edit, Replay5 } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { getAllPublicationProducts } from '../../../../_GlobalFunctions/publicationFunctions';
import { updateContractsProductId } from '../../../../_GlobalFunctions/contractFunctions';
import { MarketingContextState } from '../../../../../Context/Marketing/MarketingContext';

// MUI STYLE OBJ
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const EditContractsProduct = ({ item }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  const { setRefreshContractData } = useContext(MarketingContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState([]);

  // FUNCTIONS
  const handleClose = (res) => {
    setOpen(false);
    res.affectedRows > 0 && alert(`All's good.... ${res.InsertId}`);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClick = async (e, standard_price) => {
    const res = await updateContractsProductId(e, item.ss_publication_contract_id, standard_price);
    setRefreshContractData((prev) => prev + 1);
    handleClose(res);
  };

  const fetch = async () => {
    try {
      const res = await getAllPublicationProducts();

      // Ensure res is an array
      if (!Array.isArray(res)) {
        console.error('Unexpected response format. Expected an array.');
        return;
      }

      // Filter products based on types
      const types = res.filter((e) => {
        // Ensure e.types is a valid string
        if (typeof e.types === 'string') {
          return e.types
            .split(',')
            .map(Number)
            .includes(item.ss_publication.ss_publication_type_id); // Convert to numbers and check
        }
        return false;
      });
      setProducts(types);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };

  // USE EFFECT
  useEffect(() => {
    fetch();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <Typography>
          {item.ss_publication_product.description} - {item.ss_publication_product.standard_price}
        </Typography>
        {dayjs(item.ss_publication.submit_contract_date) > dayjs() &&
          item.ss_publication_payment_status_id !== 2 && (
            <IconButton
              sx={{
                color: theme.palette.save.main,
                backgroundColor: `${theme.palette.save.main}11`,
              }}
              onClick={(e) => handleOpen(e)}
            >
              <Edit />
            </IconButton>
          )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: '4px' }}>
            <Typography
              variant='h5'
              sx={{ marginBottom: '16px', color: theme.palette.accent.main, fontWeight: 'bold' }}
            >
              Choose Product:
            </Typography>
            {products.map((e, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '32px',
                  padding: '20px',
                  letterSpacing: '.05em',
                  borderRadius: '8px',
                  boxShadow: `2px 2px 6px ${theme.palette.primary.main}22`,
                  marginBottom: '16px',
                  border: `solid 1px ${theme.palette.primary.main}33`,
                  transition: '200ms ease-in-out',
                  backgroundColor: 'white',
                  position: 'relative',
                  '&:hover': {
                    // transform: 'scale(1.02)',
                    boxShadow: `3px 3px 8px ${theme.palette.primary.main}44`,
                    top: '-2px',
                  },
                }}
                onClick={() => handleClick(e.ss_publication_product_id, e.standard_price)}
              >
                {/* <Box>
                  <Replay5 sx={{ fontSize: '2em', color: `${theme.palette.accent.main}cc` }} />
                </Box> */}
                <Box>
                  <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{e.description}</Box>
                  <Box sx={{ whiteSpace: 'wrap' }}>{e.detailed_description}</Box>
                </Box>
                <Box sx={{ fontWeight: 'bold', fontSize: '1.4em' }}>
                  ${parseFloat(e.standard_price).toFixed(2)}
                </Box>
              </Box>
            ))}
          </Box>
          edit product {item.ss_publication_contract_id} |{' '}
          {item.ss_publication.ss_publication_type_id}
        </Box>
      </Modal>

      {/* EditContractsProduct {item.ss_publication_contract_id} {item.ss_publication.ss_publication_product_id} */}
    </Box>
  );
};

export default EditContractsProduct;
