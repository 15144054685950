import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import ContractPayButton from './ContractPayButton/ContractPayButton';
import dayjs from 'dayjs';
import EditContractsProduct from './EditContractsProduct';
import { AddCircleOutline } from '@mui/icons-material';
import ContractVendor from './ContractVendor/ContractVendor';
import DeleteContract from './DeleteContract';
//import ContractListRowSubBrands from './ContractListRowSubBrands';
//import EditContractsProduct from './EditContractsProduct';

const ContractsListRow = ({ item, idx }) => {
  // INIT's
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '200px 1fr 250px 1fr 115px 155px 64px',
        alignItems: 'center',
        gap: '16px',
        padding: '16px',
        backgroundColor: Number(idx) % 2 !== 0 && '#e7e7e7',
        borderRadius: '4px',
      }}
    >
      {/* CONTRACT PAY BUTTON */}
      <Box>
        <ContractPayButton item={item} />
      </Box>
      {/* CONTRACT DETAILS */}
      <Box>
        <Box sx={{ fontWeight: 'bold', fontSize: '.8em' }}>
          Contract Id# {item.ss_publication_contract_id}
        </Box>
        <Box sx={{ fontWeight: 'bold', fontSize: '1.2em', color: theme.palette.primary.main }}>
          {item.ss_publication.description}
        </Box>
      </Box>
      {/* CONTRACT PRODUCTS */}
      <Box>
        <Box>{item.ss_publication.ss_publication_type}</Box>
        <EditContractsProduct item={item} />
      </Box>
      {/* CONTRACT VENDOR AND SUBBRANDS */}
      <Box>
        <ContractVendor item={item} />
      </Box>
      {/* <Box>        
        <Box sx={{ paddingY: '16px', fontWeight: 'bold', fontSize: '1em' }}>{item.vendor}</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '24px',
            justifyContent: 'flex-start',
          }}
        >
          <ContractListRowSubBrands brands={item.brands} />
        </Box>
      </Box> */}
      {/* CONTRACT DUE DATES */}
      <Box>
        <Box sx={{ fontWeight: 'bold', fontSize: '.8em' }}>
          {dayjs().isBefore(dayjs(item.ss_publication.start_date).add(30, 'day'))
            ? 'Due On:'
            : dayjs().isSame(dayjs(item.ss_publication.start_date).add(30, 'day'))
            ? 'Due Now:'
            : 'Was Due On'}
        </Box>

        {dayjs(item.ss_publication.start_date).add(30, 'day').format('MMM DD, YYYY')}
      </Box>
      {/* CONTRACT COST DETAILS */}
      <Box sx={{ textAlign: 'right' }}>
        {item.ss_publication_payment_status_id !== 2 &&
          item.days_until_due > 2 &&
          `Due In ${item.days_until_due} Days`}
        {item.ss_publication_payment_status_id !== 2 && item.days_until_due === 1 && `Due Tomorrow`}
        {item.ss_publication_payment_status_id !== 2 && item.days_until_due === 0 && `Due Today`}
        {item.ss_publication_payment_status_id !== 2 &&
          item.days_until_due === -1 &&
          `Due Yesterday`}
        {item.ss_publication_payment_status_id !== 2 &&
          item.days_until_due < -1 &&
          item.days_until_due > -8 &&
          `Due Last Week`}
        {item.ss_publication_payment_status_id !== 2 &&
          item.days_until_due < -7 &&
          `${Math.abs(item.days_until_due)} Days Past Due`}

        <Box sx={{ fontSize: '1.5em', fontWeight: 'bold', position: 'relative' }}>
          <Box sx={{ color: theme.palette.accent.main, fontSize: '.65em' }}>
            orig: ${item.sold_price}
          </Box>
          ${parseFloat(Number(item.sold_price) - Number(item.total_spent)).toFixed(2)}
        </Box>
      </Box>
      {/* DELETE CONTRACT */}
      <Box sx={{ textAlign: 'right' }}>
        <DeleteContract contractId={item.ss_publication_contract_id} />
      </Box>
    </Box>
  );
};

export default ContractsListRow;
