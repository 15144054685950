import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MarketingContextState } from '../../../../Context/Marketing/MarketingContext';
import { getAllContractsData } from '../../../_GlobalFunctions/contractFunctions';
import { utilCheckUserAttributeSp } from '../../../_GlobalFunctions/utilityFunctions';
import ContractsListRow from './ContractsListRow/ContractsListRow';
import { Box, Typography } from '@mui/material';
import LoadingSkeletonStack from '../../../_GlobalComponents/LoadingSkeletonStack';

// STATIC DATA ARRAY
const headerData = [
  { id: 0, label: 'All Contracts' },
  { id: 1, label: 'Overdue' },
  { id: 2, label: 'Due Soon' },
  { id: 3, label: 'Total Outstanding' },
];

const ContractsListSection = () => {
  // REDUX STATE
  const userId = useSelector((state) => state.userInformation.value.userID);

  // CONTEXT STATE
  const { contractDueTypeId, refreshContractData } = useContext(MarketingContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // FUNCTIONS
  const fetch = async () => {
    try {
      const canSeeAll = await utilCheckUserAttributeSp(userId, 'ENTITY_LIST_ALL');
      const fullRes = await getAllContractsData();
      let res = [];
      if (canSeeAll.data) {
        res = fullRes.map((e) => e);
      } else {
        res = fullRes.filter((e) => e.ss_publication_contract_id === 10);
      }

      switch (contractDueTypeId) {
        case 1:
          setData(
            res.filter(
              (e) =>
                e.contract_deleted !== 1 &&
                e.days_until_due < 0 &&
                e.ss_publication_payment_status_id === 1
            )
          );
          break;

        case 2:
          setData(
            res.filter(
              (e) =>
                e.contract_deleted !== 1 &&
                e.days_until_due < 31 &&
                e.days_until_due > 0 &&
                e.ss_publication_payment_status_id === 1
            )
          );
          break;

        case 3:
          setData(
            res.filter((e) => e.contract_deleted !== 1 && e.ss_publication_payment_status_id === 1)
          );
          break;

        default:
          setData(res.filter((e) => e.contract_deleted !== 1));
      }

      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  // USE EFFECT
  useEffect(() => {
    fetch();
  }, [contractDueTypeId, refreshContractData]);

  if (loading) {
    return <LoadingSkeletonStack />;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '1600px', marginX: 'auto' }}>
      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
        {headerData.find((e) => e.id === contractDueTypeId).label}
      </Typography>
      ContractsList {contractDueTypeId}
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '24px' }}>
        {data.map((e, i) => (
          <ContractsListRow key={i} item={e} idx={i} />
        ))}
      </Box>
    </Box>
  );
};

export default ContractsListSection;
