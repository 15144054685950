import React, { useState, useRef, useEffect } from 'react';
import { Box, MenuItem, Select, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useManufacturerContext } from './PerformanceDashboardContext';
import { useTheme, alpha } from '@mui/material/styles';
import { Pinch } from '@mui/icons-material';
import PushPinIcon from '@mui/icons-material/PushPin';

const ManufacturerSelect = () => {
  const {
    manufacturers,
    handleChange,
    selectedValue,
    selectedYear,
    setSelectedYear,
    skipAnimation,
    setSkipAnimation,
    salesSeries,
    performanceMode,
    setPerformanceMode,
    alignment,
    setAlignment,
    monthFilter,
    setMonthFilter,
    pieFilter,
    setPieFilter,
    setSlice,
    setPieDropDown,
    model,
    setModel,
  } = useManufacturerContext();

  const theme = useTheme();
  const [position, setPosition] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [pin, setPin] = useState(false);
  const [pinColor, setPinColor] = useState('#fff');
  const [pinBgColor, setPinBgColor] = useState(theme.palette.primary.main);
  const [stickyPadding, setStickyPadding] = useState('16px 16px');
  const [toggleOpacity, setToggleOpacity] = useState(1);
  const boxRef = useRef(null); // Ref for the Box component

  // useEffect(() => {
  //   const initalLoad = () => {
  //     console.log(scroll);
  //   };
  //   initalLoad();
  // }, [scroll]);

  //delete above

  //dynamic year button labels
  const dyButtonLabels =
    Array.from(new Set(salesSeries.map((item) => item.s_year))) // Collect unique s_year values
      .sort((a, b) => a - b) // Sort the integers in ascending order
      .map((year) => year.toString()) || []; // Convert the sorted integers to strings

  const handleToggle = (e, alignment) => {
    if (alignment !== null) {
      setSelectedYear(parseInt(e.target.value));
      setAlignment(alignment);
    }
  };

  const handlePin = () => {
    const pinTrueFalse = !pin;
    setPin(pinTrueFalse);

    if (pinTrueFalse) {
      setPinColor(theme.palette.accent.main);
      setPinBgColor(theme.palette.primary.main);
      setPosition('sticky');
      setBgColor(
        `linear-gradient(to right, rgba(251, 254, 249, 1) 0%, rgba(251, 254, 249, 0) 100%)`
      );
      setStickyPadding('0px');
    } else {
      setPosition('');
      setPinColor('#fff');
      setPinBgColor(theme.palette.primary.main);
      setStickyPadding('16px 16px');
    }
  };

  const handleOpactiy = () => {
    const pinValue = pin;
    if (!pinValue) {
      setToggleOpacity(1);
    } else {
      setToggleOpacity(0.3);
    }
  };

  return (
    <Box
      ref={boxRef}
      onMouseEnter={() => setToggleOpacity(1)} // Set opacity to 1 on hover
      onMouseLeave={() => handleOpactiy()} // Reset opacity when hover ends
      sx={{
        position: position,
        top: 0, // Distance from the top of the container
        display: 'flex',
        flexDirection: 'row',
        padding: stickyPadding,
        background: bgColor,
        backgroundColor: bgColor,
        zIndex: 99,
        transition: 'color 0.3s ease, padding 0.3s ease, background-color 0.3s ease', // Smooth transitions for color changes
        '&:hover': {
          backgroundColor: `rgba(251, 254, 249, 1)`,
          padding: '16px 16px',
        },
      }}
    >
      <Select
        labelId='dropdown-label'
        id='dropdown'
        value={selectedValue}
        onChange={handleChange}
        autoWidth
        style={{ opacity: toggleOpacity }}
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem',
        }}
      >
        {/* <MenuItem value='sports south all'>All</MenuItem> */}
        {manufacturers.map((manufacturer, index) => (
          <MenuItem key={index} value={manufacturer.description}>
            {manufacturer.description}
          </MenuItem>
        ))}
      </Select>

      <ToggleButtonGroup
        exclusive
        color='primary'
        value={alignment}
        onChange={handleToggle}
        aria-label='Platform'
        style={{ opacity: toggleOpacity }}
        sx={{ marginLeft: 2, padding: 1 }}
      >
        <ToggleButton value={dyButtonLabels[0]}>{dyButtonLabels[0]}</ToggleButton>
        {dyButtonLabels.length > 1 && (
          <ToggleButton value={dyButtonLabels[1]}>{dyButtonLabels[1]}</ToggleButton>
        )}
        {dyButtonLabels.length > 2 && (
          <ToggleButton value={dyButtonLabels[2]}>{dyButtonLabels[2]}</ToggleButton>
        )}
      </ToggleButtonGroup>
      <Box
        sx={{
          display: 'flex', // Enables flexbox layout
          alignItems: 'center', // Centers items vertically
          justifyContent: 'flex-end', // Moves items to the far right
          width: '100%',
          gap: '16px',
        }}
      >
        {monthFilter && (
          <Button
            sx={{
              height: 50,
              bgcolor: theme.palette.primary.main,
              color: '#ffffff',
              zIndex: 2,
              opacity: toggleOpacity,
            }}
            onClick={() => {
              setMonthFilter(null);
            }}
          >
            Reset Month Filter
          </Button>
        )}

        {pieFilter && (
          <Button
            style={{ opacity: toggleOpacity }}
            sx={{
              height: 50,
              bgcolor: theme.palette.primary.main,
              color: '#ffffff',
              zIndex: 2,
            }}
            onClick={() => {
              setPieFilter(null);
              setSlice(null);
              setPieDropDown('');
            }}
          >
            Reset Category Filter
          </Button>
        )}

        {model && (
          <Button
            style={{ opacity: toggleOpacity }}
            sx={{
              height: 50,
              bgcolor: theme.palette.primary.main,
              color: '#ffffff',
              zIndex: 2,
            }}
            onClick={() => {
              setModel(null);
            }}
          >
            Reset Model Filter
          </Button>
        )}

        <PushPinIcon
          onClick={handlePin}
          sx={{
            opacity: toggleOpacity,
            height: 50,
            width: 50,
            color: pinColor,
            backgroundColor: pinBgColor,
            textColor: pinColor,
            borderRadius: 2, // Round the background to fit the icon
            padding: 1,
            cursor: 'pointer',
            transition: 'color 0.3s ease, background-color 0.3s ease', // Smooth transitions for color changes
            '&:hover': {
              color: theme.palette.accent.main,
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          Pin Toolbar
        </PushPinIcon>
      </Box>

      {/* <Button
        sx={{
          bgcolor: theme.palette.primary.main,
          color: '#ffffff',
          zIndex: 2,
        }}
        onClick={() => {
          setSkipAnimation(!skipAnimation);
          setPerformanceMode(!performanceMode);
        }}
      >
        {`Performance Mode ${performanceMode}`}
      </Button> */}
    </Box>
  );
};

export default ManufacturerSelect;
