import React from 'react';
import MakeDeposit from './components/MakeDeposit';
import DepositsTable from './components/DepositsTable/DepositsTable';

const Deposits = () => {
  return (
    <div>
      {/* <MakeDeposit /> */}
      <DepositsTable />
    </div>
  );
};

export default Deposits;
