import axios from 'axios';

export async function getContactDetailsByUserId(userId) {
  try {
    const response = await axios.get(`/entity-contacts/getContactDetailsByUserId/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}
