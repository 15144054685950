import { Avatar, Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const links = [
  {
    id: 100,
    label: 'Marketing Contracts',
    url: '/manufacturer/contracts',
  },
  {
    id: 150,
    label: 'Marketing Funds',
    url: '/manufacturer/deposits',
  },
  // {
  //   id: 175,
  //   label: 'Marketing Payments',
  //   url: '/manufacturer/deposits',
  // },
  {
    id: 200,
    label: 'Media Kit',
    url: '/manufacturer/media-kit',
  },
  // {
  //   id: 300,
  //   label: 'Brand Maintenance',
  //   url: '/link',
  // },
  // {
  //   id: 400,
  //   label: 'Product Audit',
  //   url: '/link',
  // },
  {
    id: 500,
    label: 'Product Performance',
    url: '/sandbox/cameron/function-test',
  },
];

const ManufacturerNavBar = () => {
  // INIT's
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '100px',
        paddingX: '24px',
        display: 'flex',

        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ flex: 1 }}></Box>
      <Box
        sx={{
          gap: '32px',
          display: { xs: 'none', md: 'flex' },
          '& .btn': {
            fontWeight: 'bold',
            fontSize: '1.1em',
          },
        }}
      >
        {links.map((link, idx) => (
          <Box key={idx}>
            <Button className='btn' onClick={() => navigate(link.url)}>
              {link.label}
            </Button>
          </Box>
        ))}
      </Box>

      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Avatar
          alt='Remy Sharp'
          src='https://images.unsplash.com/photo-1688888745596-da40843a8d45?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        />
      </Box>
    </Box>
  );
};

export default ManufacturerNavBar;
