import { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Box, Button, Modal, useTheme } from '@mui/material';
import ModalPayments from './ModalPayments';

const useColumns = () => {
  //INIT's
  const theme = useTheme();

  return useMemo(
    () => [
      {
        accessorKey: 'entity',
        id: 'entity',
        header: 'Vendor',
        size: 166,
        grow: false,
      },
      // {
      //   accessorKey: 'method',
      //   id: 'method',
      //   header: 'Payment Method',
      //   size: 166,
      //   grow: false,
      // },
      // {
      //   accessorKey: 'type',
      //   id: 'type',
      //   header: 'Deposit Type',
      //   size: 166,
      //   grow: false,
      // },
      // {
      //   accessorKey: 'description',
      //   id: 'description',
      //   header: 'Description',
      //   size: 166,
      //   grow: false,
      // },
      {
        accessorFn: (row) =>
          `${row.entity_deposit_id} ${row.type} ${row.method} ${row.description}`,
        id: 'description',
        header: 'Description',
        Cell: ({ cell }) => (
          <Box>
            {cell.row.original.entity_deposit_id} - {cell.row.original.type} -{' '}
            {cell.row.original.method} - {cell.row.original.description}
          </Box>
        ),
        size: 200,
        grow: true,
      },
      // {
      //   accessorKey: 'deposit_amount',
      //   id: 'deposit_amount2',
      //   header: 'Amount2',
      //   Cell: ({ cell }) => {
      //     const amountUsed =
      //       cell.row.original.total_spent === null ? 0 : cell.row.original.total_spent;
      //     const remainingBalance = cell.row.original.deposit_amount - amountUsed;
      //     return (
      //       <Box sx={{ fontWeight: 'bold' }}>
      //         {cell.row.original.deposit_amount} -{' '}
      //         <span style={{ color: theme.palette.delete.main }}>
      //           {parseFloat(amountUsed).toFixed(2)}
      //         </span>{' '}
      //         ={' '}
      //         <span style={{ color: theme.palette.save.main }}>
      //           {parseFloat(remainingBalance).toFixed(2)}
      //         </span>
      //       </Box>
      //     );
      //   },

      //   size: 166,
      //   grow: false,
      // },
      {
        accessorKey: 'deposit_amount',
        id: 'deposit_amount',
        header: 'Amount',
        Cell: ({ cell }) => `$${cell.row.original.deposit_amount}`,
        size: 166,
        grow: false,
      },
      {
        accessorKey: 'total_spent',
        id: 'total_spent',
        header: 'Total Spent',
        Cell: ({ cell }) => {
          return (
            <ModalPayments
              depositId={cell.row.original.entity_deposit_id}
              total_spent={cell.row.original.total_spent}
            />
          );
        },

        size: 146,
        grow: false,
      },
      {
        accessorKey: 'time_created',
        id: 'time_created',
        header: 'Deposited On',
        Cell: ({ cell }) => dayjs(cell.row.original.time_created).format('MMM DD, YYYY'),
        size: 166,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        id: 'user',
        header: 'Agent',
        Cell: ({ cell }) => (
          <Box>
            {cell.row.original.first_name} {cell.row.original.last_name}
          </Box>
        ),
        size: 200,
        grow: false,
      },

      {
        accessorKey: 'notes',
        id: 'notes',
        header: 'Notes',
        Cell: ({ cell }) => <Box sx={{ whiteSpace: 'wrap' }}>{cell.row.original.notes}</Box>,
        size: 166,
        grow: true,
      },

      // Add more column definitions as needed
    ],
    []
  );
};

export default useColumns;
