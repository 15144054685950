import { Box, Button, Modal, useTheme } from '@mui/material';
import React, { useState } from 'react';
import MakeDeposit from './MakeDeposit';
import { AddCircleOutline } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const MakeDepositModal = ({ userId }) => {
  // INIT's
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button
        variant='contained'
        sx={{ backgroundColor: theme.palette.save.main }}
        onClick={() => handleOpen()}
        endIcon={<AddCircleOutline />}
      >
        Add Funds
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <MakeDeposit userId={userId} />
        </Box>
      </Modal>
    </Box>
  );
};

export default MakeDepositModal;
