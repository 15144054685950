import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { getContactDetailsByUserId } from '../../../../_GlobalFunctions/contactFunctions';
import {
  getAllParentEntitiesByContactId,
  getAllEntities,
  getAllSubBrandsByEntityId,
} from '../../../../_GlobalFunctions/entityFunctions';
import {
  getAllPublicationProducts,
  sendContractCreatedEmail,
} from '../../../../_GlobalFunctions/publicationFunctions';
import { createContract } from '../../../../_GlobalFunctions/contractFunctions';
import { Box, Button, MenuItem, TextField, useTheme } from '@mui/material';
import BrandDropDown from '../../../../Manufacturers/components/MediaKit/components/BrandDropDown';
import { MonetizationOn } from '@mui/icons-material';
import dayjs from 'dayjs';
import SnackBarV3 from '../../../../_GlobalComponents/SnackBarV3';

const CreateContract = ({ pub_type_id, data }) => {
  const theme = useTheme();

  // REDUX VALUES
  const userID = useSelector((state) => state.userInformation.value.userID);

  // LOCAL STATE
  const [isLoading, setIsLoading] = useState(true);
  const [vendorData, setVendorData] = useState([]);
  const [subBrandData, setSubBrandData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [contractData, setContractData] = useState({ vendor_id: 0, product_id: 0, brands: [] });
  const [forceOpen, setForceOpen] = useState();
  const [message, setMessage] = useState('Contract Submitted Successfully!');

  // FUNCTIONS

  const vendorChange = async (id) => {
    setContractData((prev) => ({ ...prev, vendor_id: id }));
    const res = await getAllSubBrandsByEntityId(id);
    const entities = await getAllEntities();
    const brands = res.map((e) => ({
      entity_id: e.entity_id,
      business_name: entities.find((ent) => ent.entity_id === e.entity_id).business_name,
    }));
    setSubBrandData(brands);
  };

  const brandChange = (ids) => {
    const res = ids.split(',').map((e) => e);
    setContractData((prev) => ({ ...prev, brands: res }));
  };

  const productChange = (id) => {
    setContractData((prev) => ({ ...prev, product_id: id }));
  };

  const fetch = async () => {
    // const contactInfo = await getContactDetailsByUserId(userID);
    const res = await getAllParentEntitiesByContactId(userID);
    const entities = await getAllEntities();
    const vendors = res.map((e) => ({
      entity_id: e.entity_id,
      business_name: entities.find((ent) => ent.entity_id === e.entity_id).business_name,
    }));
    setVendorData(vendors);

    const allPubProducts = await getAllPublicationProducts();
    const pubProductsById = allPubProducts
      .filter(
        (p) => p.types && p.types.split(',').includes(String(pub_type_id)) && p.inactive !== 1
      )
      .map((e) => ({
        ss_publication_product_id: e.ss_publication_product_id,
        description: e.description,
        standard_price: e.standard_price,
        types: e.types,
      }));
    setProductData(pubProductsById);
    setIsLoading(false);
  };

  const handleClick = async () => {
    try {
      const obj = {
        ss_publication_id: data.ss_publication_id,
        ss_publication_product_id: contractData.product_id,
        ss_publication_contract_status_id: 1,
        entity_id: contractData.vendor_id,
        user_id: userID,
        promotion_set_id: null,
        product_manufacturers: contractData.brands.join(','),
        last_status_change: dayjs().format('YYYY-MM-DD'),
        sold_price: productData.find((e) => e.ss_publication_product_id === contractData.product_id)
          .standard_price,
        notes: '',
        ss_publication_payment_status_id: 1,
      };
      const res = await createContract(obj);

      if (res.insertId > 0) {
        const content = (
          <div>
            <h3>Thank you for your participation!</h3>
            <h4>Contract Id #{res.insertId} Submitted...</h4>
          </div>
        );

        const emailObj = {
          emailAddress:
            'tony.ocon@oconfamily.net, tony.ocon@sportssouth.biz, athena.walls@sportssouth.biz',
          subject: `Marketing Contract #${res.insertId}`,
          htmlContent: ReactDOMServer.renderToStaticMarkup(content),
        };

        const sendMail = await sendContractCreatedEmail(emailObj);
        setMessage('Great! Contract Saved...');
        setForceOpen(dayjs().unix());
      }
    } catch (err) {
      console.log('err: ', err);
    }
    // data.ss_publication_id,
    // data.ss_publication_product_id,
    // data.ss_publication_contract_status_id,
    // data.entity_id,
    // data.user_id,
    // data.promotion_set_id,
    // data.product_manufacturers,
    // data.last_status_change,
    // data.sold_price,
    // data.notes,
  };

  useEffect(() => {
    fetch();
  }, [pub_type_id]);

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <TextField
        sx={{ marginBottom: '24px' }}
        select
        label='Vendors'
        value={contractData.vendor_id}
        onChange={(e) => vendorChange(e.target.value)}
        fullWidth
      >
        {[{ entity_id: 0, business_name: 'Choose Vendor' }, ...vendorData].map((e, idx) => (
          <MenuItem key={idx} value={e.entity_id}>
            {e.business_name}
          </MenuItem>
        ))}
      </TextField>

      {/* <TextField
        select
        disabled={contractData.vendor_id === 0}
        label='Brands'
        value={contractData.vendor_id}
        //onChange={(e) => vendorChange(e.target.value)}
        fullWidth
      >
        {[{ entity_id: 0, business_name: 'Choose Brands' }, ...subBrandData].map((e, idx) => (
          <MenuItem key={idx} value={e.entity_id}>
            {e.business_name}
          </MenuItem>
        ))}
      </TextField> */}

      <BrandDropDown
        brandData={subBrandData}
        func={brandChange}
        vendorId={contractData.vendor_id}
      />

      <TextField
        sx={{ marginTop: '24px' }}
        select
        disabled={contractData.vendor_id === 0}
        label='Publication Options'
        value={contractData.product_id}
        onChange={(e) => productChange(e.target.value)}
        fullWidth
      >
        {[{ ss_publication_product_id: 0, description: 'Choose Options' }, ...productData].map(
          (e, idx) => (
            <MenuItem key={idx} value={e.ss_publication_product_id}>
              {e.description} {e.ss_publication_product_id !== 0 && ` - $${e.standard_price}`}
            </MenuItem>
          )
        )}
      </TextField>

      <Button
        fullWidth
        variant='contained'
        endIcon={<MonetizationOn />}
        sx={{ backgroundColor: theme.palette.save.main, marginTop: '24px' }}
        onClick={() => handleClick()}
      >
        Submit
      </Button>
      <SnackBarV3 message={message} forceOpen={forceOpen} />
    </Box>
  );
};

export default CreateContract;
