import axios from 'axios';
import dayjs from 'dayjs';
import { getAllContractsData } from './contractFunctions';

// DEPOSITS
export const getDeposits = async () => {
  const res = await axios.get(`/deposits/getDeposits`);
  return res;
};

// PAYMENTS
export const makePayment = async (data) => {
  const res = await axios.post(`/payments/makePayment`, {
    data: data,
  });

  return res.data;
};

export const getDepositsPayments = async (depositId) => {
  const res = await axios.get(`/deposits/getDepositsPayments/${depositId}`);
  return res.data;
};

export const getPaymentsPrimaryIdentifier = async (id, type) => {
  const res = await axios.get(`/payments/getPaymentsPrimaryIdentifier/${id}/${type}`);
  return res.data;
};

export const getContractsRemainingBalance = async (id, type, contract_amount) => {
  try {
    const res = await getPaymentsPrimaryIdentifier(id, type);
    console.log('res getContractsRemainingBalance: ', res);
    const values = res.map((e) => Number(e.payment_amount));
    const total = values.length > 0 ? values.reduce((sum, value) => sum + value, 0) : 0;
    return Number(contract_amount) - Number(total);
  } catch (err) {
    console.log('err: ', err);
  }
};

export const setContractsPaidStatus = async (id, status) => {
  const fetch = await getAllContractsData();
  const contractAmount = fetch.find((e) => e.ss_publication_contract_id === id).sold_price;
  const totalSpent = fetch.find((e) => e.ss_publication_contract_id === id).total_spent;
  const remainingBalance = Number(contractAmount) - Number(totalSpent);
  const res = await axios.post(`/payments/setContractsPaidStatus`, {
    ss_publication_contract_id: id,
    ss_publication_payment_status_id: remainingBalance > 0.0 ? 1 : 2,
  });
  return res.data;
};

export const insertCreditDeposit = async (depositDetails = {}) => {
  const data = await axios.post(`/entities/insertCreditDeposit`, depositDetails);
  return data.data;
};

export const getEntityDepositMethods = async () => {
  const data = await axios.get('/entities/getEntityDepositMethods');
  return data.data;
};

export const getEntityDepositTypes = async () => {
  const data = await axios.get('/entities/getEntityDepositTypes');
  return data.data;
};

export const getAllEntities = async () => {
  const data = await axios.get('/entities/getAllEntities');
  return data.data;
};

export const getEntitiesIdByUserId = async (userId) => {
  const data = await axios.get(`/entities/getEntitiesIdByUserId/${userId}`);
  console.log('getEntitiesIdByUserId: ', data.data, ' | ', userId);
  return data.data;
};
