import React from 'react';
import PublicationProducts from './PublicationProducts';
import { Box } from '@mui/material';

const PublicationProductsExport = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <PublicationProducts />
    </Box>
  );
};

export default PublicationProductsExport;
