import React, { useEffect, useState } from 'react';
import {
  getDeposits,
  getEntityDepositTypes,
  getEntityDepositMethods,
} from '../../../../../_GlobalFunctions/accountingFunctions';
import { getAllEntities } from '../../../../../_GlobalFunctions/entityFunctions';
import { getContactDetailsByUserId } from '../../../../../_GlobalFunctions/contactFunctions';

import useColumns from './Columns';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from 'material-react-table';
import { Box, Skeleton, useTheme } from '@mui/material';
import MakeDepositModal from '../MakeDepositModal';

import { useSelector } from 'react-redux';

const DepositsTable = () => {
  // INIT's
  const theme = useTheme();
  const columns = useColumns();

  // REDUX STATE
  const userID = useSelector((state) => state.userInformation.value.userID);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetch = async () => {
    try {
      const res = await getDeposits();
      const entityRes = await getAllEntities();
      const methodsRes = await getEntityDepositMethods();
      const typesRes = await getEntityDepositTypes();

      const newData = await Promise.all(
        res.data.map(async (e) => {
          const contractRes = await getContactDetailsByUserId(e.user_id);

          return {
            ...e,
            entity: entityRes.find((v) => v.entity_id === e.entity_id)?.business_name || 'Unknown',
            method:
              methodsRes.find((v) => v.entity_deposit_method_id === e.entity_deposit_method_id)
                ?.description || 'Unknown',
            type:
              typesRes.find((v) => v.entity_deposit_type_id === e.entity_deposit_type_id)
                ?.description || 'Unknown',
            first_name: contractRes[0].first_name || '',
            last_name: contractRes[0].last_name || '',
          };
        })
      );

      setData(newData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }

    // const newData = res.data.map(async (e) => {
    //   const contractRes = await getContactDetailsByUserId(e.user_id);
    //   return {
    //     ...e,
    //     entity: entityRes.find((v) => v.entity_id === e.entity_id).business_name,
    //     method: methodsRes.find((v) => v.entity_deposit_method_id === e.entity_deposit_method_id)
    //       .description,
    //     type: typesRes.find((v) => v.entity_deposit_type_id === e.entity_deposit_type_id)
    //       .description,
    //     first_name: contractRes.first_name,
    //     last_name: contractRes.last_name,
    //   };
    // });
    // setData(await newData);
    // setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  // TABLE OPTIONS
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    initialState: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 100 },
      showGlobalFilter: true,
      columnOrder: [
        'description',
        'entity',
        'user',
        'time_created',
        'deposit_amount',
        'total_spent',
        'notes',
      ],
    },
    enableColumnResizing: true,
    enableRowSelection: false,
    // state: { rowSelection: selectedRowIds },
    // onRowSelectionChange: setSelectedRowIds,
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        backgroundColor: 'white',
        padding: '18px',
        borderRadius: '18px',
        maxWidth: '1600px',
        mx: 'auto',
      },
    },
    muiTableContainerProps: { sx: { maxHeight: '700px' } },
    muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'white',
        pt: 2,
        fontWeight: 'bold',
        height: 'auto',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        mb: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottomColor: theme.palette.secondary.main,
        pb: 2,
      },
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search Publications',
      sx: { minWidth: '500px' },
      variant: 'outlined',
    },
    renderTopToolbar: () => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '15px',
        }}
      >
        <Box sx={{ gridColumn: 'span 3' }}></Box>
        <Box sx={{ gridColumn: 'span 2' }}>
          <MrtGlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MakeDepositModal userId={userID} />
          <MrtToggleDensePaddingButton table={table} />
          <MrtToggleFiltersButton table={table} />
          <MrtShowHideColumnsButton table={table} />
          <MrtToggleFullScreenButton table={table} />
        </Box>
      </Box>
    ),
  });

  if (loading) {
    return (
      <Box sx={{ mx: 'auto', width: '100%', maxWidth: '1600px' }}>
        <Skeleton />
        <Skeleton animation='wave' height={40} />
        <Skeleton animation={false} height={120} />
      </Box>
    );
  }

  console.log('data: ', data);
  return (
    <>
      <Box sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}>
        <MaterialReactTable table={table} />
      </Box>
    </>
  );
};

export default DepositsTable;
