import { DeleteForever } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { deleteContracts } from '../../../../_GlobalFunctions/contractFunctions';

const DeleteContract = ({ contractId }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [open, setOpen] = useState(false);

  // FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    if (typeof contractId === 'number') {
      console.log('contractId is a string');
    } else {
      console.log('contractId is not a string');
    }

    const res = await deleteContracts(contractId);
    console.log('res: ', res);
    handleClose();
  };

  return (
    <>
      <IconButton
        sx={{ color: theme.palette.delete.main, fontWeight: 'bold' }}
        onClick={() => handleClickOpen()}
      >
        <DeleteForever />
      </IconButton>

      <Dialog
        className='delete-contract-dialog'
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          ' .MuiDialog-paper': {
            backgroundColor: 'white',
            padding: '24px ',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box></Box>
        <Box>
          <Typography variant='h5' sx={{ fontWeight: 'bold', color: theme.palette.delete.main }}>
            DELETE CONTRACT #{contractId}
          </Typography>
        </Box>
        <Box sx={{ mb: '24px' }}>
          <Typography>This will permanently delete this contract</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            size='small'
            variant='contained'
            onClick={() => handleClick()}
            autoFocus
            sx={{
              backgroundColor: theme.palette.delete.main,
              fontWeight: 'bold',
              fontSize: '1.1em',
            }}
          >
            Delete
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteContract;
