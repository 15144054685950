import React from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from './PerformanceDashboardContext';

const ManufacturerTable = () => {
  const {
    pieFilter,
    salesByItem,
    monthFilter,
    selectedYear,
    currentYear,
    lastYear,
    setModel,
    model,
  } = useManufacturerContext();
  const theme = useTheme();

  if (salesByItem.length > 0) {
    console.log(salesByItem);
  } else {
    setModel(null);
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (value) => (value != null ? currencyFormatter.format(value) : '$0'),
    cellClassName: 'font-tabular-nums',
  };

  const addCommas = {
    type: 'number',
    valueFormatter: (value) => (value != null ? value.toLocaleString() : 0),
  };

  //add commas not working

  const columns = [
    { field: 'id', headerName: 'SKU', flex: 1.2 },
    {
      field: 'sales_lastYear',
      headerName: `Sales ${selectedYear - 1}`,
      flex: 0.5,
      ...usdPrice,
    },
    {
      field: 'sales_current',
      headerName: `Sales ${selectedYear}`,
      flex: 0.5,
      ...usdPrice,
    },
    {
      field: 'qtyoh',
      headerName: 'On Hand',
      flex: 0.3,
      ...addCommas,
    },
    { field: 'category', headerName: 'Category', flex: 0.5 },
  ];

  const rows = salesByItem.map((item) => ({
    id: item.as400_description, // SKU
    sales_current: item[`sales_${selectedYear}`],
    sales_lastYear: item[`sales_${selectedYear - 1}`],
    qtyoh: parseInt(item.qtyoh) + parseInt(item.RESRV),
    category: item.category, // Category
  }));

  return (
    <>
      <Box
        sx={{
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 'bold',
            fontSize: '1.85em',
            color: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          {'All '}
          {pieFilter && !model
            ? `${pieFilter.label} for`
            : model && pieFilter
            ? `${model} ${pieFilter.label} for`
            : model
            ? `${model} for`
            : 'Items for'}
          {monthFilter ? ` ${monthFilter.axisValue} ${selectedYear}` : ` ${selectedYear}`}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          padding: '24px',
          height: 960,
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          sx={{ border: 0 }}
          sortingOrder={['asc', 'desc']}
          initialState={{
            sorting: {
              sortModel: [{ field: 'sales_current', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        />
      </Box>
    </>
  );
};

export default ManufacturerTable;
