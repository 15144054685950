import React, { useEffect, useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme, alpha } from '@mui/material/styles';
import { useManufacturerContext } from './PerformanceDashboardContext';

const ManufacturerBar = () => {
  const {
    yoyProducts = [],
    model,
    setModel,
    pieFilter,
    selectedYear,
    monthFilter,
  } = useManufacturerContext();
  const [labelMap, setLabelMap] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [labelSpace, setLabelSpace] = useState(100);
  const [top5Items, setTop5Items] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchYoyProducts = () => {
      if (Array.isArray(yoyProducts) && yoyProducts.length > 0) {
        const top5 = yoyProducts.sort((a, b) => b.sum - a.sum).slice(0, 7);
        const top5Map = top5.map((e) => e.label);
        const chartMap = top5.map((e) => Math.round(e.sum));
        setTop5Items(top5);
        setLabelMap(top5Map);
        setChartValues(chartMap);
        const maxLength = Math.max(...top5Map.map((e) => e.length));
        setLabelSpace(
          maxLength > 20
            ? 180
            : maxLength > 15
            ? 120
            : maxLength > 8
            ? 100
            : maxLength > 5
            ? 65
            : 50
        );
      }
    };
    fetchYoyProducts();
  }, [yoyProducts]);

  const handleBarClick = (i) => {
    if (labelMap[i.dataIndex] === model) {
      setModel(null);
    } else {
      setModel(labelMap[i.dataIndex]);
    }
  };

  const chartSetting = {
    yAxis: [
      {
        data: labelMap,
        scaleType: 'band',
      },
    ],
    xAxis: [
      {
        colorMap: {
          type: 'piecewise',
          thresholds: [0],
          colors: [`${alpha(theme.palette.accent.main, 0.9)}`, theme.palette.primary.main],
        },
      },
    ],
    width: 700,
    height: 450,
    borderRadius: 5,
    margin: { top: 20, right: 40, bottom: 40, left: labelSpace },
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '1.85em',
          color: theme.palette.primary.main,
          marginBottom: 1,
        }}
      >
        {'Top '}
        {pieFilter ? `${pieFilter.label} ` : 'Products '}
        {monthFilter ? `for ${monthFilter.axisValue} ` : ' '}
        {selectedYear}
      </Typography>
      <Box
        sx={{
          bgcolor: `${theme.palette.primary.main}10`,
          borderRadius: '10px',
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <BarChart
          layout='horizontal'
          sx={{
            '.MuiBarLabel-root': {
              fill: '#fff',
              fontSize: '1.2em',
            },
          }}
          series={[
            {
              data: chartValues,
              highlightScope: {
                highlight: 'item',
              },
            },
          ]}
          onItemClick={(e, i) => handleBarClick(i)}
          {...chartSetting}
          grid={{
            horizontal: true,
            vertical: false,
          }}
          barLabel={(item, bar) => {
            if (bar.bar.width > 60) {
              var label = `${item.value.toLocaleString()}`;
            } else {
              var label = '';
            }
            return label;
          }}
          slotProps={{
            legend: {
              position: {},
              itemMarkWidth: 0,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ManufacturerBar;
