import React, { useEffect, useState } from 'react';

import {
  getAllPublication,
  getAllPublicationProducts,
  getAllPublicationTypes,
  PublicationProductCategories,
} from '../../../_GlobalFunctions/publicationFunctions';
import { Box, Chip, Link, Paper, Skeleton, styled, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import CreateContract from './components/CreateContract';

// Material Styled Components
const TopBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url('/images/publications/turkey_hunting_large.jpg')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',

  aspectRatio: '21/9',
}));

const PublicationDetailsDrawerView = ({ pubId }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const work = async () => {
      try {
        const [pubs, types, products, getCategories] = await Promise.all([
          getAllPublication(),
          getAllPublicationTypes(),
          getAllPublicationProducts(),
          PublicationProductCategories(pubId),
        ]);

        const pubDetails = await pubs.find((e) => e.ss_publication_id === parseInt(pubId));
        const allDetails = {
          ...pubDetails,
          publication_type: types.find(
            (t) => t.ss_publication_type_id === pubDetails.ss_publication_type_id
          ).description,
          products: [
            ...products
              .filter(
                (e) =>
                  typeof e.types === 'string' &&
                  e.types.split(',').includes(String(pubDetails.ss_publication_type_id))
              )
              .map((p) => ({
                ss_publication_product_id: p.ss_publication_product_id,
                description: p.description,
              })),
          ],
        };
        setData({ ...allDetails });
        setCategories(getCategories);

        setLoading(false);
      } catch (error) {
        return console.log('error: ', error);
      }
    };
    work();
  }, [pubId]);

  if (loading) {
    return (
      <Box sx={{ width: '700px', padding: '24px' }}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant='circular' width={40} height={40} />
        <Skeleton variant='rectangular' width={210} height={60} />
        <Skeleton variant='rounded' width={210} height={60} />
        <Skeleton />
        <Skeleton animation='wave' />
        <Skeleton animation={false} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'auto',
        width: '700px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        {/* BANNER AND DETAILS */}
        <Box>
          <TopBannerWrapper sx={{ marginBottom: '16px' }}></TopBannerWrapper>
          <Box sx={{ paddingX: '24px' }}>
            <Typography variant='h5'>{data.description}</Typography>
            <Typography>{data.detailed_description}</Typography>
          </Box>
        </Box>

        {/* DATES */}
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginTop: '16px',
            padding: '16px 24px',
          }}
        >
          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Submit Contract
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.submit_contract_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Art Due
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.submit_art_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              In Production
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.production_start_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Start
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.start_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>

          <Box sx={{ width: '125px' }}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.accent.main }}>
              Expires
            </Typography>
            <Typography sx={{ color: '#666' }}>
              {dayjs(data.expiration_date).format('MMM DD, YYYY')}
            </Typography>
          </Box>
        </Box>

        {/* CATEGORIES */}
        <Box
          sx={{
            marginX: 'auto',
            paddingX: '16px',
            display: categories.length > 0 ? 'block' : 'none',
          }}
        >
          <h3>Product Categories:</h3>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {categories.map((e, idx) => (
              <Chip
                sx={{
                  backgroundColor: `${theme.palette.accent.main}22`,
                  color: theme.palette.accent.main, //colors[idx],
                  fontWeight: 'bold',
                  borderColor: theme.palette.accent.main, //colors[idx],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                }}
                key={idx}
                label={e.category}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {/* CREATE CONTRACT */}
      <Box
        sx={{
          backgroundColor: `${theme.palette.accent.main}11`,
          padding: '24px',
          paddingTop: '0',
          borderTop: `solid 2px ${theme.palette.accent.main}`,
        }}
      >
        <h3>Sign Me Up!</h3>
        <CreateContract pub_type_id={data.ss_publication_type_id} data={data} />
      </Box>
    </Box>
  );
};

export default PublicationDetailsDrawerView;
