import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function PageTitle() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/calendar':
        setPageTitle('Calendar');
        document.title = 'Calendar';
        break;
      case '/image-library':
        setPageTitle('Image Library');
        document.title = 'Image Library';
        break;
      case '/email-builder':
        setPageTitle('Email Builder');
        document.title = 'Email Builder';
        break;
      case '/crm':
        setPageTitle('Customer Relationship Management');
        document.title = 'CRM';
        break;
      case '/account-notes':
        setPageTitle('Account Notes');
        document.title = 'Account Notes';
        break;
      case '/pdm/current-attributes-drawer-demo':
        setPageTitle('Product Data Management');
        document.title = 'Product Data Management';
        break;
      case '/entity-manager/entity-updater':
        setPageTitle('Entity Manager');
        break;
      case '/advertising/':
        setPageTitle('Advertising Manager');
        break;
      case '/publish-items':
        setPageTitle('Publish Items');
        break;
      case '/sales-promotions':
        setPageTitle('Sales Promotions');
        document.title = 'Sales Promotions';
        break;
      case '/image-management':
        setPageTitle('Image Management');
        document.title = 'Image Management';
        break;
      case '/marketing/entity/manage-entities':
        setPageTitle('Entity Management');
        document.title = 'Entity Management';
        break;
      case '/marketing/entity/manage-contacts':
        setPageTitle('Entity Contacts');
        document.title = 'Entity Contacts';
        break;
      case '/marketing/publications':
        setPageTitle('Publication Management');
        document.title = 'Publication Management';
        break;

      case '/marketing/entity-contacts/manage-entity-contacts':
        setPageTitle('Entity Contact Management');
        document.title = 'Entity Contact Management';
        break;
      case '/marketing':
        setPageTitle('Marketing Management');
        document.title = 'Marketing Management';
        break;
      case '/exhibitors':
        setPageTitle('ROTR Exhibitors');
        document.title = 'ROTR Exhibitors';
        break;
      case '/attendees':
        setPageTitle('ROTR Attendees');
        document.title = 'ROTR Attendees';
        break;
      case '/badges':
        setPageTitle('ROTR Badges');
        document.title = 'ROTR Badges';
        break;
      case '/directory':
        setPageTitle('Directory');
        document.title = 'Directory';
        break;
      case '/manufacturer/media-kit':
        setPageTitle('Media Kit');
        document.title = 'Media Kit';
        break;
      case '/advertising/contracts':
        setPageTitle('Marketing Contracts');
        document.title = 'Marketing Contracts';
        break;
      case '/advertising/media-kit':
        setPageTitle('Media-Kit');
        document.title = 'Media-Kit';
        break;
      case '/advertising/product/performance-dashboard':
        setPageTitle('Performance Dashboard');
        document.title = 'Performance Dashboard';
        break;
      case '/advertising/publications/publication-products':
        setPageTitle('Publication Products');
        document.title = 'Publication Products';
        break;
      default:
        setPageTitle('');
        document.title = 'Sports South Hub';
        break;
    }
  }, [location]);

  return pageTitle;
}

export default PageTitle;
