import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// CONTEXT STATE
export const MarketingContextState = createContext();

// PRIMARY COMPONENT
const MarketingContext = ({ children }) => {
  // REDUX STATE
  const userID = useSelector((state) => state.userInformation.value.userID ?? 0);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [contractDueTypeId, setContractDueTypeId] = useState(0);
  const [refreshContractData, setRefreshContractData] = useState(0);

  // USE EFFECT
  useEffect(() => {
    setLoading(false);
  }, [userID]);

  // CONTEXT OBJECT TO PASS THROUGH PROVIDER
  const contextValue = React.useMemo(
    () => ({
      userId: userID,
      contractDueTypeId,
      setContractDueTypeId,
      refreshContractData,
      setRefreshContractData,
    }),
    [userID, contractDueTypeId, refreshContractData]
  );

  if (loading) {
    return null;
  }

  return (
    <MarketingContextState.Provider value={contextValue}>{children}</MarketingContextState.Provider>
  );
};

export default MarketingContext;
