import { ProductDataContextState } from '../../../Context/Marketing/ProductData/ProductDataContext';
import { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { getProductsByFacets, getFacetsByCategory, getProductsByFacetCat, getUniqueOptions, getFacetsByProductIds, deleteValuesByIds, insertAttributeValue, removeAndApplyAttributeValue } from '../../_GlobalFunctions/pdm';

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_TopToolbar,
  MRT_BottomToolbar,
  MRT_ActionMenuItem,
} from 'material-react-table';
import FacetOptionReplaceOptions from '../Dashboard/components/FacetOptionReplaceOptions';
import { Button, FormControlLabel, FormGroup, Checkbox, Drawer, Typography, Stack, Grow, Box } from '@mui/material';
import { RedshiftData } from 'aws-sdk';
import { Search, ViewComfy } from '@mui/icons-material';

export default function FacetOptionReplaceTable( props ) {

    const { allCategories, allDepartments, allTypes, allManufacturers, getDesiredSkuInfo } = useContext(ProductDataContextState)
    const [data, setData] = useState([])

    const [category, setCategory] = useState()
    const [facet, setFacet] = useState()
    const [facetOption, setFacetOption] = useState()
    const [newFacet, setNewFacet] = useState()
    const [newFacetOption, setNewFacetOption] = useState()
    const [actionType, setActionType] = useState()

    const [filterBlock, setFilterBlock] = useState(false)
    const [confirmValues, setConfirmValues] = useState()

    const [facetList, setFacetList] = useState([{description: 'All', product_facet_id:0}])
    const [facetOptionList, setFacetOptionList] = useState([])
    const [newFacetOptionList, setNewFacetOptionList] = useState([])
    const [ready, setReady] = useState(false)

    const [primaryFacet, setPrimaryFacet] = useState(false)

    const [drawerOpen, isDrawerOpen] = useState(false)
    const [drawerBody, setDrawerBody] = useState(<div></div>)

    let prodCatData = useRef()

    const [slideConfirm, setSlideConfirm] = useState(false)
    

    useEffect(() => {
        console.log('confirming', confirmValues)
        console.log(actionType)
        switch(actionType){
            case 'add':
                if(confirmValues.categorySelected && confirmValues.newFacetSelected && confirmValues.newFacetOptionSelected){
                    setReady(true)
                }else{
                    console.log('setting False')
                    setReady(false)
                }
                break
            case 'replace':
                console.log('in replace')
                if(confirmValues.categorySelected && 
                    confirmValues.facetSelected && 
                    confirmValues.facetOptionSelected && 
                    confirmValues.newFacetSelected && 
                    confirmValues.newFacetOptionSelected){
                    setReady(true)
                }else{
                    setReady(false)
                }
                break
            case 'delete':
                if(confirmValues.categorySelected && confirmValues.facetSelected && confirmValues.facetOptionSelected){
                    setReady(true)
                }else{
                    setReady(false)
                }
                break
            default:
                setReady(false)
                break
        }
    }, [confirmValues])

    const setOptionsConfirm = (val) => {
        setConfirmValues(val)
    }

    const getItems = async ( type, info ) => {
        //console.log(type)
        switch(type){
            case 'facet':
                //need to pull from the list of products
                console.log(await getProductsByFacets(info[0], info[1]))
                break
            case 'category':
                //need to pull from the list of products
                setData(await getDesiredSkuInfo('category', info))
                break
            case 'reset':
                setData([])
                break
            default:
                break            
        }
    }

    const setValues = async ( type, id ) => {
        if(id !== 'reset'){
        switch(type){
            case "category":
                setCategory(id)
                setFacetList(await getFacetsByCategory(id.product_category_id))
                let newProds = await getDesiredSkuInfo(type, id.product_category_id)
                prodCatData.current = newProds
                setData(newProds)
                console.log(newProds)               
                break
            case "facet":
                setFacet(id)
                if(actionType !== 'add' || !filterBlock){
                    let FOData = []
                    let retDat = (await getProductsByFacetCat(id.product_facet_id, category.product_category_id))
                    retDat.map((val) => {
                        let searchData = data.find((e) => e.product_id == val.product_id)
                            if(searchData){
                                FOData.push(searchData)
                            }
                    })
                    console.log(FOData)
                   setData(FOData)
                }
                
                setFacetOptionList(await getUniqueOptions(id.product_facet_id, category.product_category_id))
                setNewFacetOptionList(await getUniqueOptions(id.product_facet_id, category.product_category_id))
                break
            case "facetOption":
                setFacetOption(id)
                if(actionType !== 'add'){
                    let prdFacOptList = ((await getProductsByFacets(facet.product_facet_id, id.product_facet_option_id)).data)
                    let retDat = (await getDesiredSkuInfo('category', category.product_category_id))
                    let catPrdList = []
                        prdFacOptList.map((val) => {
                            let searchData = retDat.find((e) => e.product_id == val.product_id)
                            if(searchData){
                                catPrdList.push(searchData)
                            }
                        })
                    setData(catPrdList)
                }
                console.log()
                break
            case "newFacet":
                setNewFacet(id)
                setNewFacetOptionList(await getUniqueOptions(id.product_facet_id, category.product_category_id))
                break
            case "newFacetOption":
                setNewFacetOption(id)
                break
            case "type":
                // console.log('setting actionType')
                // console.log(id)
                //let newProdsType = await getDesiredSkuInfo('category', category.product_category_id)
                setData(prodCatData.current)
                setActionType(id) 
                break   
            default:
                break
        }
    }else{
        switch(type){
            case "category":
                setCategory(null)
                setFacetList(null)
                setData({})
                break
            case "facet":
                setFacet(null)
                let newProds = await getDesiredSkuInfo('category', category.product_category_id)
                console.log(newProds)
                setData(newProds)
                setFacetOptionList(null)
                setNewFacetOptionList(null)
                break
            case "facetOption":
                setFacetOption(null)
                if(actionType !== 'add' || !filterBlock){
                    console.log(facet)
                    let FOData = []
                    let retDat = (await getProductsByFacetCat(facet.product_facet_id, category.product_category_id))
                    console.log(retDat)
                    retDat.map((val) => {
                        let searchData = data.find((e) => e.product_id == val.product_id)
                            if(searchData){
                                FOData.push(searchData)
                            }
                    })
                    console.log(FOData)
                   setData(FOData)
                }
                break
            case "newFacet":
                setNewFacet(null)
                setNewFacetOptionList(null)
                break
            case "newFacetOption":
                setNewFacetOption(null)
                break
            case "type":
                setActionType(id) 
                break   
            default:
                break
        }
    }
    }

    const filterItems = (check) => {
        console.log(check)
        setFilterBlock(check)
    }
    
    const openDataDrawer = async (pi, type, pc, row) => {
        console.log(pi)
        console.log(type)

        const clickToClear = new MouseEvent("click", {
            bubbles: true,
            cancelable: false,
            clientX: 0,
            clientY: 0
        })

        if(type === 'extra'){
            console.log(row)
            let body = (
                <Stack spacing={3}>
                    <Typography variant='h4' color='primary' width={'75%'} textAlign={'center'} alignItems={'center'} >
                        All info for {pc}
                    </Typography>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        AS400 Description
                    </Typography>
                    <Typography color='secondary' maxWidth={'50%'}>
                        {row.original.as400_description}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}} >
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Category
                    </Typography>
                    <Typography color='secondary' maxWidth={'50%'}>
                        {row.original.category}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Country of Origin
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.country_of_origin}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Department
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.department}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Description
                    </Typography>
                    <Typography color='secondary' maxWidth={'50%'}>
                        {row.original.description}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Detailed Description
                    </Typography>
                    <Typography color='secondary' maxWidth={'50%'}>
                        {row.original.detailed_description}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Manufacturer
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.manufacturer}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Manufacturer SKU
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.manufacturer_sku}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Model
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.model}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Product Code/Sports South Number
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.product_code}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Product ID
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.product_id}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Scan 1
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.scan_1}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Scan 2
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.scan_2}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        Series
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.series}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary' >
                        True Value
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.truevalue}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary'>
                        Type
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.type}
                    </Typography>
                    </div>
                    <div style={{padding:20, borderTop:'solid 1px'}}>
                    <Typography gutterBottom={true} variant='h5' color='primary'>
                        UPC Code
                    </Typography>
                    <Typography color='secondary'>
                        {row.original.upc_code}
                    </Typography>
                    </div>
                </Stack>
            )
            setDrawerBody(body)
            isDrawerOpen(!drawerOpen)
        }

        if(type === 'facet'){
            let retData = await getFacetsByProductIds(pi)
            const groupedData = Object.values(
                retData.reduce((acc, { description, facet_value }) => {
                  // Check if the description already exists in the accumulator
                  if (!acc[description]) {
                    acc[description] = { description, list: [] }; // Initialize if not present
                  }
                  acc[description].list.push(facet_value); // Add the title to the list
                  return acc;
                }, {})
              );
            
              let body = (
                <div>
                    <Typography variant='h4' color='primary' width={'75%'} textAlign={'center'} alignItems={'center'}>All Facet Options for {pc}</Typography>
                    {groupedData.map((group, index) => (
                        <div key={index} style={{padding:20, borderTop:'solid 1px'}}>
                            <Typography gutterBottom={true} variant='h5' color='primary'>{group.description}</Typography>
                            {group.list.map((fo, ind) => (
                                <Typography key={{ind}} color='secondary'>{fo}</Typography>
                            ))}
                        </div>
                    ))}
                </div>
              )
              console.log(groupedData)
              setDrawerBody(body)
              isDrawerOpen(!drawerOpen)
              
              document.dispatchEvent(clickToClear)
        }
    } 

    const closeDataDrawer = () => {
        isDrawerOpen(false)
    }
 
  const columns = useMemo(
    () => [
            {
            id:100,
            accessorKey: 'product_code',
            header:'SS#',
            size:150,
            },{
                        id:400,
                        accessorKey: 'category',
                        header:'Category',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allCategories
                    },{
                        id:500,
                        accessorKey: 'upc_code',
                        header:'UPC Code',
                        size:150,
                    },{
                        id:600,
                        accessorKey: 'product_id',
                        header:'Product ID',
                        size:150,
                    },{
                        id:700,
                        accessorKey: 'as400_description',
                        header:'Description',
                        size:350,
                        grow: false
                    },{
                        id:800,
                        accessorKey: 'model',
                        header:'Model',
                        size:150,
                    },{
                        id:899,
                        accessorKey: 'series',
                        header:'Series',
                        size:150,
                    },{
                        id:1100,
                        accessorKey: 'scan2',
                        header:'Scan 2',
                        size:150,
                    }
    ]
  );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row, table}) => [
            <MRT_ActionMenuItem
                icon={<ViewComfy />}
                key="viewai"
                label='View Additional Info'
                onClick={() => {
                    openDataDrawer(row.original.product_id, 'extra', row.original.product_code, row)
                    closeMenu()
                }}
                table={table}
                
            />,
            <MRT_ActionMenuItem
                icon={<Search />}
                key="viewffo"
                label='View Facet info'
                onClick={() => { 
                    openDataDrawer(row.original.product_id, 'facet', row.original.product_code )
                    closeMenu()
                }}
                table={table}
            />
        ],
        enableStickyHeader: true,
        renderTopToolbar: ({ table }) => {
            const tableReset = () => {
                table.resetRowSelection()
            }
            
            
            return(
                <div>
                    
                    <FacetOptionReplaceOptions 
                        categories={allCategories} 
                        getItems={getItems} 
                        setValues={setValues} 
                        setOptionsConfirm={setOptionsConfirm}
                        facetList={facetList}
                        facetOptionList={facetOptionList}
                        newFacetOptionList={newFacetOptionList}
                        filterItems={filterItems}
                        tableReset={tableReset}
                    />
                    
                    <MRT_TopToolbar table={table}/>
                </div>
            )
        },      

        renderBottomToolbar: ({ table }) => {
            const tableReset = () => {
                table.resetRowSelection()
            }
            let saveArray = []
            const saveRows = async () => {
                table.getSelectedRowModel().flatRows.map((row, ind) => {
                   saveArray.push(row.original.product_id)
                })
                //console.log(saveArray)
                let ret
                switch(actionType){
                    case 'add':
                        //need product id list, facet, facet option, and primary
                        // console.log('adding')
                        // console.log('Facet: ' , newFacet , " FacetOption: " , newFacetOption , " Primary: " , primaryFacet )
                        ret = await insertAttributeValue(saveArray, newFacet.product_facet_id, newFacetOption.product_facet_option_id )
                        break
                    case 'delete':
                        //need product list and facet option id
                        // console.log('deleting')
                        // console.log('FacetOption: ' , facetOption)
                        ret = await deleteValuesByIds(saveArray, facetOption.product_facet_option_id)
                        break
                    case 'replace':
                        // console.log('replace')
                        // console.log('old facet option: ' , facetOption , " new facet Option: " , newFacetOption)
                        ret = await removeAndApplyAttributeValue(facetOption.product_facet_option_id, newFacetOption.product_facet_option_id, saveArray)
                        break
                    default:
                        break
                }
                //console.log(ret)
                if(ret.status === 500){
                    alert('Server Error' + ret.data)
                }
                if(ret.status === 200){
                    alert('Transaction completed')
                }
                if(typeof ret === String){
                    alert(ret)
                }
                tableReset()
            }

            const handlePrimaryFacet = ( event ) => {
                setPrimaryFacet(event.target.checked)
            }

            return(
                <div style={{flex:1, paddingTop:5}}>
                    <div style={{paddingLeft: '10%'}}>
                    {actionType === 'add' ? 
                    <FormGroup sx={{paddingLeft:5}}>
                        <FormControlLabel control={<Checkbox checked={primaryFacet} onChange={handlePrimaryFacet}/>} label="Is this a Primary Facet?"/>
                    </FormGroup> : ''}
                    <Button variant='contained' sx={{marginTop:1, marginBottom:2}} onClick={saveRows} disabled={!ready || table.getSelectedRowModel().rows.length === 0}>
                        Confirm Facet Option Changes
                    </Button>
                    </div>
                    <MRT_BottomToolbar table={table} />
                </div>

            )
        }

      });

      return(
        <div>
            <Drawer open={drawerOpen} onClose={closeDataDrawer} anchor='right'>
                {drawerBody}
            </Drawer>
            <div style={{border: 'solid 1px', margin:10, borderRadius:5, boxShadow:'5px', }}>
            <MaterialReactTable table={table}/>    
            </div>
            <Grow>
                <Box>
                    <Typography>Transaction Complete</Typography>
                </Box>
            </Grow>
        </div>
    )
};