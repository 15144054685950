import axios from 'axios';

export async function getAllParentEntitiesByContactId(userId) {
  try {
    const response = await axios.get(`/entities/getAllParentEntitiesByContactId/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllSubBrandsByEntityId(entityId) {
  try {
    const response = await axios.get(`/entities/getSubBrandsByEntityId/${entityId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllEntities() {
  try {
    const response = await axios.get(`/entities/getAllEntities`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getSubBrandsByContactId(contactId) {
  try {
    const response = await axios.get(`/entities/getSubBrandsByContactId/${contactId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}
