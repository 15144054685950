import { Collections, HighlightOff } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import ImageModal from './components/ImageModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '24px',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const PublicationImageManager = ({ publicationId }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [open, setOpen] = useState(false);

  // FUNCTIONS
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton onClick={() => handleOpen()}>
        <Collections sx={{ color: theme.palette.accent.main }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ImageModal publicationId={publicationId} />
        </Box>
      </Modal>
    </>
  );
};

export default PublicationImageManager;
