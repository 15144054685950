import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const PublicationContextV2State = React.createContext({
  allPublications: [],
  setAllPublications: () => {},

  allPublicationTypes: [],
  setAllPublicationTypes: () => {},

  allPublicationProducts: [],
  setAllPublicationProducts: () => {},
});

// PRIMARY COMPONENT
const PublicationContextV2 = ({ children }) => {
  // LOCAL STATE
  const [allPublicationsState, setAllPublicationsState] = useState([]);
  const [allPublicationTypesState, setAllPublicationTypesState] = useState([]);
  const [allPublicationProductsState, setAllPublicationProductsState] = useState([]);

  // FUNCTIONS
  const setAllPublications = async () => {
    try {
      const response = await axios.get(`/publications/getAllPublications`);
      setAllPublicationsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllPublicationTypes = async () => {
    try {
      const response = await axios.get(`/publications/getAllPublicationTypes`);
      setAllPublicationTypesState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllPublicationProducts = async () => {
    try {
      const response = await axios.get(`/publications/getAllPublicationProducts`);
      setAllPublicationProductsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  useEffect(() => {
    setAllPublications();
    setAllPublicationTypes();
    setAllPublicationProducts();
  }, []);

  const contextValue = {
    allPublications: allPublicationsState,
    setAllPublications,
    allPublicationTypes: allPublicationTypesState,
    setAllPublicationTypes,
    allPublicationProducts: allPublicationProductsState,
    setAllPublicationProducts,
  };

  return (
    <PublicationContextV2State.Provider value={contextValue}>
      {children}
    </PublicationContextV2State.Provider>
  );
};
export default PublicationContextV2;
