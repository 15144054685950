import React, { useEffect, useContext, useMemo, useState } from 'react';

import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import axios from 'axios';

// MATERIAL TABLE IMPORTS
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';

import { Box, Tooltip, useTheme } from '@mui/material';
import RowEditAction from './RowEditActions';
import ContactAddress from '../../ManageContacts/components/ContactAddress';
import EntityWebsite from './EntityWebsite';
import DeleteEntityActionButton from './DeleteEntityActionButton';

import EntityMaterialTableDropDownMenu from './EntityMaterialTableDropDownMenu';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import CreateEntityButton from './CreateEntityButton';
import EntityCompanyName from './EntityCompanyName';
import TempMarketingMenu from '../../../../_GlobalComponents/TempMarketingMenu';

const EntitiesMaterialTable = () => {
  const theme = useTheme();

  const { triggerRefreshEntityData, toggleUpdateContactDrawerOpener } =
    useContext(EntityContextState);

  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [entities, setEntities] = useState([]);
  const [mounted, setMounted] = useState(false);

  // FETCH ENTITY DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getAllEntities`);
        setEntities([...response.data]); // Assuming the response has a data object
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };

    fetchData();
  }, [triggerRefreshEntityData]);

  // WAIT FOR TABLE ROWS TO BE GREATER THAN 0 BEFORE MOUNTING CONTENT
  useEffect(() => {
    entities.length > 0 && setMounted(true);
  }, [entities]);

  // COLUMN DEFINITIONS
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.entity_id,
        id: 'entity_id', // unique ID for the column
        header: '',
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        enableColumnActions: false,
        enableResizing: false,
        Cell: ({ cell }) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-around', gap: 4, alignItems: 'center' }}
          >
            <div>
              <RowEditAction id={cell.getValue()} item={cell.row.original} />
            </div>
          </Box>
        ),
        size: 220,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.entity_type}`,
        id: 'entity_type', // unique ID for the column
        header: 'Entity Type',
        Cell: ({ cell }) => {
          return <Box>{cell.getValue() ? cell.getValue() : '---'}</Box>;
        },
        size: 250,
        grow: false,
      },
      {
        accessorFn: (row) => row.business_name,
        id: 'business_name',
        header: 'Company',
        Cell: ({ cell }) => {
          return <>{cell.getValue() ? <EntityCompanyName item={cell.row.original} /> : '---'}</>;
        },
        size: 250,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.parent_entity_id} ${row.parent_company}`,
        id: 'parent_entity_id',
        header: 'Parent Company',
        Cell: ({ cell }) => {
          return (
            <Box>
              {cell.row.original.parent_entity_id ? cell.row.original.parent_company : '---'}
            </Box>
          );
        },
        size: 250,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.vendor} ${row.reference_number}`,
        id: 'vendor',
        header: 'AS-400 ID',
        Cell: ({ cell }) => {
          return (
            <Box sx={{ pl: 1, textAlign: 'center', width: '53px' }}>
              {cell.row.original.reference_number}
            </Box>
            // <Box>
            // 	{cell.row.original.reference_number
            // 		? `${cell.row.original.vendor} (${cell.row.original.reference_number})`
            // 		: '---'}
            //</Box>
          );
        },
        size: 130,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.address_1} ${row.city} ${row.state} ${row.postal_code}`,
        id: 'address',
        header: 'Address',
        Cell: ({ cell }) => {
          return (
            <ContactAddress
              street={cell.row.original.address_1}
              city={cell.row.original.city}
              state={cell.row.original.state}
              zip={cell.row.original.postal_code}
            />
          );
        },
        size: 250,
        grow: true,
      },
      // {
      // 	accessorFn: (row) => `${row.website}`,
      // 	id: 'website',
      // 	header: 'Website',
      // 	Cell: ({ cell, i }) => {
      // 		return <EntityWebsite website={cell.row.original.website} />;
      // 	},
      // 	size: 250,
      // 	grow: false,
      // },
      {
        accessorFn: (row) => row.deleted,
        id: 'deleted', // unique ID for the column
        header: 'Active',
        Cell: ({ cell, row }) => {
          return (
            <DeleteEntityActionButton
              className={`value${cell.getValue()}`}
              isChecked={parseInt(cell.getValue()) === 1 ? false : true}
              contactId={cell.row.original.entity_id}
            />
          );
        },
        size: 150,
        grow: false,
      },
      {
        accessorFn: (row) => row.has_associations,
        id: 'associated',
        header: 'Associated',
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', pl: 2 }}
            >
              {cell.getValue() > 0 ? (
                <CheckBox color='success' />
              ) : (
                <CheckBoxOutlineBlank color='disabled' />
              )}
            </Box>
          );
        },
        size: 150,
        grow: false,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: entities,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    enableGlobalFilter: true,
    initialState: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 100 },
      showGlobalFilter: true,
      columnOrder: [
        'entity_id',
        'entity_type',
        'business_name',
        'vendor',
        'parent_entity_id',
        'address',
        'website',
      ],
    },
    enableColumnResizing: true,
    enableRowSelection: false,
    state: { rowSelection: selectedRowIds },
    onRowSelectionChange: setSelectedRowIds,
    layoutMode: 'grid',
    muiTablePaperProps: { sx: { backgroundColor: 'white', padding: '18px', borderRadius: '18px' } },
    muiTableContainerProps: { sx: { maxHeight: '700px' } },
    muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },

    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'white',
        pt: 2,
        fontWeight: 'bold',
        height: 'auto',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        mb: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottomColor: theme.palette.secondary.main,
        pb: 2,
      },
    },

    muiSearchTextFieldProps: {
      placeholder: 'Search Entities',
      sx: { minWidth: '500px' },
      variant: 'outlined',
    },
    renderTopToolbar: () => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '15px',
        }}
      >
        {/* <Box sx={{ gridColumn: 'span 3', textAlign: 'right' }}>
					<CreateEntityButton />
				</Box> */}

        <Box sx={{ gridColumn: 'span 2' }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gridColumn: 'span 1' }}>
          <CreateEntityButton />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
          <TempMarketingMenu />
          {/* <CreateEntityButton /> */}
          {/* <EntityMaterialTableDropDownMenu /> */}
        </Box>
      </Box>
    ),
  });

  return (
    <>
      {mounted && (
        <Box
          className='ManageContactsWrapper'
          sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}
        >
          <MaterialReactTable table={table} />
          {/* <UpdateContactDrawer /> */}
        </Box>
      )}
    </>
  );
};

export default EntitiesMaterialTable;
