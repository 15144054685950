import React from 'react';
import { UploadFile } from '../../../../../../../_GlobalFunctions/UploadFile';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

const approvedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

const bgUrl =
  'https://images.unsplash.com/photo-1730292422804-5bbb2bd2d3f0?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

const ImageModal = ({ publicationId }) => {
  // INIT's
  const theme = useTheme();

  // FUNCTIONS
  const handleFilesChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file

    if (file && approvedTypes.includes(file.type)) {
      console.log('Selected File Details:', {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: new Date(file.lastModified).toLocaleString(),
      });

      const res = await UploadFile(
        file,
        null,
        publicationId,
        file.name,
        'PUBLICATION_LANDSCAPE',
        'PUBLICATION_IMAGES'
      );
    }
  };

  return (
    <Box>
      <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2 }}>
        Publication: <span style={{ color: theme.palette.accent.main }}>{publicationId}</span>
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Box
          sx={{
            aspectRatio: '21/9',
            width: '300px',
            background: `url(${bgUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        ></Box>
        <Typography sx={{ flex: 1 }}> - LANDSCAPE</Typography>
        <IconButton>
          <HighlightOff sx={{ color: theme.palette.delete.main }} />
        </IconButton>
      </Box>
      <Box>
        <Button variant='outlined' component='label' fullWidth>
          Select File
          <input type='file' hidden multiple onChange={(e) => handleFilesChange(e)} />
        </Button>
      </Box>
    </Box>
  );
};

export default ImageModal;
