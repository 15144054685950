import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdBuilder from '../Components/AdBuilder/AdBuilder';
import EmailEditor from '../Components/AdBuilder/components/EmailEditor/EmailEditor';

const AdBuilderRoutes = () => {
  return (
    <>
      <Routes>
        {/* ENTITY ROUTES */}

        <Route path='/email/email-editor' element={<EmailEditor />} />
      </Routes>
    </>
  );
};

export default AdBuilderRoutes;
