import { Box, MenuItem, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleMenuButtonDropdown from './MarketingStyledComponents/ButtonDropdowns/SimpleMenu/SimpleMenu.ButtonDropdown';

const menu = [
  { label: 'Contracts', path: '/advertising/contracts' },

  { label: 'Entities', path: '/marketing/entity/manage-entities/all-entities' },
  { label: 'Marketing Funds', path: '/advertising/deposits' },
  { label: 'Media Kit', path: '/advertising/media-kit' },
  { label: 'Publications Revised', path: '/marketing/publications/v2' },
  { label: 'Publications Legacy', path: '/marketing/publications' },

  { label: 'Product Performance Dashboard', path: '/advertising/product/performance-dashboard' },
  {
    label: 'Publication Products Maintenance',
    path: '/advertising/publications/publication-products',
  },
];

const TempMarketingMenu = () => {
  // INIT's
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'right', padding: '16px' }}>
      <SimpleMenuButtonDropdown color={`${theme.palette.accent.main}`} icon='add_circle_outline'>
        {menu.map((e, i) => (
          <MenuItem key={i} onClick={() => navigate(e.path)}>
            {e.label}
          </MenuItem>
        ))}
      </SimpleMenuButtonDropdown>
    </Box>
  );
};

export default TempMarketingMenu;
