import { AddCircleOutline } from '@mui/icons-material';
import { Box, Chip, IconButton, MenuItem, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  deleteContractBrands,
  insertContractBrands,
} from '../../../../../_GlobalFunctions/contractFunctions';
import { getAllSubBrandsByEntityId } from '../../../../../_GlobalFunctions/entityFunctions';
import SimpleMenuButtonDropdown from '../../../../../_GlobalComponents/MarketingStyledComponents/ButtonDropdowns/SimpleMenu/SimpleMenu.ButtonDropdown';

const ContractVendor = ({ item, vendor, brands, submit_contract_date }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState([]);
  const [entitiesBrands, setEntitiesBrands] = useState([]);
  const [availableSubBrandsState, setAvailableSubBrandsState] = useState([]);
  const [appliedBrands, setAppliedBrands] = useState([]);

  // FUNCTIONS
  const handleDelete = (e) => {
    setDeleted((prev) => [...prev, e.ss_publication_contract_brand_id]);
    deleteContractBrands({
      ss_publication_contract_id: item.ss_publication_contract_id,
      entity_id: e.entity_id,
    });
  };

  const handleMenuItemClick = async (entityId) => {
    try {
      const res = entitiesBrands.find((e) => e.entity_id === entityId);
      if (!res) return alert('Brand not found!');

      const insertBrand = await insertContractBrands([
        { entity_id: entityId, ss_publication_contract_id: item.ss_publication_contract_id },
      ]);

      if (insertBrand.insertId > 0) {
        setAppliedBrands((prev) => [
          ...prev,
          { entity_id: res.entity_id, brand: res.business_name },
        ]);
      } else {
        alert('Something went wrong!');
      }
    } catch (err) {
      console.error('Error in handleMenuItemClick:', err);
    }
  };

  const fetch = async () => {
    try {
      const res = await getAllSubBrandsByEntityId(item.entity_id);
      setEntitiesBrands(res);
      setAppliedBrands(item.brands);

      const availableSubBrands = res.filter(
        (brand) => !item.brands.some((appliedBrand) => appliedBrand.entity_id === brand.entity_id)
      );

      setAvailableSubBrandsState(availableSubBrands);
      setLoading(false);
    } catch (err) {
      console.error('Fetch Error:', err);
    }
  };

  const displayableBrands = appliedBrands.filter(
    (brand) => !deleted.includes(brand.ss_publication_contract_brand_id)
  );

  useEffect(() => {
    fetch();
  }, [item.entity_id, item.brands]);

  if (loading) {
    return (
      <Box>
        <Skeleton />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
          marginBottom: '16px',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{item.vendor}</Typography>
        {dayjs() < dayjs(item.ss_publication.submit_contract_date) && (
          <>
            {entitiesBrands.length > 1 && entitiesBrands.length !== appliedBrands.length && (
              <SimpleMenuButtonDropdown
                color={`${theme.palette.save.main}`}
                icon='add_circle_outline'
                onClick={handleMenuItemClick}
              >
                {availableSubBrandsState.length > 0 &&
                  availableSubBrandsState.map((e, i) => (
                    <MenuItem
                      key={i}
                      value={e.entity_id}
                      onClick={() => handleMenuItemClick(e.entity_id)}
                    >
                      {e.business_name} ({e.entity_id})
                    </MenuItem>
                  ))}
              </SimpleMenuButtonDropdown>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '24px',
          justifyContent: 'center',
        }}
      >
        {displayableBrands.map((e, i) => (
          <Chip
            key={i}
            label={e.brand}
            onDelete={() => handleDelete(e)}
            sx={{
              fontWeight: 'bold',
              fontSize: '.8em',
              color: theme.palette.primary.main,
              backgroundColor: `${theme.palette.primary.main}22`,
              '& .MuiChip-deleteIcon': {
                display:
                  dayjs() < dayjs(item.ss_publication.submit_contract_date)
                    ? 'inline-block'
                    : 'none',
                color: theme.palette.delete.main,
              },
            }}
          />
        ))}

        {/* {
          // item.brands.length === 1 && item.brands[0].entity_id === item.entity_id
          //   ? ''
          //   :
          //item.brands
          appliedBrands
            .filter((brand) => !deleted.includes(brand.ss_publication_contract_brand_id))
            .map((e, i) => {
              return (
                <>
                  {e.entity_id > 0 && (
                    <Chip
                      key={i}
                      label={e.brand}
                      onDelete={() => handleDelete(e)}
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '.8em',
                        color: theme.palette.primary.main,
                        backgroundColor: `${theme.palette.primary.main}22`,
                        '& .MuiChip-deleteIcon': {
                          display: dayjs() < dayjs(submit_contract_date) ? 'inline-block' : 'none',
                          color: theme.palette.delete.main,
                        },
                      }}
                    />
                  )}
                </>
              );
            })
        } */}
      </Box>
    </>
  );
};

export default ContractVendor;
