import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MarketingContextState } from '../../Context/Marketing/MarketingContext';
import { Box, useTheme } from '@mui/material';
import SimpleMenuButtonDropdown from '../_GlobalComponents/MarketingStyledComponents/ButtonDropdowns/SimpleMenu/SimpleMenu.ButtonDropdown';
import TempMarketingMenu from '../_GlobalComponents/TempMarketingMenu';

const AdvertisingLayout = ({ children }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  //const userId = useSelector((state) => state.userInformation.value.userID);
  const { userId } = useContext(MarketingContextState);

  return (
    <>
      <Box>
        <TempMarketingMenu />
      </Box>
      {children}
    </>
  );
};

export default AdvertisingLayout;
