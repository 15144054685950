import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Row from './Rows/Row';
// import { getAllEntities } from '../../../_GlobalFunctions/entityFunctions';
import { useContractContext } from '../../../../Context/Contracts/ContractsDashboardContext';
// import {
//   getAllPublicationTypes,
//   getAllPublicationProducts,
// } from '../../../_GlobalFunctions/publicationFunctions';
// import { getAllPaymentStatuses } from '../../../_GlobalFunctions/contractFunctions';
import { Visibility } from '@mui/icons-material';
import useSnackbar from '../../../../Hooks/useSnackbar';

const headerData = [
  { id: 0, label: 'All Contracts' },
  { id: 1, label: 'Overdue' },
  { id: 2, label: 'Due Soon' },
  { id: 3, label: 'Total Outstanding' },
];

const AllInvoices = ({ contracts, publications }) => {
  const theme = useTheme();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  // CONTEXT STATE
  const { billingStatusId, billingStatusItemsIds, setBillingStatusId } = useContractContext();

  // LOCAL STATE
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [data, setData] = useState([]);
  const [filterType, setFilterType] = useState(0);
  const [filterValue, setFilterValue] = useState(0);

  const fetch = async (filterTypeProp = 0, filterValueProp = 0) => {};

  // FUNCTIONS
  // const fetch = async (filterTypeProp = 0, filterValueProp = 0) => {
  //   console.log('filterValueProp: ', filterValueProp);
  //   try {
  //     let tempArray = [];
  //     const res = await getAllEntities();
  //     const pub_types = await getAllPublicationTypes();
  //     const payment_statuses = await getAllPaymentStatuses();
  //     const publicaiton_products = await getAllPublicationProducts();

  //     let filteredContracts = [];

  //     if (filterTypeProp === 1) {
  //       const res = contracts.filter((e) => e.entity_id === filterValueProp);
  //       filteredContracts = [...res];
  //     } else if (filterTypeProp === 2) {
  //       const res = contracts.filter((e) => e.brands.includes(filterValueProp));
  //       filteredContracts = [...res];
  //     } else {
  //       filteredContracts = [...contracts];
  //     }
  //     console.log('filteredContracts: ', filteredContracts);

  //     filteredContracts.map((e) =>
  //       tempArray.push({
  //         ...e,
  //         entity: res.find((ent) => ent.entity_id === e.entity_id).business_name || 'unknown',
  //         brand_details: e.brands.map((b) => ({
  //           brand_id: b,
  //           brand: res.find((ent) => ent.entity_id === b)?.business_name || 'Unknown',
  //         })),
  //         publication_details: {
  //           ...publications.find((p) => p.ss_publication_id === e.ss_publication_id),
  //           publication_type: pub_types.find(
  //             (pt) =>
  //               pt.ss_publication_type_id ===
  //               parseInt(
  //                 publications.find((p) => p.ss_publication_id === e.ss_publication_id)
  //                   .ss_publication_type_id
  //               )
  //           ).description,
  //         },
  //         payment_status: payment_statuses.find(
  //           (p) => p.ss_publication_payment_status_id === e.ss_publication_payment_status_id
  //         ).description,
  //         publicaiton_product: publicaiton_products.find(
  //           (p) => p.ss_publication_product_id === e.ss_publication_product_id
  //         ).description,
  //       })
  //     );
  //     return tempArray;
  //     // setData(tempArray);
  //     // setIsLoading(false);
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  const handleViewAll = () => {
    const fetchData = async () => {
      setFilterType(1);
      setFilterValue(0);
      setBillingStatusId(0);
      try {
        const res = await contracts.filter((e) => e.contract_deleted !== 1);
        setData(res);
      } catch (err) {
        console.log('err: ', err);
      }
    };
    fetchData();
  };

  const handleVendorFilter = (entityId) => {
    const fetchData = async () => {
      setFilterType(1);
      setFilterValue(entityId);
      try {
        //const res = await fetch(1, entityId);
        const res = await contracts.filter(
          (e) => e.entity_id === entityId && e.contract_deleted !== 1
        );
        setData(res);
      } catch (err) {
        console.log('err: ', err);
      }
    };
    fetchData();
  };

  const handleBrandFilter = (brandId) => {
    const fetchData = async () => {
      setFilterType(1);
      setFilterValue(brandId);
      try {
        //const res = await fetch(2, brandId);
        let res = await contracts.map(
          (e) =>
            e.brands.filter((e) => e.entity_id === brandId).length > 0 &&
            e.contract_deleted !== 1 &&
            e
        );

        setData(res.filter((item) => item !== false));
      } catch (err) {
        console.log('err: ', err);
      }
    };
    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const res = await fetch();
        const res = contracts.filter((e) => e.contract_deleted !== 1);
        billingStatusId === 0
          ? setData(res)
          : setData(
              res.filter((e) =>
                billingStatusItemsIds
                  .find((ee) => ee.id === billingStatusId)
                  .items[0].includes(e.ss_publication_contract_id)
              )
            );
      } catch (err) {
        console.log('err: ', err);
      }
    };
    fetchData();
    //setData(res);
    setIsLoading(false);
  }, [billingStatusId]);

  if (isLoading) {
    return null;
  }

  return (
    <Box sx={{ paddingTop: '48px' }}>
      <Box sx={{ maxWidth: '1600px', marginX: 'auto' }}>
        <Typography variant='h5' sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
          {headerData[billingStatusId].label}
          <Button onClick={() => showSnackbar('This is a success message!', 'error')}>
            Click Me
          </Button>
          <SnackbarComponent />
        </Typography>
        <Box sx={{ paddingBottom: '100px' }}>
          {/* <Box sx={{ backgroundColor: '#ffebbc', padding: '16px' }}>Header:</Box> */}
          <Box
            sx={{
              background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
              padding: '16px',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1.4em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Contracts:</Typography>

            <Box>
              <Button color='white' variant='outlined' onClick={() => handleVendorFilter(767)}>
                Allen Co
              </Button>
              <Button color='white' variant='outlined' onClick={() => handleBrandFilter(1218)}>
                TacSix
              </Button>
            </Box>
            <Box sx={{ display: billingStatusId !== 0 ? 'block' : 'none' }}>
              <Button
                variant='outlined'
                endIcon={<Visibility />}
                sx={{
                  borderColor: 'white',
                  color: 'white',

                  flexDirection: 'row',
                }}
                onClick={() => handleViewAll()}
              >
                View All
              </Button>
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '600px', overflowY: 'auto' }}
          >
            {data.map((d, idx) => (
              <Row key={idx} idx={idx} item={d} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllInvoices;
