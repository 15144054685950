import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDepositsPayments } from '../../../../../_GlobalFunctions/accountingFunctions';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalPayments = ({ depositId, total_spent }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(false);
  const [spentOpened, setSpentOpened] = useState(false);
  const [data, setData] = useState([]);

  // FUNCTIONS
  const fetch = async () => {
    const res = await getDepositsPayments(depositId);
    setData(res);
    console.log('res deposit: ', res);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);
  // FUNCTIONS
  const handleSpentOpen = () => {
    setSpentOpened(true);
  };

  const handleSpentClose = () => {
    setSpentOpened(false);
  };

  if (loading) {
    <div></div>;
  }
  return (
    <>
      <Button
        variant='contained'
        disabled={total_spent === null}
        sx={{ marginX: 'auto' }}
        onClick={() => handleSpentOpen()}
      >
        ${total_spent === null ? parseFloat(0).toFixed(2) : total_spent}
      </Button>
      <Modal
        open={spentOpened}
        onClose={() => handleSpentClose()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <h3>Payments:</h3>
          {data.map((e, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box sx={{ width: '105px' }}>{dayjs(e.payment_time).format('MMM DD, YYYY')}</Box>
              <Box sx={{ width: '80px' }}>{`$${parseFloat(e.payment_amount).toFixed(2)}`}</Box>
              <Box sx={{ width: '120px' }}>{`${e.user.first_name} ${e.user.last_name}`}</Box>
              <Box sx={{ flex: 1, whiteSpace: 'wrap' }}>{e.description}</Box>
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default ModalPayments;
