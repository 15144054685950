import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import Actions from './Actions/Actions';
import TitleDetails from './Actions/components/TitleDetails';

const useColumns = () => {
  return useMemo(
    () => [
      {
        accessorFn: (row) => row.ss_publication_id,
        id: 'ss_publication_id',
        header: 'Actions',
        Cell: ({ cell }) => (
          <Actions
            publicaitonId={cell.row.original.ss_publication_id}
            publicationData={cell.row.original}
          />
        ),
        size: 270,
        grow: false,
      },
      {
        accessorFn: (row) => `${row.description} ${row.detailed_description}`,
        id: 'title_details',
        header: 'Details',
        Cell: ({ cell }) => (
          <TitleDetails details={cell.row.original} />
          // <Box
          // 	sx={{
          // 		display: 'flex',
          // 		flexDirection: 'column',
          // 		justifyContent: 'flex-start',
          // 		padding: '16px',
          // 		alignItems: 'flex-start',
          // 		width: '100%',
          // 	}}
          // >
          // 	<Box>{cell.row.original.description}</Box>
          // 	<Box>{cell.row.original.detailed_description}</Box>
          // </Box>
        ),
        size: 400,
        grow: true,
      },
      {
        accessorKey: 'submit_contract_date', // accessor is the "key" in the data
        id: 'submit_contract_date',
        header: 'Contract Due',
        size: 170,
        grow: false,
      },
      {
        accessorKey: 'submit_art_date', // accessor is the "key" in the data
        id: 'submit_art_date',
        header: 'Art Due',
        size: 150,
        grow: false,
      },
      {
        accessorKey: 'production_start_date', // accessor is the "key" in the data
        id: 'production_start_date',
        header: 'Production',
        size: 150,
        grow: false,
      },
      {
        accessorKey: 'start_date', // accessor is the "key" in the data
        id: 'start_date',
        header: 'Start Date',
        size: 150,
        grow: false,
      },
      {
        accessorKey: 'expiration_date', // accessor is the "key" in the data
        id: 'expiration_date',
        header: 'Expiration Date',
        size: 166,
        grow: false,
      },
    ],
    []
  );
};

export default useColumns;
