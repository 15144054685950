import axios from 'axios';

// Utils Function
export const utilCheckUserAttributeSp = async (userID, tag) => {
  // const persistRoot = localStorage.getItem('persist:root');
  // const parsedPersistRoot = JSON.parse(persistRoot);
  // const userInformation = JSON.parse(parsedPersistRoot.userInformation);
  // const user_id = userInformation.value.userID;
  const res = await axios.get(`/util/checkUserAttributeSp/${userID}/${tag}`);
  return res;
};

export const utilGetUserId = async () => {
  const persistRoot = localStorage.getItem('persist:root');
  const parsedPersistRoot = JSON.parse(persistRoot);
  const userInformation = JSON.parse(parsedPersistRoot.userInformation);
  const user_id = userInformation.value.userID;
  return user_id;
};
