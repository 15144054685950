//http://localhost:3000/sandbox/cameron/function-test

import React from 'react';
import ManufacturerDashboard from './ManufacturerDashboard';
import { ManufacturerProvider } from './PerformanceDashboardContext';

const PerformanceDashboard = () => {
  //todo

  //add dynamic month comparison in yoy banner => need to make the change in context
  //fix (New) showing up in divergent chart for items that had 0 sales -- probably just change the name new to something else'
  //change banner card to also filter by model
  //fix banner card to display "No data for previous year"
  //fix middle banner card to display model when it's being filtered by model
  //fix formatting for mobile
  //maybe add selecting an item on the right plots it on one of the charts to the left, modal?

  return (
    <ManufacturerProvider>
      <ManufacturerDashboard />
    </ManufacturerProvider>
  );
};

export default PerformanceDashboard;
