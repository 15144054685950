export const pub_data = [
  {
    publication_code: 'RELOAD25_BLOG',
    publication_type_id: '9',
    description: '2025 Reloading Blog',
    detailed_description:
      '2025 Reloading Mini Mailer Essential Supplies: Stock up on must-haves like brass, bullets, and powders.\nExpert Tips and Tools: Enhance your customers’ reloading experience with high-quality tools and accessories.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/12/25',
    production_start_date: '2/12/25',
    start_date: '2/24/25',
    expiration_date: '3/24/25',
  },
  {
    publication_code: 'RANGE25_BLOG',
    publication_type_id: '9',
    description: '2025 Summer Range Blog',
    detailed_description:
      'Top-Tier Firearms and Optics: Showcase the latest handguns, rifles, and cutting-edge optics perfect for summer shooting.\nAmmunition and Targets: High-performance ammo and innovative target solutions to enhance practice sessions.\nProtective Gear and Accessories: Ear and eye protection, range bags, and other must-have accessories for a safe and efficient experience.""',
    submit_contract_date: '3/10/25',
    submit_art_date: '3/17/25',
    production_start_date: '3/17/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'GAME25_BLOG',
    publication_type_id: '9',
    description: '2025 Big Game Blog',
    detailed_description:
      'Rifles and Optics: Highlighting powerful rifles and precision scopes designed for long-range and rugged terrain.\nEssential Gear: High-quality packs, camo apparel, game calls, and other critical accessories for big game success.\nAmmunition and Scents: Superior ammunition and scent-control products to give hunters the edge they need.\nTop Brands and Innovations: Showcasing trusted brands and the latest advancements in hunting technology.',
    submit_contract_date: '5/26/25',
    submit_art_date: '6/2/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'WATERFOWL25_BLOG',
    publication_type_id: '9',
    description: '2025 Waterfowl Blog',
    detailed_description:
      'Decoys and Calls: Highlighting the most realistic decoys and effective calls to lure in ducks and geese.\nShotguns and Ammunition: Showcasing reliable shotguns and high-performance waterfowl-specific loads for optimal success.\nBlinds and Accessories: Portable and durable blinds, waders, and other essential gear for harsh environments.\nTop Brands and Innovations: Featuring trusted names and the latest advancements in waterfowl hunting technology.',
    submit_contract_date: '8/4/25',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'HANDGUN25_BLOG',
    publication_type_id: '9',
    description: '2025 Handgun and EDC Blog',
    detailed_description:
      'Top Handgun Models: Compact and full-size options from trusted brands, offering reliability and performance.\nHolsters and Carry Solutions: Versatile holsters and concealed carry systems tailored for comfort and quick access.\nAmmunition and Accessories: Premium self-defense rounds, cleaning kits, and spare magazines for every need.\nEveryday Essentials: Flashlights, knives, and multitools to complete a well-rounded carry kit.',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'HOG25_BLOG',
    publication_type_id: '9',
    description: '2025 Hog Blog',
    detailed_description:
      'Rifles and Optics: Durable, high-performance rifles and precision optics tailored for day and night hog hunting.\nThermal and Night Vision Gear: Cutting-edge technology to help hunters spot hogs in any light condition.\nCalls and Attractants: Tools to lure hogs into range and ensure a successful hunt.\nEssential Accessories: Heavy-duty packs, hunting lights, and game retrieval equipment for the toughest terrain.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/10/25',
    production_start_date: '2/10/25',
    start_date: '3/17/25',
    expiration_date: '4/17/25',
  },
  {
    publication_code: 'DOVE25_BLOG',
    publication_type_id: '9',
    description: '2025 Dove and Upland Blog',
    detailed_description:
      'Shotguns and Ammunition: Highlighting reliable shotguns and specialized upland and dove loads for superior performance.\nDecoys and Calls: Effective tools for attracting birds and enhancing every hunt.\nApparel and Accessories: Lightweight, durable vests, boots, and gloves built for long days in the field.\nDog Gear and Training Aids: Essentials for hunting companions, including collars, kennels, and training tools.',
    submit_contract_date: '6/23/25',
    submit_art_date: '6/30/25',
    production_start_date: '6/30/25',
    start_date: '8/4/25',
    expiration_date: '9/4/25',
  },
  {
    publication_code: 'PREDATOR25_BLOG',
    publication_type_id: '9',
    description: '2025 Predator and Varmint Blog',
    detailed_description:
      'Rifles and Optics: High-powered rifles and precision scopes designed for long-range accuracy and versatility.\nElectronic Calls and Decoys: Advanced calling systems and realistic decoys to lure predators into range.\nThermal and Night Vision Gear: Cutting-edge technology for effective hunting in low-light and nighttime conditions.\nAmmunition and Accessories: Premium varmint loads and essential gear like shooting sticks and camo.',
    submit_contract_date: '7/14/25',
    submit_art_date: '7/21/25',
    production_start_date: '7/21/25',
    start_date: '8/25/25',
    expiration_date: '9/25/25',
  },
  {
    publication_code: 'SHOT25_BLOG',
    publication_type_id: '9',
    description: '2025 SHOT Show Specials Blog',
    detailed_description:
      'New Product Spotlights: Showcasing the latest innovations and must-have products across firearms, optics, accessories, and more.',
    submit_contract_date: '1/27/25',
    submit_art_date: '2/3/25',
    production_start_date: '2/3/25',
    start_date: '2/10/25',
    expiration_date: '2/16/25',
  },
  {
    publication_code: 'WOMEN25_BLOG',
    publication_type_id: '9',
    description: '2025 Women in the Industry BLOG',
    detailed_description:
      'Innovative Gear for Women: Firearms, holsters, and accessories designed with women in mind—combining function, fit, and style.\nSpotlight on Female Leaders: Inspiring stories of women making an impact in the firearm industry and breaking barriers.\nProduct Features: Compact handguns, lightweight hunting gear, customizable options, and more to meet the preferences and needs of women shooters.\nTraining and Education Resources: Information on self-defense classes, shooting sports opportunities, and gear tips for women new to firearms.',
    submit_contract_date: '5/12/25',
    submit_art_date: '5/19/25',
    production_start_date: '5/19/25',
    start_date: '5/26/25',
    expiration_date: '6/1/25',
  },
  {
    publication_code: 'SAFETY25_BLOG',
    publication_type_id: '9',
    description: '2025 Gun Safety and Storage BLOG',
    detailed_description:
      'Secure Storage Solutions: Safes, lockboxes, and trigger locks designed to prevent unauthorized access.\nChild Safety Products: Innovative devices and educational tools to help families create safe environments.\nRange Safety Gear: Ear and eye protection, chamber flags, and other essentials for safe practice and training.',
    submit_contract_date: '3/31/25',
    submit_art_date: '4/7/25',
    production_start_date: '4/7/25',
    start_date: '4/14/25',
    expiration_date: '4/20/25',
  },
  {
    publication_code: 'HOLSTER25_BLOG',
    publication_type_id: '9',
    description: '2025 Holstering a Gun Blog',
    detailed_description:
      'Holsters for Every Carry Style: Inside-the-waistband (IWB), outside-the-waistband (OWB), ankle, shoulder, and appendix carry solutions from trusted brands.\nCustomizable and Modular Options: Holsters with adjustable retention, modular designs, and compatibility with various firearms.\nConcealed Carry Essentials: Accessories like gun belts, mag carriers, and clothing designed for discreet and secure carry.\nTactical and Duty Gear: Holsters built for law enforcement, military, and competition use, offering durability and quick access.',
    submit_contract_date: '11/3/25',
    submit_art_date: '11/10/25',
    production_start_date: '11/10/25',
    start_date: '11/17/25',
    expiration_date: '11/23/25',
  },
  {
    publication_code: 'ARCHERY25_BLOG',
    publication_type_id: '9',
    description: '2025 Archery Blog',
    detailed_description:
      'Bows for Every Shooter: From high-performance compound and recurve bows to beginner-friendly models, this publication showcases options for all skill levels.\nArrows and Broadheads: Highlighting durable, high-accuracy arrows and premium broadheads tailored for hunting and target shooting.\nAccessories and Upgrades: Releases, quivers, sights, stabilizers, and other tools to enhance precision and performance.\nHunting Essentials: Tree stands, bow cases, and camo gear designed specifically for archery hunters.',
    submit_contract_date: '7/21/25',
    submit_art_date: '7/28/25',
    production_start_date: '7/28/25',
    start_date: '8/4/25',
    expiration_date: '8/10/25',
  },
  {
    publication_code: 'HOLIDAY25_BLOG',
    publication_type_id: '9',
    description: '2025 Holiday Gift Guide Blog',
    detailed_description:
      'Guns for Every Enthusiast: Compact handguns, hunting rifles, and versatile shotguns to make the holidays unforgettable.\nUnique and Practical Gifts: Knives, multitools, optics, and other essential gear that blend functionality with style.\nNovelty Products: Fun and quirky items for collectors and enthusiasts, perfect for stocking stuffers or standout gifts.\nTop Brands and Must-Haves: Highlighting premium products and trusted names to ensure quality and satisfaction.\nGifts for Every Budget: A range of price points, from affordable accessories to high-end gear, catering to every shopper.',
    submit_contract_date: '9/1/25',
    submit_art_date: '9/8/25',
    production_start_date: '9/8/25',
    start_date: '10/13/25',
    expiration_date: '11/14/25',
  },
  {
    publication_code: 'RELOAD25_EM',
    publication_type_id: '5',
    description: '2025 Reloading Email',
    detailed_description:
      '2025 Reloading Mini Mailer Essential Supplies: Stock up on must-haves like brass, bullets, and powders.\nExpert Tips and Tools: Enhance your customers’ reloading experience with high-quality tools and accessories.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/12/25',
    production_start_date: '2/12/25',
    start_date: '2/24/25',
    expiration_date: '3/24/25',
  },
  {
    publication_code: 'RANGE25_EM',
    publication_type_id: '5',
    description: '2025 Summer Range Email',
    detailed_description:
      'Top-Tier Firearms and Optics: Showcase the latest handguns, rifles, and cutting-edge optics perfect for summer shooting.\nAmmunition and Targets: High-performance ammo and innovative target solutions to enhance practice sessions.\nProtective Gear and Accessories: Ear and eye protection, range bags, and other must-have accessories for a safe and efficient experience.',
    submit_contract_date: '3/10/25',
    submit_art_date: '3/17/25',
    production_start_date: '3/17/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'GAME25_EM',
    publication_type_id: '5',
    description: '2025 Big Game Email',
    detailed_description:
      'Rifles and Optics: Highlighting powerful rifles and precision scopes designed for long-range and rugged terrain.\nEssential Gear: High-quality packs, camo apparel, game calls, and other critical accessories for big game success.\nAmmunition and Scents: Superior ammunition and scent-control products to give hunters the edge they need.\nTop Brands and Innovations: Showcasing trusted brands and the latest advancements in hunting technology.',
    submit_contract_date: '5/26/25',
    submit_art_date: '6/2/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'WATERFOWL25_EM',
    publication_type_id: '5',
    description: '2025 Waterfowl Email',
    detailed_description:
      'Decoys and Calls: Highlighting the most realistic decoys and effective calls to lure in ducks and geese.\nShotguns and Ammunition: Showcasing reliable shotguns and high-performance waterfowl-specific loads for optimal success.\nBlinds and Accessories: Portable and durable blinds, waders, and other essential gear for harsh environments.\nTop Brands and Innovations: Featuring trusted names and the latest advancements in waterfowl hunting technology.',
    submit_contract_date: '8/4/11',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'HANDGUN25_EM',
    publication_type_id: '5',
    description: '2025 Handgun and EDC Email',
    detailed_description:
      'Top Handgun Models: Compact and full-size options from trusted brands, offering reliability and performance.\nHolsters and Carry Solutions: Versatile holsters and concealed carry systems tailored for comfort and quick access.\nAmmunition and Accessories: Premium self-defense rounds, cleaning kits, and spare magazines for every need.\nEveryday Essentials: Flashlights, knives, and multitools to complete a well-rounded carry kit.',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'HOG25_EM',
    publication_type_id: '5',
    description: '2025 Hog Email',
    detailed_description:
      'Rifles and Optics: Durable, high-performance rifles and precision optics tailored for day and night hog hunting.\nThermal and Night Vision Gear: Cutting-edge technology to help hunters spot hogs in any light condition.\nCalls and Attractants: Tools to lure hogs into range and ensure a successful hunt.\nEssential Accessories: Heavy-duty packs, hunting lights, and game retrieval equipment for the toughest terrain.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/10/25',
    production_start_date: '2/10/25',
    start_date: '3/17/25',
    expiration_date: '4/17/25',
  },
  {
    publication_code: 'DOVE25_EM',
    publication_type_id: '5',
    description: '2025 Dove and Upland Email',
    detailed_description:
      'Shotguns and Ammunition: Highlighting reliable shotguns and specialized upland and dove loads for superior performance.\nDecoys and Calls: Effective tools for attracting birds and enhancing every hunt.\nApparel and Accessories: Lightweight, durable vests, boots, and gloves built for long days in the field.\nDog Gear and Training Aids: Essentials for hunting companions, including collars, kennels, and training tools.',
    submit_contract_date: '6/23/25',
    submit_art_date: '6/30/25',
    production_start_date: '6/30/25',
    start_date: '8/4/25',
    expiration_date: '9/4/25',
  },
  {
    publication_code: 'PREDATOR25_EM',
    publication_type_id: '5',
    description: '2025 Predator and Varmint Email',
    detailed_description:
      'Rifles and Optics: High-powered rifles and precision scopes designed for long-range accuracy and versatility.\nElectronic Calls and Decoys: Advanced calling systems and realistic decoys to lure predators into range.\nThermal and Night Vision Gear: Cutting-edge technology for effective hunting in low-light and nighttime conditions.\nAmmunition and Accessories: Premium varmint loads and essential gear like shooting sticks and camo.',
    submit_contract_date: '7/14/25',
    submit_art_date: '7/21/25',
    production_start_date: '7/21/25',
    start_date: '8/25/25',
    expiration_date: '9/25/25',
  },
  {
    publication_code: 'SHOT25_EM',
    publication_type_id: '5',
    description: '2025 SHOT Show Specials Email',
    detailed_description:
      'New Product Spotlights: Showcasing the latest innovations and must-have products across firearms, optics, accessories, and more.',
    submit_contract_date: '1/27/25',
    submit_art_date: '2/3/25',
    production_start_date: '2/3/25',
    start_date: '2/10/25',
    expiration_date: '2/16/25',
  },
  {
    publication_code: 'WOMEN25_EM',
    publication_type_id: '5',
    description: '2025 Women in the Industry Emal',
    detailed_description:
      'Innovative Gear for Women: Firearms, holsters, and accessories designed with women in mind—combining function, fit, and style.\nSpotlight on Female Leaders: Inspiring stories of women making an impact in the firearm industry and breaking barriers.\nProduct Features: Compact handguns, lightweight hunting gear, customizable options, and more to meet the preferences and needs of women shooters.\nTraining and Education Resources: Information on self-defense classes, shooting sports opportunities, and gear tips for women new to firearms.',
    submit_contract_date: '5/12/25',
    submit_art_date: '5/19/25',
    production_start_date: '5/19/25',
    start_date: '5/26/25',
    expiration_date: '6/1/25',
  },
  {
    publication_code: 'SAFETY25_EM',
    publication_type_id: '5',
    description: '2025 Gun Safety and Storage Email',
    detailed_description:
      'Secure Storage Solutions: Safes, lockboxes, and trigger locks designed to prevent unauthorized access.\nChild Safety Products: Innovative devices and educational tools to help families create safe environments.\nRange Safety Gear: Ear and eye protection, chamber flags, and other essentials for safe practice and training.',
    submit_contract_date: '3/31/25',
    submit_art_date: '4/7/25',
    production_start_date: '4/7/25',
    start_date: '4/14/25',
    expiration_date: '4/20/25',
  },
  {
    publication_code: 'HOLSTER25_EM',
    publication_type_id: '5',
    description: '2025 Holstering a Gun Email',
    detailed_description:
      'Holsters for Every Carry Style: Inside-the-waistband (IWB), outside-the-waistband (OWB), ankle, shoulder, and appendix carry solutions from trusted brands.\nCustomizable and Modular Options: Holsters with adjustable retention, modular designs, and compatibility with various firearms.\nConcealed Carry Essentials: Accessories like gun belts, mag carriers, and clothing designed for discreet and secure carry.\nTactical and Duty Gear: Holsters built for law enforcement, military, and competition use, offering durability and quick access.',
    submit_contract_date: '11/3/25',
    submit_art_date: '11/10/25',
    production_start_date: '11/10/25',
    start_date: '11/17/25',
    expiration_date: '11/23/25',
  },
  {
    publication_code: 'ARCHERY25_EM',
    publication_type_id: '5',
    description: '2025 Archery Email',
    detailed_description:
      'Bows for Every Shooter: From high-performance compound and recurve bows to beginner-friendly models, this publication showcases options for all skill levels.\nArrows and Broadheads: Highlighting durable, high-accuracy arrows and premium broadheads tailored for hunting and target shooting.\nAccessories and Upgrades: Releases, quivers, sights, stabilizers, and other tools to enhance precision and performance.\nHunting Essentials: Tree stands, bow cases, and camo gear designed specifically for archery hunters.',
    submit_contract_date: '7/21/25',
    submit_art_date: '7/28/25',
    production_start_date: '7/28/25',
    start_date: '8/4/25',
    expiration_date: '8/10/25',
  },
  {
    publication_code: 'JAN25_NL',
    publication_type_id: '5',
    description: 'January 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '12/17/25',
    submit_art_date: '12/23/24',
    production_start_date: '12/23/25',
    start_date: '1/2/25',
    expiration_date: '1/31/25',
  },
  {
    publication_code: 'FEB25_NL',
    publication_type_id: '5',
    description: 'February 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '1/20/25',
    submit_art_date: '1/27/25',
    production_start_date: '1/27/25',
    start_date: '2/2/25',
    expiration_date: '2/28/25',
  },
  {
    publication_code: 'MAR25_NL',
    publication_type_id: '5',
    description: 'March 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '2/17/25',
    submit_art_date: '2/24/25',
    production_start_date: '2/24/25',
    start_date: '3/3/25',
    expiration_date: '3/31/25',
  },
  {
    publication_code: 'APR25_NL',
    publication_type_id: '5',
    description: 'April 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '3/17/25',
    submit_art_date: '3/25/25',
    production_start_date: '3/25/25',
    start_date: '4/2/25',
    expiration_date: '4/30/25',
  },
  {
    publication_code: 'MAY25_NL',
    publication_type_id: '5',
    description: 'May 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '4/17/25',
    submit_art_date: '4/24/25',
    production_start_date: '4/24/25',
    start_date: '5/2/25',
    expiration_date: '5/31/25',
  },
  {
    publication_code: 'JUN25_NL',
    publication_type_id: '5',
    description: 'June 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '5/19/25',
    submit_art_date: '5/26/25',
    production_start_date: '5/26/25',
    start_date: '6/2/25',
    expiration_date: '6/30/25',
  },
  {
    publication_code: 'JUL25_NL',
    publication_type_id: '5',
    description: 'July 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '6/18/25',
    submit_art_date: '6/25/25',
    production_start_date: '6/25/25',
    start_date: '7/2/25',
    expiration_date: '7/31/25',
  },
  {
    publication_code: 'AUG25_NL',
    publication_type_id: '5',
    description: 'August 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '7/18/25',
    submit_art_date: '7/25/25',
    production_start_date: '7/25/25',
    start_date: '8/1/25',
    expiration_date: '8/31/25',
  },
  {
    publication_code: 'SEP25_NL',
    publication_type_id: '5',
    description: 'September 2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '8/18/25',
    submit_art_date: '8/25/25',
    production_start_date: '8/25/25',
    start_date: '9/2/25',
    expiration_date: '9/30/25',
  },
  {
    publication_code: 'OCT25_NL',
    publication_type_id: '5',
    description: 'October  2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '9/18/25',
    submit_art_date: '9/25/25',
    production_start_date: '9/25/25',
    start_date: '10/2/25',
    expiration_date: '10/31/25',
  },
  {
    publication_code: 'NOV25_NL',
    publication_type_id: '5',
    description: 'November  2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '10/20/25',
    submit_art_date: '10/27/25',
    production_start_date: '10/27/25',
    start_date: '11/3/25',
    expiration_date: '11/30/25',
  },
  {
    publication_code: 'DEC25_NL',
    publication_type_id: '5',
    description: 'December  2025 Newsletter Email',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '11/17/25',
    submit_art_date: '11/24/25',
    production_start_date: '11/24/25',
    start_date: '12/2/25',
    expiration_date: '12/31/25',
  },
  {
    publication_code: 'HOLIDAY25_EM',
    publication_type_id: '5',
    description: '2025 Holiday Gift Guide Email',
    detailed_description:
      'Guns for Every Enthusiast: Compact handguns, hunting rifles, and versatile shotguns to make the holidays unforgettable.\nUnique and Practical Gifts: Knives, multitools, optics, and other essential gear that blend functionality with style.\nNovelty Products: Fun and quirky items for collectors and enthusiasts, perfect for stocking stuffers or standout gifts.\nTop Brands and Must-Haves: Highlighting premium products and trusted names to ensure quality and satisfaction.\nGifts for Every Budget: A range of price points, from affordable accessories to high-end gear, catering to every shopper.',
    submit_contract_date: '9/1/25',
    submit_art_date: '9/8/25',
    production_start_date: '9/8/25',
    start_date: '10/13/25',
    expiration_date: '11/14/25',
  },
  {
    publication_code: 'JAN25_XEM',
    publication_type_id: '6',
    description: 'January 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '12/16/24',
    submit_art_date: '12/23/24',
    production_start_date: '12/23/24',
    start_date: '1/2/25',
    expiration_date: '1/31/25',
  },
  {
    publication_code: 'FEB25_XEM',
    publication_type_id: '6',
    description: 'February 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '1/20/25',
    submit_art_date: '1/27/25',
    production_start_date: '1/27/25',
    start_date: '2/2/25',
    expiration_date: '2/28/25',
  },
  {
    publication_code: 'MAR25_XEM',
    publication_type_id: '6',
    description: 'March 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '2/17/25',
    submit_art_date: '2/24/25',
    production_start_date: '2/24/25',
    start_date: '3/3/25',
    expiration_date: '3/31/25',
  },
  {
    publication_code: 'APR25_XEM',
    publication_type_id: '6',
    description: 'April 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '3/17/25',
    submit_art_date: '3/25/25',
    production_start_date: '3/25/25',
    start_date: '4/2/25',
    expiration_date: '4/30/25',
  },
  {
    publication_code: 'MAY25_XEM',
    publication_type_id: '6',
    description: 'May 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '4/17/25',
    submit_art_date: '4/24/25',
    production_start_date: '4/24/25',
    start_date: '5/2/25',
    expiration_date: '5/31/25',
  },
  {
    publication_code: 'JUN25_XEM',
    publication_type_id: '6',
    description: 'June 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '5/19/25',
    submit_art_date: '5/26/25',
    production_start_date: '5/26/25',
    start_date: '6/2/25',
    expiration_date: '6/30/25',
  },
  {
    publication_code: 'JUL25_XEM',
    publication_type_id: '6',
    description: 'July 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '6/18/25',
    submit_art_date: '6/25/25',
    production_start_date: '6/25/25',
    start_date: '7/2/25',
    expiration_date: '7/31/25',
  },
  {
    publication_code: 'AUG25_XEM',
    publication_type_id: '6',
    description: 'August 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '7/18/25',
    submit_art_date: '7/25/25',
    production_start_date: '7/25/25',
    start_date: '8/1/25',
    expiration_date: '8/31/25',
  },
  {
    publication_code: 'SEP25_XEM',
    publication_type_id: '6',
    description: 'September 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '8/18/25',
    submit_art_date: '8/25/25',
    production_start_date: '8/25/25',
    start_date: '9/2/25',
    expiration_date: '9/30/25',
  },
  {
    publication_code: 'OCT25_XEM',
    publication_type_id: '6',
    description: 'October 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '9/18/25',
    submit_art_date: '9/25/25',
    production_start_date: '9/25/25',
    start_date: '10/2/25',
    expiration_date: '10/31/25',
  },
  {
    publication_code: 'NOV25_XEM',
    publication_type_id: '6',
    description: 'November 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '10/20/25',
    submit_art_date: '10/27/25',
    production_start_date: '10/27/25',
    start_date: '11/3/25',
    expiration_date: '11/30/25',
  },
  {
    publication_code: 'DEC25_XEM',
    publication_type_id: '6',
    description: 'December 2025 Exclusive Email',
    detailed_description:
      'Exclusive Branding: A fully customized email featuring your logo, imagery, and messaging to ensure your brand shines.\nNew Product Showcases: Highlight your latest innovations and key products, complete with links to drive dealer engagement.\nPromotions and Offers: Promote exclusive deals, incentives, or seasonal specials to boost orders and excitement.',
    submit_contract_date: '11/17/25',
    submit_art_date: '11/24/25',
    production_start_date: '11/24/25',
    start_date: '12/2/25',
    expiration_date: '12/31/25',
  },
  {
    publication_code: 'RELOAD25_ODW',
    publication_type_id: '10',
    description: '2025 Reloading Outdoor Wire',
    detailed_description:
      '2025 Reloading Mini Mailer Essential Supplies: Stock up on must-haves like brass, bullets, and powders.\nExpert Tips and Tools: Enhance your customers’ reloading experience with high-quality tools and accessories.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/17/25',
    production_start_date: '2/12/25',
    start_date: '2/24/25',
    expiration_date: '3/7/25',
  },
  {
    publication_code: 'HOG25_ODW',
    publication_type_id: '10',
    description: '2025 Hog Outdoor Wire',
    detailed_description:
      'Rifles and Optics: Durable, high-performance rifles and precision optics tailored for day and night hog hunting.\nThermal and Night Vision Gear: Cutting-edge technology to help hunters spot hogs in any light condition.\nCalls and Attractants: Tools to lure hogs into range and ensure a successful hunt.\nEssential Accessories: Heavy-duty packs, hunting lights, and game retrieval equipment for the toughest terrain.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/10/25',
    production_start_date: '2/10/25',
    start_date: '3/17/25',
    expiration_date: '3/24/25',
  },
  {
    publication_code: 'DOVE25_ODW',
    publication_type_id: '10',
    description: '2025 Dove and Upland Outdoor Wire',
    detailed_description:
      'Shotguns and Ammunition: Highlighting reliable shotguns and specialized upland and dove loads for superior performance.\nDecoys and Calls: Effective tools for attracting birds and enhancing every hunt.\nApparel and Accessories: Lightweight, durable vests, boots, and gloves built for long days in the field.\nDog Gear and Training Aids: Essentials for hunting companions, including collars, kennels, and training tools.',
    submit_contract_date: '6/23/25',
    submit_art_date: '6/30/25',
    production_start_date: '6/30/25',
    start_date: '8/4/25',
    expiration_date: '8/10/25',
  },
  {
    publication_code: 'PREDATOR25_ODW',
    publication_type_id: '10',
    description: '2025 Predator and Varmint Oudoor Wire',
    detailed_description:
      'Rifles and Optics: High-powered rifles and precision scopes designed for long-range accuracy and versatility.\nElectronic Calls and Decoys: Advanced calling systems and realistic decoys to lure predators into range.\nThermal and Night Vision Gear: Cutting-edge technology for effective hunting in low-light and nighttime conditions.\nAmmunition and Accessories: Premium varmint loads and essential gear like shooting sticks and camo.',
    submit_contract_date: '7/14/25',
    submit_art_date: '7/21/25',
    production_start_date: '7/21/25',
    start_date: '8/25/25',
    expiration_date: '8/31/25',
  },
  {
    publication_code: 'SHOT25_ODW',
    publication_type_id: '10',
    description: '2025 SHOT Show Specials Oudoor Wire',
    detailed_description:
      'New Product Spotlights: Showcasing the latest innovations and must-have products across firearms, optics, accessories, and more.',
    submit_contract_date: '1/27/25',
    submit_art_date: '2/3/25',
    production_start_date: '2/3/25',
    start_date: '2/10/25',
    expiration_date: '2/16/25',
  },
  {
    publication_code: 'WOMEN25_ODW',
    publication_type_id: '10',
    description: '2025 Women in the Industry Outdoor Wire',
    detailed_description:
      'Innovative Gear for Women: Firearms, holsters, and accessories designed with women in mind—combining function, fit, and style.\nSpotlight on Female Leaders: Inspiring stories of women making an impact in the firearm industry and breaking barriers.\nProduct Features: Compact handguns, lightweight hunting gear, customizable options, and more to meet the preferences and needs of women shooters.\nTraining and Education Resources: Information on self-defense classes, shooting sports opportunities, and gear tips for women new to firearms.',
    submit_contract_date: '5/12/25',
    submit_art_date: '5/19/25',
    production_start_date: '5/19/25',
    start_date: '5/26/25',
    expiration_date: '6/1/25',
  },
  {
    publication_code: 'SAFETY25_ODW',
    publication_type_id: '10',
    description: '2025 Gun Safety and Storage Outdoor Wire',
    detailed_description:
      'Secure Storage Solutions: Safes, lockboxes, and trigger locks designed to prevent unauthorized access.\nChild Safety Products: Innovative devices and educational tools to help families create safe environments.\nRange Safety Gear: Ear and eye protection, chamber flags, and other essentials for safe practice and training.',
    submit_contract_date: '3/31/25',
    submit_art_date: '4/7/25',
    production_start_date: '4/7/25',
    start_date: '4/14/25',
    expiration_date: '4/20/25',
  },
  {
    publication_code: 'HOLSTER25_ODW',
    publication_type_id: '10',
    description: '2025 Holstering a Gun Oudoor Wire',
    detailed_description:
      'Holsters for Every Carry Style: Inside-the-waistband (IWB), outside-the-waistband (OWB), ankle, shoulder, and appendix carry solutions from trusted brands.\nCustomizable and Modular Options: Holsters with adjustable retention, modular designs, and compatibility with various firearms.\nConcealed Carry Essentials: Accessories like gun belts, mag carriers, and clothing designed for discreet and secure carry.\nTactical and Duty Gear: Holsters built for law enforcement, military, and competition use, offering durability and quick access.',
    submit_contract_date: '11/3/25',
    submit_art_date: '11/10/25',
    production_start_date: '11/10/25',
    start_date: '11/17/25',
    expiration_date: '11/23/25',
  },
  {
    publication_code: 'ARCHERY25_ODW',
    publication_type_id: '10',
    description: '2025 Archery Outdoor Wire',
    detailed_description:
      'Bows for Every Shooter: From high-performance compound and recurve bows to beginner-friendly models, this publication showcases options for all skill levels.\nArrows and Broadheads: Highlighting durable, high-accuracy arrows and premium broadheads tailored for hunting and target shooting.\nAccessories and Upgrades: Releases, quivers, sights, stabilizers, and other tools to enhance precision and performance.\nHunting Essentials: Tree stands, bow cases, and camo gear designed specifically for archery hunters.',
    submit_contract_date: '7/21/25',
    submit_art_date: '7/28/25',
    production_start_date: '7/28/25',
    start_date: '8/4/25',
    expiration_date: '8/10/25',
  },
  {
    publication_code: 'RANGE25_MLR',
    publication_type_id: '2',
    description: '2025 Summer Range Mailer',
    detailed_description:
      'Top-Tier Firearms and Optics: Showcase the latest handguns, rifles, and cutting-edge optics perfect for summer shooting.\nAmmunition and Targets: High-performance ammo and innovative target solutions to enhance practice sessions.\nProtective Gear and Accessories: Ear and eye protection, range bags, and other must-have accessories for a safe and efficient experience.',
    submit_contract_date: '3/10/25',
    submit_art_date: '3/17/25',
    production_start_date: '3/17/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'FEB25_8PG1',
    publication_type_id: '2',
    description: '2025 February 8PG Spot 1',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the February Turkey Package. Limited Availability',
    submit_contract_date: '12/16/24',
    submit_art_date: '12/23/25',
    production_start_date: '12/23/25',
    start_date: '2/10/25',
    expiration_date: '3/10/25',
  },
  {
    publication_code: 'FEB25_8PG2',
    publication_type_id: '2',
    description: '2025 February 8PG Spot 2',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the February Turkey Package. Limited Availability',
    submit_contract_date: '12/16/24',
    submit_art_date: '12/23/25',
    production_start_date: '12/23/25',
    start_date: '2/10/25',
    expiration_date: '3/10/25',
  },
  {
    publication_code: 'FEB25_8PG3',
    publication_type_id: '2',
    description: '2025 February 8PG Spot 3',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the February Turkey Package. Limited Availability',
    submit_contract_date: '12/16/24',
    submit_art_date: '12/23/25',
    production_start_date: '12/23/25',
    start_date: '2/10/25',
    expiration_date: '3/10/25',
  },
  {
    publication_code: 'MAY25_8PG1',
    publication_type_id: '2',
    description: '2025 May 8 PG Spot 1',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the May Range Package. Limited Availability',
    submit_contract_date: '4/14/25',
    submit_art_date: '4/21/25',
    production_start_date: '4/21/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'MAY25_8PG2',
    publication_type_id: '2',
    description: '2025 May 8 PG Spot 2',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the May Range Package. Limited Availability',
    submit_contract_date: '4/14/25',
    submit_art_date: '4/21/25',
    production_start_date: '4/21/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'MAY25_8PG3',
    publication_type_id: '2',
    description: '2025 May 8 PG Spot 3',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the May Range Package. Limited Availability',
    submit_contract_date: '4/14/25',
    submit_art_date: '4/21/25',
    production_start_date: '4/21/25',
    start_date: '5/5/25',
    expiration_date: '6/13/25',
  },
  {
    publication_code: 'GAME25_MLR',
    publication_type_id: '2',
    description: '2025 Big Game Mailer',
    detailed_description:
      'Rifles and Optics: Highlighting powerful rifles and precision scopes designed for long-range and rugged terrain.\nEssential Gear: High-quality packs, camo apparel, game calls, and other critical accessories for big game success.\nAmmunition and Scents: Superior ammunition and scent-control products to give hunters the edge they need.\nTop Brands and Innovations: Showcasing trusted brands and the latest advancements in hunting technology.',
    submit_contract_date: '6/9/25',
    submit_art_date: '6/16/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'JULY25_8PG1',
    publication_type_id: '2',
    description: '2025 July 8 PG Spot 1',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the July Big Game Package. Limited Availability',
    submit_contract_date: '5/26/25',
    submit_art_date: '6/2/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'JULY25_8PG2',
    publication_type_id: '2',
    description: '2025 July 8 PG Spot 2',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the July Big Game Package. Limited Availability',
    submit_contract_date: '5/26/25',
    submit_art_date: '6/2/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'JULY25_8PG3',
    publication_type_id: '2',
    description: '2025 July 8 PG Spot 3',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the July Big Game Package. Limited Availability',
    submit_contract_date: '5/26/25',
    submit_art_date: '6/2/25',
    production_start_date: '6/2/25',
    start_date: '7/14/25',
    expiration_date: '8/24/25',
  },
  {
    publication_code: 'WATERFOWL25_MLR',
    publication_type_id: '2',
    description: '2025 Waterfowl Mailer',
    detailed_description:
      'Decoys and Calls: Highlighting the most realistic decoys and effective calls to lure in ducks and geese.\nShotguns and Ammunition: Showcasing reliable shotguns and high-performance waterfowl-specific loads for optimal success.\nBlinds and Accessories: Portable and durable blinds, waders, and other essential gear for harsh environments.\nTop Brands and Innovations: Featuring trusted names and the latest advancements in waterfowl hunting technology.',
    submit_contract_date: '8/4/11',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'SEPT25_8PG1',
    publication_type_id: '2',
    description: '2025 September 8 PG Spot 1',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the September Waterfowl Package. Limited Availability',
    submit_contract_date: '8/4/11',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'SEPT25_8PG2',
    publication_type_id: '2',
    description: '2025 September 8 PG Spot 2',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the September Waterfowl Package. Limited Availability',
    submit_contract_date: '8/4/11',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'SEPT25_8PG3',
    publication_type_id: '2',
    description: '2025 September 8 PG Spot 3',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the September Waterfowl Package. Limited Availability',
    submit_contract_date: '8/4/11',
    submit_art_date: '8/11/25',
    production_start_date: '8/11/25',
    start_date: '9/22/25',
    expiration_date: '10/22/25',
  },
  {
    publication_code: 'HANDGUN25_MAILER',
    publication_type_id: '2',
    description: '2025 Handgun and EDC Mailer',
    detailed_description:
      'Top Handgun Models: Compact and full-size options from trusted brands, offering reliability and performance.\nHolsters and Carry Solutions: Versatile holsters and concealed carry systems tailored for comfort and quick access.\nAmmunition and Accessories: Premium self-defense rounds, cleaning kits, and spare magazines for every need.\nEveryday Essentials: Flashlights, knives, and multitools to complete a well-rounded carry kit.',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'DEC25_8PG1',
    publication_type_id: '2',
    description: '2025 December 8 PG Spot 1',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the December Handgun and EDC Package. Limited Availability',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'DEC25_8PG2',
    publication_type_id: '2',
    description: '2025 December 8 PG Spot 2',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the December Handgun and EDC Package. Limited Availability',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'DEC25_8PG3',
    publication_type_id: '2',
    description: '2025 December 8 PG Spot 3',
    detailed_description:
      'Exclusive Branded 8 Pager inserted in the December Handgun and EDC Package. Limited Availability',
    submit_contract_date: '10/6/25',
    submit_art_date: '10/13/25',
    production_start_date: '10/13/25',
    start_date: '11/24/25',
    expiration_date: '12/24/25',
  },
  {
    publication_code: 'HOG25_MLR',
    publication_type_id: '2',
    description: '2025 Hog Mini Mailer',
    detailed_description:
      'Rifles and Optics: Durable, high-performance rifles and precision optics tailored for day and night hog hunting.\nThermal and Night Vision Gear: Cutting-edge technology to help hunters spot hogs in any light condition.\nCalls and Attractants: Tools to lure hogs into range and ensure a successful hunt.\nEssential Accessories: Heavy-duty packs, hunting lights, and game retrieval equipment for the toughest terrain.',
    submit_contract_date: '2/3/25',
    submit_art_date: '2/10/25',
    production_start_date: '2/10/25',
    start_date: '3/17/25',
    expiration_date: '4/17/25',
  },
  {
    publication_code: 'DOVE25_MLR',
    publication_type_id: '2',
    description: '2025 Dove and Upland Mini Mailer',
    detailed_description:
      'Shotguns and Ammunition: Highlighting reliable shotguns and specialized upland and dove loads for superior performance.\nDecoys and Calls: Effective tools for attracting birds and enhancing every hunt.\nApparel and Accessories: Lightweight, durable vests, boots, and gloves built for long days in the field.\nDog Gear and Training Aids: Essentials for hunting companions, including collars, kennels, and training tools.',
    submit_contract_date: '6/23/25',
    submit_art_date: '6/30/25',
    production_start_date: '6/30/25',
    start_date: '8/4/25',
    expiration_date: '9/4/25',
  },
  {
    publication_code: 'PREDATOR25_MLR',
    publication_type_id: '2',
    description: '2025 Predator and Varmint Mini Mailer',
    detailed_description:
      'Rifles and Optics: High-powered rifles and precision scopes designed for long-range accuracy and versatility.\nElectronic Calls and Decoys: Advanced calling systems and realistic decoys to lure predators into range.\nThermal and Night Vision Gear: Cutting-edge technology for effective hunting in low-light and nighttime conditions.\nAmmunition and Accessories: Premium varmint loads and essential gear like shooting sticks and camo.',
    submit_contract_date: '7/14/25',
    submit_art_date: '7/21/25',
    production_start_date: '7/21/25',
    start_date: '8/25/25',
    expiration_date: '9/25/25',
  },
  {
    publication_code: 'HOLIDAY25_MAILER',
    publication_type_id: '2',
    description: '2025 Holiday Gift Guide Mailer',
    detailed_description:
      'Guns for Every Enthusiast: Compact handguns, hunting rifles, and versatile shotguns to make the holidays unforgettable.\nUnique and Practical Gifts: Knives, multitools, optics, and other essential gear that blend functionality with style.\nNovelty Products: Fun and quirky items for collectors and enthusiasts, perfect for stocking stuffers or standout gifts.\nTop Brands and Must-Haves: Highlighting premium products and trusted names to ensure quality and satisfaction.\nGifts for Every Budget: A range of price points, from affordable accessories to high-end gear, catering to every shopper.',
    submit_contract_date: '9/1/25',
    submit_art_date: '9/8/25',
    production_start_date: '9/8/25',
    start_date: '10/13/25',
    expiration_date: '11/14/25',
  },
  {
    publication_code: 'RELOAD25_WB',
    publication_type_id: '3',
    description: '2025 Reloading Web Banners',
    detailed_description:
      '2025 Reloading Mini Mailer Essential Supplies: Stock up on must-haves like brass, bullets, and powders.\nExpert Tips and Tools: Enhance your customers’ reloading experience with high-quality tools and accessories.',
    submit_contract_date: '2/10/25',
    submit_art_date: '2/17/25',
    production_start_date: '2/17/25',
    start_date: '2/24/25',
    expiration_date: '3/7/25',
  },
  {
    publication_code: 'RANGE25_WB',
    publication_type_id: '3',
    description: '2025 Summer Range Web Banners',
    detailed_description:
      'Top-Tier Firearms and Optics: Showcase the latest handguns, rifles, and cutting-edge optics perfect for summer shooting.\nAmmunition and Targets: High-performance ammo and innovative target solutions to enhance practice sessions.\nProtective Gear and Accessories: Ear and eye protection, range bags, and other must-have accessories for a safe and efficient experience.""',
    submit_contract_date: '4/21/25',
    submit_art_date: '4/28/25',
    production_start_date: '4/28/25',
    start_date: '5/5/25',
    expiration_date: '5/23/25',
  },
  {
    publication_code: 'GAME25_WB',
    publication_type_id: '3',
    description: '2025 Big Game Web Banners',
    detailed_description:
      'Rifles and Optics: Highlighting powerful rifles and precision scopes designed for long-range and rugged terrain.\nEssential Gear: High-quality packs, camo apparel, game calls, and other critical accessories for big game success.\nAmmunition and Scents: Superior ammunition and scent-control products to give hunters the edge they need.\nTop Brands and Innovations: Showcasing trusted brands and the latest advancements in hunting technology.',
    submit_contract_date: '6/30/25',
    submit_art_date: '7/7/25',
    production_start_date: '7/7/25',
    start_date: '7/14/25',
    expiration_date: '7/25/25',
  },
  {
    publication_code: 'WATERFOWL25_WB',
    publication_type_id: '3',
    description: '2025 Waterfowl Web Banners',
    detailed_description:
      'Decoys and Calls: Highlighting the most realistic decoys and effective calls to lure in ducks and geese.\nShotguns and Ammunition: Showcasing reliable shotguns and high-performance waterfowl-specific loads for optimal success.\nBlinds and Accessories: Portable and durable blinds, waders, and other essential gear for harsh environments.\nTop Brands and Innovations: Featuring trusted names and the latest advancements in waterfowl hunting technology.',
    submit_contract_date: '9/8/25',
    submit_art_date: '9/15/25',
    production_start_date: '9/15/25',
    start_date: '9/22/25',
    expiration_date: '10/3/25',
  },
  {
    publication_code: 'HANDGUN25_WB',
    publication_type_id: '3',
    description: '2025 Handgun and EDC Web Banners',
    detailed_description:
      'Top Handgun Models: Compact and full-size options from trusted brands, offering reliability and performance.\nHolsters and Carry Solutions: Versatile holsters and concealed carry systems tailored for comfort and quick access.\nAmmunition and Accessories: Premium self-defense rounds, cleaning kits, and spare magazines for every need.\nEveryday Essentials: Flashlights, knives, and multitools to complete a well-rounded carry kit.',
    submit_contract_date: '11/10/25',
    submit_art_date: '11/17/25',
    production_start_date: '11/17/25',
    start_date: '11/24/25',
    expiration_date: '12/7/25',
  },
  {
    publication_code: 'HOG25_WB',
    publication_type_id: '3',
    description: '2025 Hog Web Banners',
    detailed_description:
      'Rifles and Optics: Durable, high-performance rifles and precision optics tailored for day and night hog hunting.\nThermal and Night Vision Gear: Cutting-edge technology to help hunters spot hogs in any light condition.\nCalls and Attractants: Tools to lure hogs into range and ensure a successful hunt.\nEssential Accessories: Heavy-duty packs, hunting lights, and game retrieval equipment for the toughest terrain.',
    submit_contract_date: '3/3/25',
    submit_art_date: '3/10/25',
    production_start_date: '3/10/25',
    start_date: '3/17/25',
    expiration_date: '4/4/25',
  },
  {
    publication_code: 'DOVE25_WB',
    publication_type_id: '3',
    description: '2025 Dove and Upland Web Banners',
    detailed_description:
      'Shotguns and Ammunition: Highlighting reliable shotguns and specialized upland and dove loads for superior performance.\nDecoys and Calls: Effective tools for attracting birds and enhancing every hunt.\nApparel and Accessories: Lightweight, durable vests, boots, and gloves built for long days in the field.\nDog Gear and Training Aids: Essentials for hunting companions, including collars, kennels, and training tools.',
    submit_contract_date: '7/21/25',
    submit_art_date: '7/28/25',
    production_start_date: '7/28/25',
    start_date: '8/4/25',
    expiration_date: '8/15/25',
  },
  {
    publication_code: 'PREDATOR25_WB',
    publication_type_id: '3',
    description: '2025 Predator and Varmint Web Banner',
    detailed_description:
      'Rifles and Optics: High-powered rifles and precision scopes designed for long-range accuracy and versatility.\nElectronic Calls and Decoys: Advanced calling systems and realistic decoys to lure predators into range.\nThermal and Night Vision Gear: Cutting-edge technology for effective hunting in low-light and nighttime conditions.\nAmmunition and Accessories: Premium varmint loads and essential gear like shooting sticks and camo.',
    submit_contract_date: '8/11/25',
    submit_art_date: '8/18/25',
    production_start_date: '8/18/28',
    start_date: '8/25/25',
    expiration_date: '9/5/25',
  },
  {
    publication_code: 'SHOT25_WB',
    publication_type_id: '3',
    description: '2025 SHOT Show Specials Web Banner',
    detailed_description:
      'New Product Spotlights: Showcasing the latest innovations and must-have products across firearms, optics, accessories, and more.',
    submit_contract_date: '1/27/25',
    submit_art_date: '2/3/25',
    production_start_date: '2/3/25',
    start_date: '2/10/25',
    expiration_date: '2/16/25',
  },
  {
    publication_code: 'WOMEN25_WB',
    publication_type_id: '3',
    description: '2025 Women in the Industry Web Banners',
    detailed_description:
      'Innovative Gear for Women: Firearms, holsters, and accessories designed with women in mind—combining function, fit, and style.\nSpotlight on Female Leaders: Inspiring stories of women making an impact in the firearm industry and breaking barriers.\nProduct Features: Compact handguns, lightweight hunting gear, customizable options, and more to meet the preferences and needs of women shooters.\nTraining and Education Resources: Information on self-defense classes, shooting sports opportunities, and gear tips for women new to firearms.',
    submit_contract_date: '5/12/25',
    submit_art_date: '5/19/25',
    production_start_date: '5/19/25',
    start_date: '5/26/25',
    expiration_date: '6/1/25',
  },
  {
    publication_code: 'SAFETY25_WB',
    publication_type_id: '3',
    description: '2025 Gun Safety and Storage Web Banners',
    detailed_description:
      'Secure Storage Solutions: Safes, lockboxes, and trigger locks designed to prevent unauthorized access.\nChild Safety Products: Innovative devices and educational tools to help families create safe environments.\nRange Safety Gear: Ear and eye protection, chamber flags, and other essentials for safe practice and training.',
    submit_contract_date: '3/31/25',
    submit_art_date: '4/7/25',
    production_start_date: '4/7/25',
    start_date: '4/14/25',
    expiration_date: '4/20/25',
  },
  {
    publication_code: 'HOLSTER25_WB',
    publication_type_id: '3',
    description: '2025 Holstering a Gun Web Banners',
    detailed_description:
      'Holsters for Every Carry Style: Inside-the-waistband (IWB), outside-the-waistband (OWB), ankle, shoulder, and appendix carry solutions from trusted brands.\nCustomizable and Modular Options: Holsters with adjustable retention, modular designs, and compatibility with various firearms.\nConcealed Carry Essentials: Accessories like gun belts, mag carriers, and clothing designed for discreet and secure carry.\nTactical and Duty Gear: Holsters built for law enforcement, military, and competition use, offering durability and quick access.',
    submit_contract_date: '11/3/25',
    submit_art_date: '11/10/25',
    production_start_date: '11/10/25',
    start_date: '11/17/25',
    expiration_date: '11/23/25',
  },
  {
    publication_code: 'ARCHERY25_WB',
    publication_type_id: '3',
    description: '2025 Archery Web Banners',
    detailed_description:
      'Bows for Every Shooter: From high-performance compound and recurve bows to beginner-friendly models, this publication showcases options for all skill levels.\nArrows and Broadheads: Highlighting durable, high-accuracy arrows and premium broadheads tailored for hunting and target shooting.\nAccessories and Upgrades: Releases, quivers, sights, stabilizers, and other tools to enhance precision and performance.\nHunting Essentials: Tree stands, bow cases, and camo gear designed specifically for archery hunters.',
    submit_contract_date: '7/21/25',
    submit_art_date: '7/28/25',
    production_start_date: '7/28/25',
    start_date: '8/4/25',
    expiration_date: '8/10/25',
  },
  {
    publication_code: 'JAN25_WB',
    publication_type_id: '3',
    description: 'January 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '12/17/25',
    submit_art_date: '12/23/24',
    production_start_date: '12/23/25',
    start_date: '1/2/25',
    expiration_date: '1/31/25',
  },
  {
    publication_code: 'FEB25_WB',
    publication_type_id: '3',
    description: 'February 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '1/20/25',
    submit_art_date: '1/27/25',
    production_start_date: '1/27/25',
    start_date: '2/2/25',
    expiration_date: '2/28/25',
  },
  {
    publication_code: 'MAR25_WB',
    publication_type_id: '3',
    description: 'March 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '2/17/25',
    submit_art_date: '2/24/25',
    production_start_date: '2/24/25',
    start_date: '3/3/25',
    expiration_date: '3/31/25',
  },
  {
    publication_code: 'APR25_WB',
    publication_type_id: '3',
    description: 'April 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '3/17/25',
    submit_art_date: '3/25/25',
    production_start_date: '3/25/25',
    start_date: '4/2/25',
    expiration_date: '4/30/25',
  },
  {
    publication_code: 'MAY25_WB',
    publication_type_id: '3',
    description: 'May 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '4/17/25',
    submit_art_date: '4/24/25',
    production_start_date: '4/24/25',
    start_date: '5/2/25',
    expiration_date: '5/31/25',
  },
  {
    publication_code: 'JUN25_WB',
    publication_type_id: '3',
    description: 'June 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '5/19/25',
    submit_art_date: '5/26/25',
    production_start_date: '5/26/25',
    start_date: '6/2/25',
    expiration_date: '6/30/25',
  },
  {
    publication_code: 'JUL25_WB',
    publication_type_id: '3',
    description: 'July 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '6/18/25',
    submit_art_date: '6/25/25',
    production_start_date: '6/25/25',
    start_date: '7/2/25',
    expiration_date: '7/31/25',
  },
  {
    publication_code: 'AUG25_WB',
    publication_type_id: '3',
    description: 'August 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '7/18/25',
    submit_art_date: '7/25/25',
    production_start_date: '7/25/25',
    start_date: '8/1/25',
    expiration_date: '8/31/25',
  },
  {
    publication_code: 'SEP25_WB',
    publication_type_id: '3',
    description: 'September 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '8/18/25',
    submit_art_date: '8/25/25',
    production_start_date: '8/25/25',
    start_date: '9/2/25',
    expiration_date: '9/30/25',
  },
  {
    publication_code: 'OCT25_WB',
    publication_type_id: '3',
    description: 'October 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '9/18/25',
    submit_art_date: '9/25/25',
    production_start_date: '9/25/25',
    start_date: '10/2/25',
    expiration_date: '10/31/25',
  },
  {
    publication_code: 'NOV25_WB',
    publication_type_id: '3',
    description: 'November 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '10/20/25',
    submit_art_date: '10/27/25',
    production_start_date: '10/27/25',
    start_date: '11/3/25',
    expiration_date: '11/30/25',
  },
  {
    publication_code: 'DEC25_WB',
    publication_type_id: '3',
    description: 'December 2025 Web Banners',
    detailed_description:
      'Product Highlights: Promote your newest launches, top-selling items, or special programs to drive dealer interest and orders.\nCustom Call-to-Actions: Link directly to your product pages, dealer programs, or promotional offers to maximize engagement.',
    submit_contract_date: '11/17/25',
    submit_art_date: '11/24/25',
    production_start_date: '11/24/25',
    start_date: '12/2/25',
    expiration_date: '12/31/25',
  },
];
