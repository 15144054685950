import { ProductDataContextState } from '../../../Context/Marketing/ProductData/ProductDataContext';
import { useMemo, useContext, useState, useEffect } from 'react';

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ActionMenuItem
//   MRT_GlobalFilterTextField,
//   MRT_ToggleFiltersButton,
} from 'material-react-table';

import { ViewComfy } from '@mui/icons-material';
export default function ProductDataGeneralTable( props ) {

    const { allCategories, allDepartments, allTypes, allManufacturers } = useContext(ProductDataContextState)
    const data = (props.data)

  const columns = useMemo(
    () => [
        {
                        id:'Vendor/Brand',
                        accessorKey: 'manufacturer',
                        header:'Vendor/Brand',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allManufacturers
                    },{
                        id:200,
                        accessorKey: 'department',
                        header:'Dept',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allDepartments
                    },{
                        id:300,
                        accessorKey: 'type',
                        header:'Item Type',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allTypes
                    },{
                        id:400,
                        accessorKey: 'category',
                        header:'Category',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allCategories
                    },{
                        id:500,
                        accessorKey: 'upc_code',
                        header:'UPC Code',
                        size:150,
                    },{
                        id:600,
                        accessorKey: 'product_id',
                        header:'SS#',
                        size:150,
                    },{
                        id:700,
                        accessorKey: 'detailed_description',
                        header:'Description',
                        size:350,
                        grow: false
                    },{
                        id:800,
                        accessorKey: 'model',
                        header:'Model',
                        size:150,
                    },{
                        id:900,
                        accessorKey: 'series',
                        header:'Series',
                        size:150,
                    },{
                        id:1000,
                        accessorKey: 'scan1',
                        header:'Scan 1',
                        size:150,
                    },{
                        id:1100,
                        accessorKey: 'scan2',
                        header:'Scan 2',
                        size:150,
                    }
    ]
  );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        enableRowActions: true,
        enableStickyHeader: true,
        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row, table}) => [
            <MRT_ActionMenuItem
                icon={<ViewComfy />}
                key="viewpp"
                label='View Product Page'
                onClick={() => {
                    
                    closeMenu()
                    console.log(row)
                    //props.switchPage('product_page', '',)
                }}
                table={table}
                
            />
        ],
        

      });

      return(
        
        <div style={{border: 'solid 1px', margin:10, borderRadius:5, boxShadow:'25px', }}>
            <MaterialReactTable table={table}/>    
        </div>      
        
    )
};