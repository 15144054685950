import { Avatar, Box, Button, Chip, IconButton, styled, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import PaymentStatus from './components/PaymentStatus/PaymentStatus';
import { ArrowForwardIos, DeleteForever, Remove, Visibility } from '@mui/icons-material';
import DeleteContract from './components/DeleteContract';
import EditContractsProduct from './components/EditContractsProduct';
import ItemsBrands from './components/ItemsBrands';

// Material Styled Components
const Cell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const Row = ({ idx, item }) => {
  // INIT's
  const theme = useTheme();

  // FUNCTIONS
  // const handleDeleteClick = () => {
  //   console.log('adsfsdf' + item.ss_publication_contract_id);
  // };

  console.log('item: ', item);

  return (
    <Box
      sx={{
        backgroundColor: idx % 2 === 0 ? 'white' : '#e5f1f9',
        padding: '16px',
        display: 'flex',
        gap: '32px',
        alignItems: 'center',
        '.grow': {
          flex: 1,
        },
      }}
    >
      {/* <Cell sx={{ width: '100%', maxWidth: '60px' }}>
        <Tooltip title='Dallas Miller'>
          <Avatar
            sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
            alt='Remy Sharp'
            src='https://images.unsplash.com/photo-1688888745596-da40843a8d45?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            onClick={() => alert('Dallas Miller')}
          />
        </Tooltip>
      </Cell> */}
      <Cell sx={{ width: '100%', maxWidth: '115px', textAlign: 'center' }}>
        <Box>
          <PaymentStatus
            item={item}
            //status={item.ss_publication.ss_publication_payment_status_id}
            label={item.payment_status}
            contractId={item.ss_publication_contract_id}
          />
        </Box>
      </Cell>
      <Cell className='grow' sx={{}}>
        <Box sx={{ fontWeight: 'bold', fontSize: '.8em' }}>
          Contract Id# {item.ss_publication_contract_id}
        </Box>
        <Box sx={{ fontWeight: 'bold', fontSize: '1.2em', color: theme.palette.primary.main }}>
          {item.ss_publication.description}
        </Box>
      </Cell>
      <Cell sx={{ width: '100%', maxWidth: '200px' }}>
        <Box>{item.ss_publication.ss_publication_type}</Box>
        <Box>
          <EditContractsProduct item={item} />
        </Box>
      </Cell>

      <Cell sx={{ width: '100%', maxWidth: '250px' }}>
        <Box sx={{ paddingY: '16px', fontWeight: 'bold', fontSize: '1em' }}>{item.vendor}</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '24px',
            justifyContent: 'flex-start',
          }}
        >
          <ItemsBrands brands={item.brands} />
        </Box>
      </Cell>
      <Cell sx={{ width: '100%', maxWidth: '110px' }}>
        <Box sx={{ fontWeight: 'bold', fontSize: '.8em' }}>
          {dayjs().isBefore(dayjs(item.ss_publication.start_date).add(30, 'day'))
            ? 'Due On:'
            : dayjs().isSame(dayjs(item.ss_publication.start_date).add(30, 'day'))
            ? 'Due Now:'
            : 'Was Due On'}
        </Box>

        {dayjs(item.ss_publication.start_date).add(30, 'day').format('MMM DD, YYYY')}
      </Cell>

      <Cell sx={{ width: '100%', maxWidth: '145px', textAlign: 'right' }}>
        <>
          {item.ss_publication_payment_status_id !== 2 &&
            item.days_until_due > 2 &&
            `Due In ${item.days_until_due} Days`}
          {item.ss_publication_payment_status_id !== 2 &&
            item.days_until_due === 1 &&
            `Due Tomorrow`}
          {item.ss_publication_payment_status_id !== 2 && item.days_until_due === 0 && `Due Today`}
          {item.ss_publication_payment_status_id !== 2 &&
            item.days_until_due === -1 &&
            `Due Yesterday`}
          {item.ss_publication_payment_status_id !== 2 &&
            item.days_until_due < -1 &&
            item.days_until_due > -8 &&
            `Due Last Week`}
          {item.ss_publication_payment_status_id !== 2 &&
            item.days_until_due < -7 &&
            `${Math.abs(item.days_until_due)} Days Past Due`}
        </>

        <Box sx={{ fontSize: '1.5em', fontWeight: 'bold', position: 'relative' }}>
          <Box sx={{ color: theme.palette.accent.main, fontSize: '.65em' }}>
            orig: ${item.sold_price}
          </Box>
          ${parseFloat(Number(item.sold_price) - Number(item.total_spent)).toFixed(2)}
        </Box>
      </Cell>

      {/* <Cell sx={{ textAlign: 'right', width: '100%', maxWidth: '100px', paddingRight: '8px' }}>
        <IconButton
          href={`http://localhost:3000/marketing/contract/view/${item.ss_publication_contract_id}`}
          target='_blank'
        >
          <ArrowForwardIos
            sx={{
              width: '24px',
              height: '24px',
              padding: '4px',
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '50%',
            }}
          />
        </IconButton>
      </Cell> */}
      {/* <Cell>
        <DeleteContract item={item} />
      </Cell> */}
    </Box>
  );
};

export default Row;
