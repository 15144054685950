import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Button } from '@mui/material';
import { getAllCategories } from '../../../../../_GlobalFunctions/advertisingFunctions';
import {
  insertProductCategories,
  deleteProductCategories,
  PublicationProductCategories,
} from '../../../../../_GlobalFunctions/publicationFunctions';

const PccDropdown = ({ publicationId }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    ss_publication_id: publicationId,
    product_category_id: [],
  });

  useEffect(() => {
    const initialLoad = async () => {
      const catFunction = await getAllCategories();
      const currentCats = await PublicationProductCategories(publicationId);
      const mappedCats = currentCats.map((e) => e.product_category_id);
      setCategories(catFunction);

      const selectedCategories = catFunction.filter((cat) =>
        mappedCats.includes(cat.product_category_id)
      );

      const mappedSC = selectedCategories.map((e) => e.product_category_id);

      setFormData((prevData) => ({
        ...prevData,
        product_category_id: mappedSC, // Save the full category objects
      }));
    };
    initialLoad();
  }, [publicationId]);

  const handleChange = (event, value) => {
    const selectedIds = value.map((cat) => cat.product_category_id);
    setFormData((prevData) => ({
      ...prevData,
      product_category_id: selectedIds,
    }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Autocomplete
        fullWidth
        multiple
        id='product-categories'
        options={categories}
        value={formData.product_category_id
          .map((id) => categories.find((cat) => cat.product_category_id === id))
          .filter((cat) => cat !== undefined)} // Ensure no undefined values in the value array
        onChange={handleChange}
        disableCloseOnSelect
        getOptionLabel={(cats) => cats?.description || ''} // Add optional chaining to handle undefined
        renderOption={(props, cats, { selected }) => {
          const { key, ...catsProps } = props;
          return (
            <li key={key} {...catsProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {cats?.description || ''}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label='Select Categories' />}
        style={{}}
      />
      <Box sx={{ marginTop: 1, marginLeft: 1 }}></Box>
    </Box>
  );
};

export default PccDropdown;
