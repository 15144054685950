import axios from 'axios';

import {
  insertProductCategories,
  deleteProductCategories,
} from '../_GlobalFunctions/publicationFunctions';

export const insertOrUpdatePublication = async (data, categoryData) => {
  console.log('passed dataa: ' + data.ss_publication_id);
  try {
    const endpoint =
      data.ss_publication_id < 1
        ? '/publications/insertPublication'
        : '/publications/updatePublication';
    const response = await axios.post(endpoint, { data });
    const deleteRes = await deleteProductCategories({ ss_publication_id: data.ss_publication_id });
    const insertRes = await insertProductCategories({
      ss_publication_id: data.ss_publication_id,
      cat_ids: [...categoryData],
    });

    return response;
  } catch (error) {
    console.log('error insertOrUpdateContact: ', error);
  }
};
