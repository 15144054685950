import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server.browser';
import { Body, Column, Html, Row, Section, Text } from '@react-email/components';
import { Button } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';

import Block1008 from '../Blocks/Block1008';
import Block1009 from '../Blocks/Block1009';
import Block1010 from '../Blocks/Block1010';
import Footer1000 from '../Blocks/Footer1000';
import Header1000 from '../Blocks/Header1000';
import PromotionBoard01 from '../Blocks/PromotionBoard01';
import GlobalFooterDisclosure from '../Blocks/GlobalFooterDisclosure.jsx';
import Divider01 from '../Blocks/Divider01.jsx';
import LoadingSkeletonStack from '../../../_GlobalComponents/LoadingSkeletonStack.jsx';

const components = {
  Block1008: Block1008,
  Block1009: Block1009,
  Block1010: Block1010,
  PromotionBoard01: PromotionBoard01,
  Divider01: Divider01,
};
const emailData = {
  email_id: 108924,
  email_name: 'Weekly Email November 08, 2024',
  subject_line: 'This Weeks Specials!',
  audience: ['tony.ocon@sportssouth.biz', 'tony.ocon@oconfamily.net'],
  from: 'tony.ocon@oconfamily.net',
  blocks: [
    {
      block_id: 'PromotionBoard01',
      block_instance_id: 334567,
      imageURL:
        'https://images.unsplash.com/photo-1675868374786-3edd36dddf04?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      sub_header: 'Blowout Deals',
      header: "Don't Miss Out On These Deals!",
      price: 0,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      linkURL: 'https://www.theshootingwarehouse.com/tsw-contact-us',
      buttonText: 'Join Us',
      products: [],
    },
    {
      block_id: 'Divider01',
      block_instance_id: 334567,
    },
    {
      block_id: 'Block1010',
      block_instance_id: 334567,
      sub_header: 'Feature Products',
      header: 'Check-out our feature items!',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      products: [73201, 8037, 13704, 170903],
    },
    {
      block_id: 'Divider01',
      block_instance_id: 334567,
    },
    {
      block_id: 'Block1010',
      block_instance_id: 334567,
      sub_header: 'Close Out Products',
      header: 'Check-out our feature items!',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      products: [73201, 170903, 170904, 169135],
    },

    {
      block_id: 'PromotionBoard01',
      block_instance_id: 334567,
      imageURL:
        'https://images.unsplash.com/photo-1675868374786-3edd36dddf04?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      sub_header: 'Feature Products',
      header: 'Check-out our feature items!',
      price: '1400.00',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      linkURL: 'https://www.theshootingwarehouse.com/tsw-contact-us',
      buttonText: 'Join Us',
      products: [],
    },
    {
      block_id: 'Divider01',
      block_instance_id: 334567,
    },
    {
      block_id: 'Block1010',
      block_instance_id: 334568,
      sub_header: 'Top Selling Products',
      header: 'Check-out these top selling items!',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      products: [170903, 170904, 169135],
    },
  ],
};

const EmailEditor = () => {
  const [loading, setLoading] = useState(true);

  const handleClick = async () => {
    try {
      const response = await axios.post(`/adBuilder/sendEmail`, {
        emailAddress: 'tony.ocon@oconfamily.net, tony.ocon@sportssouth.biz',
        subject: 'Test Ad Builder Email',
        htmlContent: ReactDOMServer.renderToStaticMarkup(
          <Html style={{ margin: 0, padding: 0 }}>
            <Body style={{ margin: 0, padding: 0 }}>
              <Header1000 />
              <Section
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: 16,
                  marginBottom: 16,
                  maxWidth: '600px',
                }}
              >
                <Row>
                  <Column>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: 24,
                        fontWeight: 'bold',
                        padding: '8px 16px',
                      }}
                    >
                      {dayjs().format('MMMM D, YYYY')}
                    </Text>
                  </Column>
                </Row>
                {emailData.blocks.map((block, idx) => {
                  const SpecificComponent = components[block.block_id];
                  return <SpecificComponent key={idx} block={block} />;
                })}
              </Section>
              <Footer1000 />
              <GlobalFooterDisclosure />
            </Body>
          </Html>
        ),
      });

      console.log('email response: ', response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  if (loading) {
    <LoadingSkeletonStack />;
  }
  return (
    <>
      <Button variant='contained' onClick={() => handleClick()}>
        Send Email
      </Button>

      {
        <>
          <Html style={{ margin: 0, padding: 0 }}>
            <Body style={{ margin: 0, padding: 0 }}>
              <Header1000 />
              <Section
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: 16,
                  marginBottom: 16,
                  maxWidth: '600px',
                }}
              >
                <Row>
                  <Column>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: 24,
                        fontWeight: 'bold',
                        padding: '8px 16px',
                      }}
                    >
                      {dayjs().format('MMMM D, YYYY')}
                    </Text>
                  </Column>
                </Row>
                {emailData.blocks.map((block, idx) => {
                  const SpecificComponent = components[block.block_id];
                  return <SpecificComponent key={idx} block={block} />;
                })}
              </Section>
              <Footer1000 />
              <GlobalFooterDisclosure />
            </Body>
          </Html>
        </>
      }
      {/* <TestEditor /> */}
    </>
  );
};

export default EmailEditor;
