import React from 'react';
import { ContractProvider } from '../../../../Context/Contracts/ContractsDashboardContext';
import DashBoardV2 from './DashBoardV2';

const ContractsDashboardWrapper = () => {
  return (
    // <DashBoardV2 />
    <ContractProvider>
      <DashBoardV2 />
    </ContractProvider>
  );
};

export default ContractsDashboardWrapper;
