import axios from 'axios';

export async function getAllPublication() {
  try {
    const response = await axios.get(`/publications/getAllPublications`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllPublicationTypes() {
  try {
    const response = await axios.get(`/publications/getAllPublicationTypes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllPublicationProducts() {
  try {
    const response = await axios.get(`/publications/getAllPublicationProducts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function savePubliationContract(obj) {
  try {
    const response = await axios.post(`/contracts/insertContract`, obj);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function sendContractCreatedEmail(obj) {
  console.log('sendContractCreatedEmail: was called: ', obj);

  try {
    const response = await axios.post(`/email/publication/contract-confirmation`, obj);
    console.log('sendContractCreatedEmail: ', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return error;
  }
}

export const insertPublicationProduct = async (publicationProductDetails = {}) => {
  const data = await axios.post(
    `/publications/insertPublicationProduct`,
    publicationProductDetails
  );
  return data.data;
};

export const insertPublicationProductTypes = async (values = []) => {
  const data = await axios.post(`/publications/insertPublicationProductTypes`, values);
  return data.data;
};

export const updatePublicationProduct = async (updateDetails = {}) => {
  const data = await axios.post(`/publications/updatePublicationProduct`, updateDetails);
  return data.data;
};

export const deletePublicationProductTypes = async (deleteId = {}) => {
  const data = await axios.post(`/publications/deletePublicationProductTypes`, deleteId);
  return data.data;
};

export const insertProductCategories = async (values = {}) => {
  const data = await axios.post(`/publications/insertProductCategories`, values);
  //return data.data;
};

export const deleteProductCategories = async (deleteIds) => {
  console.log('deleteIds: ' + deleteIds);
  const data = await axios.post(`/publications/deleteProductCategories`, deleteIds);
  return data.data;
};

export const updateProductCategories = async (updateValues = {}) => {
  const data = await axios.post(`/publications/updateProductCategories`, updateValues);
  return data.data;
};

export const PublicationProductCategories = async (pubId) => {
  const data = await axios.get(`/publications/PublicationProductCategories/${pubId}`);
  return data.data;
};
