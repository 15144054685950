import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  deletePublicationProductTypes,
  getAllPublicationProducts,
  getAllPublicationTypes,
  updatePublicationProduct,
} from '../../../_GlobalFunctions/publicationFunctions';
import {
  insertPublicationProduct,
  insertPublicationProductTypes,
} from '../../../_GlobalFunctions/publicationFunctions';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const PublicationProducts = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    standard_price: '',
    volume_discount_price: '',
    type: [],
  });
  const [types, setTypes] = useState([]);
  const [publications, setPublications] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState('');
  const [selectedBox, setSelectedBox] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const pubQuery = await getAllPublicationProducts();
      const publicationTypes = await getAllPublicationTypes();
      const activePublications = pubQuery.filter((e) => e.inactive === 0);
      setPublications(activePublications);
      setTypes(publicationTypes);
    };
    fetchData();
  }, [triggerRefresh]);

  const updatedPublications = publications.map((pub) => {
    const typeIds = pub.types ? pub.types.split(',').map((id) => parseInt(id.trim())) : [];
    const typeDetails = typeIds
      .map((id) => types.find((type) => type.ss_publication_type_id === id))
      .filter(Boolean)
      .map((type) => ({
        ss_publication_type_id: type.ss_publication_type_id,
        description: type.description,
      }));
    return {
      ...pub,
      typeDetails: typeDetails.length ? typeDetails : null,
    };
  });

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });

  const usdPrice = {
    type: 'number',
    valueFormatter: (value) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
  };

  const columns = [
    { field: 'id', headerName: 'Id', flex: 0.1 },
    { field: 'publication_code', headerName: 'Publication Code', flex: 0.3 },
    { field: 'description', headerName: 'Description', flex: 0.3 },
    { field: 'detailed_description', headerName: 'Detailed Description', flex: 0.8 },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.3,
    },
    {
      field: 'standard_price',
      headerName: 'Cost',
      ...usdPrice,
    },
  ];

  const rows = updatedPublications.map((pub) => ({
    id: pub.ss_publication_product_id,
    publication_code: pub.ss_publication_product_code,
    description: pub.description,
    detailed_description: pub.detailed_description,
    standard_price: pub.standard_price,
    type: pub.typeDetails ? pub.typeDetails.map((type) => type.description).join(', ') : '',
  }));

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const insertReturn = await insertPublicationProduct(formData);

    const typeData = formData.type.map((type) => ({
      ss_publication_type_id: type,
      ss_publication_product_id: insertReturn.insertId,
    }));

    const typeResults = await insertPublicationProductTypes(typeData);

    console.log(typeResults);

    setFormData({
      ss_publication_product_code: '',
      description: '',
      detailed_description: '',
      standard_price: '',
      volume_discount_price: '',
      inactive: 0,
      type: [],
    });

    setTriggerRefresh(triggerRefresh + 1);
    setRowSelectionModel([]);
    setSelectedBox('');
    setIsChecked(false);
  };

  const handleUpdate = async () => {
    await updatePublicationProduct(formData);
    await deletePublicationProductTypes(formData);

    const typeData = formData.type.map((type) => ({
      ss_publication_type_id: type,
      ss_publication_product_id: formData.ss_publication_product_id,
    }));

    await insertPublicationProductTypes(typeData);

    setFormData({
      ss_publication_product_id: '',
      ss_publication_product_code: '',
      description: '',
      detailed_description: '',
      standard_price: '',
      inactive: 0,
      type: [],
    });

    setTriggerRefresh(triggerRefresh + 1);
    setRowSelectionModel([]);
    setSelectedBox('');
    setIsChecked(false);
  };

  const handleInactive = (e) => {
    const checkValue = e.target.checked;
    setIsChecked(checkValue);
    if (checkValue) {
      setFormData({
        ...formData,
        inactive: 1,
      });
    } else {
      setFormData({
        ...formData,
        inactive: 0,
      });
    }
  };

  const handleRowClick = (e) => {
    const selectedCode = e[0]; // The selected product code
    setSelectedBox(selectedCode);

    if (!selectedCode) {
      setFormData({
        ss_publication_product_id: '',
        ss_publication_type_id: '',
        ss_publication_product_code: '',
        description: '',
        detailed_description: '',
        standard_price: '',
        inactive: 0,
        type: [],
      });
    } else {
      // Find the publication that matches the selected product code
      const selectedPublication = updatedPublications.filter(
        (pub) => pub.ss_publication_product_id === selectedCode
      );

      if (selectedPublication[0].inactive) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }

      if (selectedPublication[0].typeDetails) {
        var typeIds = selectedPublication[0].typeDetails.map((type) => type.ss_publication_type_id);
      }

      if (selectedPublication) {
        setFormData({
          ss_publication_product_id: selectedPublication[0].ss_publication_product_id,
          ss_publication_product_code: selectedPublication[0].ss_publication_product_code,
          description: selectedPublication[0].description,
          detailed_description: selectedPublication[0].detailed_description,
          standard_price: selectedPublication[0].standard_price,
          inactive: selectedPublication[0].inactive,
          type: typeIds ? typeIds : [],
        });
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px',
        }}
      >
        <Box
          sx={{
            marginTop: 6,
            marginBottom: 6,
            borderRadius: '10px',
            padding: 1,
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(12,1fr)' },
            gap: '16px',
            maxWidth: '750px',
            padding: '16px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            border: '1px solid rgba(0, 0, 0, 0.1)', // Light border for definition
          }}
        >
          <Typography
            align='center'
            sx={{
              fontSize: '2em',
              color: theme.palette.primary.main,
              gridColumn: { sm: 'span 12' },
            }}
          >
            Publication Product
          </Typography>
          <Select
            labelId='type-select-label'
            name='type'
            multiple
            value={formData.type || []} // Ensure it's an array
            renderValue={(selected) =>
              selected
                .map((id) => types.find((type) => type.ss_publication_type_id === id)?.description)
                .join(', ')
            }
            onChange={(e) => handleChange(e)}
            fullWidth
            sx={{ gridColumn: { sm: 'span 12' } }}
          >
            {types.map((e, index) => (
              <MenuItem key={index} value={e.ss_publication_type_id}>
                <Checkbox checked={formData.type.includes(e.ss_publication_type_id) || ''} />
                <ListItemText primary={e.description} />
              </MenuItem>
              //could change checkbox to false but causes error
            ))}
          </Select>
          <TextField
            label='Description'
            variant='outlined'
            name='description'
            value={formData.description || ''}
            onChange={handleChange}
            sx={{ gridColumn: { sm: '1/6' } }}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
          <TextField
            label='Publication Code'
            variant='outlined'
            name='ss_publication_product_code'
            value={formData.ss_publication_product_code || ''}
            onChange={handleChange}
            sx={{ gridColumn: { sm: '6/10' } }}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
          <TextField
            label='Cost'
            variant='outlined'
            name='standard_price'
            value={formData.standard_price}
            sx={{ gridColumn: { sm: '10/13' } }}
            onChange={(e) => {
              if (/^\d*\.?\d{0,2}$/.test(e.target.value)) handleChange(e);
            }}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              input: {
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              },
            }}
          />
          <TextField
            multiline
            rows={3}
            label='Detail Description'
            variant='outlined'
            name='detailed_description'
            value={formData.detailed_description || ''}
            onChange={handleChange}
            sx={{ gridColumn: { sm: 'span 12' } }}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
          {!selectedBox && (
            <Box sx={{ textAlign: 'center', gridColumn: { sm: '5/9' } }}>
              <Button
                sx={{ bgcolor: theme.palette.save.main, width: '100%' }}
                variant='contained'
                onClick={handleSubmit}
                disabled={
                  !formData.ss_publication_product_code ||
                  !formData.description ||
                  !formData.detailed_description ||
                  !formData.standard_price
                }
              >
                Submit
              </Button>
            </Box>
          )}
          {selectedBox && (
            <Box sx={{ textAlign: 'center', gridColumn: { sm: '5/9' } }}>
              <Button
                sx={{ bgcolor: theme.palette.save.main, width: '100%' }}
                variant='contained'
                onClick={handleUpdate}
                disabled={
                  !formData.ss_publication_product_code ||
                  !formData.description ||
                  !formData.detailed_description ||
                  !formData.standard_price
                }
              >
                Update
              </Button>
            </Box>
          )}
          <FormControlLabel
            sx={{ gridColumn: { sm: '10/12' } }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleInactive}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Delete' // Replace with your desired label text
          />
        </Box>
        <DataGrid
          checkboxSelection
          disableMultipleRowSelection
          rows={rows}
          columns={columns}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(e) => {
            setRowSelectionModel(e);
            handleRowClick(e);
          }}
          pagination
          sx={{
            border: 0,
            gridColumn: { sm: 'span 12' },
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            maxHeight: '850px',
            width: '1600px',
          }}
          slots={{ toolbar: GridToolbar }}
          sortingOrder={['asc', 'desc']}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        />
      </Box>
    </>
  );
};

export default PublicationProducts;
