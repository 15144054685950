import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
//import dayjs from 'dayjs';
import { useContractContext } from '../../../Context/Contracts/ContractsDashboardContext';

const BillingStatus = ({ contracts }) => {
  // CONTEXT STATE
  const { setBillingStatusId, setBillingStatusItemsIds } = useContractContext();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //const [fullContractPublicationData, setFullContractPublicationData] = useState([]);
  const [billingStatusItems, setBillingStatusItems] = useState([]);
  const [hasRun, setHasRun] = useState(false);

  const runFunctions = async () => {
    const overdueFilter = contracts.filter(
      (e) => e.ss_publication_payment_status_id === 1 && e.days_until_due < 0
    );

    const overdueAmount = overdueFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    const dueSoonFilter = contracts.filter(
      (e) =>
        e.ss_publication_payment_status_id === 1 && e.days_until_due <= 30 && e.days_until_due >= 0
    );
    const dueSoonAmount = dueSoonFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    const totalOutstandingFilter = contracts.filter(
      (e) => e.ss_publication_payment_status_id === 1 && e.contract_deleted !== 1
    );
    const totalOutstandingAmount = totalOutstandingFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    // Combine results and set   them at once

    setData([
      { id: 1, label: 'Overdue', amount: overdueAmount },
      { id: 2, label: 'Due Soon', amount: dueSoonAmount },
      { id: 3, label: 'Total Outstanding', amount: totalOutstandingAmount },
    ]);

    setBillingStatusItems([
      { id: 0, items: [contracts.map((e) => e.ss_publication_contract_id)] },
      { id: 1, items: [overdueFilter.map((e) => e.ss_publication_contract_id)] },
      { id: 2, items: [dueSoonFilter.map((e) => e.ss_publication_contract_id)] },
      { id: 3, items: [totalOutstandingFilter.map((e) => e.ss_publication_contract_id)] },
    ]);
  };

  // FUNCTIONS
  const handleClick = (id) => {
    setBillingStatusId(id); // Context Variable
    setBillingStatusItemsIds(billingStatusItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (hasRun) return; // Prevent multiple runs
      setHasRun(true); // Mark as run
      const res = await contracts;
      res.length > 0 && setLoading(false);
      runFunctions();
    };
    // const fetchData = async () => {
    //   const res = await contracts;
    //   setFullContractPublicationData(res);
    //   runFunctions();
    // };
    contracts && contracts.length > 0 && fetchData();
  }, [contracts, hasRun]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        paddingY: '40px',
        background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          mx: 'auto',
          maxWidth: '1600px',
        }}
      >
        {data.map((bill, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box sx={{ color: '#bce2ff', fontSize: '1.1em', textTransform: 'uppercase' }}>
              {bill.label}
            </Box>
            <Box sx={{ color: 'white', fontSize: '3em' }}>${bill.amount}</Box>
            <Box>
              <Button
                disabled={parseFloat(bill.amount) === 0}
                onClick={() => handleClick(bill.id)}
                sx={{
                  fontSize: '1.1em',
                  backgroundColor: 'rgba(0,0,0,.2)',
                }}
                variant='contained'
                endIcon={
                  <ArrowForwardIos
                    sx={{
                      width: '32px',
                      height: '32px',
                      padding: '8px',
                      backgroundColor: 'rgba(0,0,0,.2)',
                      borderRadius: '50%',
                    }}
                  />
                }
              >
                View All
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BillingStatus;
