import React, { useContext, useEffect, useState, useRef } from 'react';

import { EntityContextState } from '../../../../../Context/Entity/EntityContext';
import axios from 'axios';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import SnackBarAlert from '../../../../_GlobalComponents/SnackBarAlert';
import ContainerAlpha from '../../ContainerAlpha';
import ContainerTitle from '../../ContainerTitle';
import LoadingSkeletonStack from '../../../../_GlobalComponents/LoadingSkeletonStack';

const EntityInfoForm = () => {
  // LOCAL STATE DATA
  const [parentEntities, setParentEntities] = useState([]);
  const [productManufacturers, setProductManufacturers] = useState([]);
  const [hasProductChecked, setHasProductChecked] = useState(1);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [entityTypeIdState, setEntityTypeIdState] = useState(0);
  const [entityTypesList, setEntityTypesList] = useState([]);

  const workingData = useRef({});

  // CONTEXT DATA
  const { selectedEntityId, updateTriggerRefreshEntityData, updateSelectedEntityTypeId } =
    useContext(EntityContextState);

  const handleHasProductChecked = (e) => {
    const checkValue = e.target.checked;
    setHasProductChecked(checkValue);
    workingData.current.has_products = checkValue;
  };

  // FUNCTION TO CREATE PROFILE DATA
  const handleAddSubmit = () => {
    try {
      const response = axios.post('/entity/addEntityRecord', {
        entity_add_data: workingData.current,
      });
      console.log('new entity response: ', response.code);
      console.log('on submit workingData: ', workingData.current);
      setSnackbarOpen(true);
      updateTriggerRefreshEntityData();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // FUNCTION TO UPDATE PROFILE DATA
  const handleUpdateSubmit = () => {
    try {
      const response = axios.post('/entity/updateEntity', {
        entity_update_data: workingData,
      });
      setSnackbarOpen(true);
      updateTriggerRefreshEntityData();
      console.log('update result: ', response);
    } catch (error) {
      console.error('Error:', error);
    }
    console.log('info that will be udpated: ', workingData.current);
  };

  const ResetOpen = () => {
    setSnackbarOpen(false);
  };

  // GET ALL ENTITY TYPES
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getAllEntityTypes`);
        setEntityTypesList([...response.data]);
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getEntityById/${selectedEntityId}`);
        //setData({ ...response.data[0] });
        workingData.current = { ...response.data[0] };
        setEntityTypeIdState(response.data[0].entity_type_id);
        setHasProductChecked(response.data[0].has_products);
        updateSelectedEntityTypeId(response.data[0].entity_type_id);
        console.log('workingData.current: ', workingData.current);
        //setLoaded(true);
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };

    selectedEntityId > 0 ? fetchData() : (workingData.current.has_products = 1);
  }, [selectedEntityId]);

  // FETCH TIMEZONE DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getTimeZones`);
        setTimeZoneList([...response.data]);
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };

    fetchData();
  }, []);

  // FETCH STATE DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getStates`);
        setStateList([...response.data]);
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };
    fetchData();
  }, []);

  // FETCH ENTITY DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/entity/getAllEntities`);
        setParentEntities([...response.data]);
        setLoaded(true);
      } catch (error) {
        console.log('fetchContacts error: ', error);
      }
    };

    fetchData();
  }, []);

  // FETCH PRODUCT_MANUFACTURER DATA
  // useEffect(() => {
  // 	const fetchData = async () => {
  // 		try {
  // 			const response = await axios.get(`/entity/getAllProductManufacturers`);
  // 			//setProductManufacturers([...response.data]);
  // 			setLoaded(true);
  // 		} catch (error) {
  // 			console.log('fetchContacts error: ', error);
  // 		}
  // 	};
  // 	fetchData();
  // }, []);

  const Content = () => {
    return (
      <>
        {loaded && (
          <ContainerAlpha>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
            >
              <ContainerTitle title='Entity Info' />
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(12,1fr)',
                gap: 3,
              }}
            >
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label='Company Name'
                  defaultValue={workingData.current.business_name}
                  onChange={(e) =>
                    (workingData.current = {
                      ...workingData.current,
                      business_name: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>

              <Box sx={{ gridColumn: 'span 2' }}>
                <TextField
                  fullWidth
                  select
                  label='Entity Type'
                  defaultValue={workingData.current.entity_type_id}
                  onChange={(e) => {
                    workingData.current = {
                      ...workingData.current,
                      entity_type_id: e.target.value,
                    };
                    setEntityTypeIdState(e.target.value);
                    updateSelectedEntityTypeId(e.target.value);
                    console.log('what is type: ' + e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {entityTypesList.map((t) => (
                    <MenuItem key={t.entity_type_id} value={t.entity_type_id}>
                      {t.description}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={3}>AS400 Manufacturer</MenuItem>
									<MenuItem value={1}>Manufacturer Rep Groups</MenuItem>
									<MenuItem value={2}>Sub Manufacturer</MenuItem> */}
                </TextField>
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  disabled={entityTypeIdState === 1 || entityTypeIdState === 5}
                  select
                  label='Parent Manufacturer'
                  defaultValue={workingData.current.parent_entity_id}
                  onChange={(e) =>
                    (workingData.current = {
                      ...workingData.current,
                      parent_entity_id: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value={null}>Unknown Parent</MenuItem>
                  {parentEntities.map(
                    (e, i) =>
                      e.business_name !== '' &&
                      (entityTypeIdState === 3 ? (
                        e.entity_type_id === 5 && (
                          <MenuItem key={i + 1} value={e.entity_id}>
                            {e.business_name}
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem key={i + 1} value={e.entity_id}>
                          {e.business_name}
                        </MenuItem>
                      ))
                  )}
                </TextField>
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <TextField
                  fullWidth
                  disabled={entityTypeIdState !== 3}
                  label='AS-400 ID'
                  defaultValue={workingData.current.reference_number}
                  onChange={(e) =>
                    (workingData.current = {
                      ...workingData.current,
                      reference_number: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 2', textAlign: 'center' }}>
                <FormControlLabel
                  control={<Checkbox checked={hasProductChecked} />}
                  label='Has Product?'
                  onChange={(e) => handleHasProductChecked(e)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 6' }}>
                <TextField
                  fullWidth
                  label='Street'
                  defaultValue={workingData.current.address_1}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, address_1: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 6' }}>
                <TextField
                  fullWidth
                  label='Address 2'
                  defaultValue={workingData.current.address_2}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, address_2: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 6' }}>
                <TextField
                  fullWidth
                  label='City'
                  defaultValue={workingData.current.city}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, city: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  select
                  label='State'
                  defaultValue={workingData.current.state_id}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, state_id: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem key={0} value={0}>
                    Unknown
                  </MenuItem>
                  {stateList.map((e, i) => (
                    <MenuItem key={i + 1} value={e.state_id}>
                      {e.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label='Zip Code'
                  defaultValue={workingData.current.postal_code}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, postal_code: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label='Latitude'
                  defaultValue={workingData.current.latitude}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, latitude: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label='Longitude'
                  defaultValue={workingData.current.longitude}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, longitude: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  select
                  label='Time Zone'
                  defaultValue={workingData.current.timezone_id}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, timezone_id: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem key={0} value={249}>
                    Unknown
                  </MenuItem>
                  {timeZoneList.map((e, i) => (
                    <MenuItem key={i + 1} value={e.timezone_id}>
                      {e.timezone_identifier}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label='Website'
                  defaultValue={workingData.current.website}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, website: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 12' }}>
                <TextField
                  fullWidth
                  id='outlined-controlled'
                  label='About Text'
                  defaultValue={workingData.current.about_text}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, about_text: e.target.value })
                  }
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 12' }}>
                <TextField
                  fullWidth
                  id='outlined-controlled'
                  label='Notes'
                  defaultValue={workingData.current.notes}
                  onChange={(e) =>
                    (workingData.current = { ...workingData.current, notes: e.target.value })
                  }
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 12', textAlign: 'right' }}>
                {selectedEntityId > 0 ? (
                  <Button variant='contained' color='success' onClick={() => handleUpdateSubmit()}>
                    Update Entity
                  </Button>
                ) : (
                  <Button variant='contained' color='success' onClick={() => handleAddSubmit()}>
                    Add Entity
                  </Button>
                )}
              </Box>
            </Box>
          </ContainerAlpha>
        )}
      </>
    );
  };

  return (
    <>
      {loaded ? <Content /> : <LoadingSkeletonStack />}
      <SnackBarAlert doOpen={snackbarOpen} ResetOpen={ResetOpen} message='Update Data Saved!' />
    </>
  );
};

export default EntityInfoForm;
