import { MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextV2State } from '../../../../Context/Marketing/PublicationContextV2';
const FilterTableByPubType = ({ func }) => {
  // CONTEXT STATE
  const { allPublicationTypes } = useContext(PublicationContextV2State);

  // LOCAL STATE
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [id, setId] = useState(0);

  const fetch = async () => {
    const res = await allPublicationTypes;
    setData(res);
    setPageIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  if (pageIsLoading) {
    return null;
  }

  return (
    <>
      <TextField
        sx={{ width: '170px' }}
        select
        label='Filter By Type'
        value={id}
        onChange={(e) => (setId(e.target.value), func(e.target.value))}
      >
        {[{ ss_publication_type_id: 0, description: 'All Pub Typies' }, ...data].map((e, i) => (
          <MenuItem key={i} value={e.ss_publication_type_id}>
            {e.description}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default FilterTableByPubType;
