import { Box, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getAllContractsData } from '../../_GlobalFunctions/contractFunctions';
import { MarketingContextState } from '../../../Context/Marketing/MarketingContext';

import { ArrowForwardIos } from '@mui/icons-material';
import LoadingSkeletonStack from '../../_GlobalComponents/LoadingSkeletonStack';

const OverviewSection = () => {
  // CONTEXT STATE
  const { setContractDueTypeId, refreshContractData } = useContext(MarketingContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  // FUNCTIONS
  const handleClick = (id) => {
    setContractDueTypeId(id);
  };

  const fetch = async () => {
    const resContracts = await getAllContractsData();
    runFunctions(resContracts);
  };

  const runFunctions = async (contracts) => {
    const overdueFilter = contracts.filter(
      (e) =>
        e.ss_publication_payment_status_id === 1 && e.days_until_due < 0 && e.contract_deleted !== 1
    );

    const overdueAmount = overdueFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    const dueSoonFilter = contracts.filter(
      (e) =>
        e.ss_publication_payment_status_id === 1 && e.days_until_due <= 30 && e.days_until_due >= 0
    );
    const dueSoonAmount = dueSoonFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    const totalOutstandingFilter = contracts.filter(
      (e) => e.ss_publication_payment_status_id === 1 && e.contract_deleted !== 1
    );
    const totalOutstandingAmount = totalOutstandingFilter
      .map((item) => parseFloat(item.sold_price))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2);

    setData([
      { id: 1, label: 'Overdue', amount: overdueAmount },
      { id: 2, label: 'Due Soon', amount: dueSoonAmount },
      { id: 3, label: 'Total Outstanding', amount: totalOutstandingAmount },
    ]);

    setLoading(false);
  };

  // USE EFFECTS
  useEffect(() => {
    fetch();
  }, [refreshContractData]);

  if (loading) {
    return <LoadingSkeletonStack />;
  }

  return (
    <Box
      sx={{
        paddingY: '40px',
        background: 'linear-gradient(270deg, rgba(0,78,137,1) 0%, rgba(0,50,90,1) 100%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          mx: 'auto',
          maxWidth: '1600px',
        }}
      >
        {data.map((bill, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box sx={{ color: '#bce2ff', fontSize: '1.1em', textTransform: 'uppercase' }}>
              {bill.label}
            </Box>
            <Box sx={{ color: 'white', fontSize: '3em' }}>${bill.amount}</Box>
            <Box>
              <Button
                disabled={parseFloat(bill.amount) === 0}
                onClick={() => handleClick(bill.id)}
                sx={{
                  fontSize: '1.1em',
                  backgroundColor: 'rgba(0,0,0,.2)',
                }}
                variant='contained'
                endIcon={
                  <ArrowForwardIos
                    sx={{
                      width: '32px',
                      height: '32px',
                      padding: '8px',
                      backgroundColor: 'rgba(0,0,0,.2)',
                      borderRadius: '50%',
                    }}
                  />
                }
              >
                View All
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OverviewSection;
