import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server.browser';
import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Preview,
  Section,
  Text,
} from '@react-email/components';
import {
  AppBar,
  Box,
  Typography,
  Button,
  Container as MUIContainer,
  useTheme,
} from '@mui/material';
import axios from 'axios';

const ContractCreatedEmail = ({
  previewText = 'Default preview text',
  headerText = 'Company Name',
  heroTitle = 'Welcome!',
  heroDescription = 'Thank you for joining us.',
  mainContent = 'This is the main content section.',
  footerText = '© 2025 Company Name. All rights reserved.',
}) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);

  // Style constants
  const styles = {
    main: {
      backgroundColor: '#ffffff',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    },
    container: {
      margin: '0 auto',
      padding: '20px 0 48px',
    },
    header: {
      backgroundColor: theme.palette.accent.main,
      padding: '20px 0',
      marginBottom: '24px',
      color: 'white',
    },
    hero: {
      padding: '32px 0',
      textAlign: 'center',
      backgroundColor: '#f6f9fc',
    },
    content: {
      padding: '32px 0',
    },
    footer: {
      color: '#8898aa',
      fontSize: '12px',
      marginTop: '24px',
      textAlign: 'center',
    },
  };

  // Create the email component
  const EmailComponent = () => (
    <Html>
      <Head />
      <Preview>{previewText}</Preview>
      <Body style={styles.main}>
        <Section style={styles.header}>
          <Container>
            <Text style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>
              {headerText}
            </Text>
          </Container>
        </Section>

        <Section style={styles.hero}>
          <Container>
            <Heading style={{ fontSize: '32px', fontWeight: 'bold', textAlign: 'center' }}>
              {heroTitle}
            </Heading>
            <Text style={{ fontSize: '16px', textAlign: 'center', margin: '16px 0' }}>
              {heroDescription}
            </Text>
          </Container>
        </Section>

        <Container style={styles.container}>
          <Section style={styles.content}>
            <Text>{mainContent}</Text>
          </Section>

          <Text style={styles.footer}>{footerText}</Text>
        </Container>
      </Body>
    </Html>
  );

  // FUNCTION
  const sendMail = async () => {
    try {
      const htmlContent = ReactDOMServer.renderToStaticMarkup(<EmailComponent />);
      const response = await axios.post(`/adBuilder/sendEmail`, {
        emailAddress: 'tony.ocon@oconfamily.net, tony.ocon@sportssouth.biz',
        subject: 'Test Ad Builder Email',
        htmlContent,
      });

      console.log('email response: ', response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  // USE EFFECT
  useEffect(() => {
    //sendMail();
    setLoading(false);
  }, []);

  if (loading) {
    return <div>... loading</div>;
  }

  // Preview component using MUI
  return (
    <MUIContainer maxWidth='md'>
      <AppBar position='static' sx={{ mb: 2 }}>
        <Box p={2}>
          <Typography variant='h6'>{headerText}</Typography>
        </Box>
      </AppBar>

      <Box sx={{ bgcolor: 'grey.100', py: 4, textAlign: 'center', mb: 2 }}>
        <Typography variant='h3' gutterBottom>
          {heroTitle}
        </Typography>
        <Typography variant='subtitle1'>{heroDescription}</Typography>
      </Box>

      <Box sx={{ py: 4 }}>
        <Typography variant='body1'>{mainContent}</Typography>
      </Box>

      <Box sx={{ bgcolor: 'grey.100', p: 2, textAlign: 'center', mt: 2 }}>
        <Typography variant='body2' color='text.secondary'>
          {footerText}
        </Typography>
      </Box>

      {/* Output raw HTML */}
      <Box sx={{ mt: 4, p: 2, bgcolor: 'grey.100' }}>
        <Typography variant='h6' gutterBottom>
          Generated Email HTML:
        </Typography>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          <EmailComponent />
        </pre>
      </Box>
    </MUIContainer>
  );
};

export default ContractCreatedEmail;
