import { Grid2 } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { MediaKitContextState } from '../MediaKitContext';
import {
  getAllPublication,
  getAllPublicationTypes,
  getAllPublicationProducts,
} from '../../../../_GlobalFunctions/publicationFunctions';

import PublicationCard from './PublicationCard';

import { useSelector } from 'react-redux';
import PublicationCardV3 from './PublicationCardV3';
import LoadingSkeletonStack from '../../../../_GlobalComponents/LoadingSkeletonStack';

const PublicationList = ({ publications }) => {
  // REDUX SELECTOR
  const contactId = useSelector((state) => state.userInformation.value.contactID);

  // CONTEXT STATE
  const { year, month, type } = useContext(MediaKitContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [pubData, setPubData] = useState([]);
  const [pubProducts, setPubProducts] = useState([]);

  // USE EFFECT
  useEffect(() => {
    const proxyMonth = month === 0 ? 1 : month;
    const startMonth = proxyMonth < 10 ? `0${proxyMonth}` : proxyMonth;
    const nextMonth =
      proxyMonth === 12 ? `01` : proxyMonth + 1 < 10 ? `0${proxyMonth + 1}` : proxyMonth + 1;
    const startDate = `${year}-${startMonth}-00`;
    const endDate =
      proxyMonth === 12 || month === 0 ? `${year + 1}-01-01` : `${year}-${nextMonth}-01`;

    const getPubReturn = async () => {
      let pubReturn = await getAllPublication();

      if (type !== 0) {
        pubReturn = pubReturn.filter((e) => e.ss_publication_type_id === type);
      }

      pubReturn = await pubReturn.filter(
        (pub) => pub.start_date > startDate && pub.start_date < endDate
      );
      const pubTypes = await getAllPublicationTypes();
      const pubProductsArray = await getAllPublicationProducts();
      setPubProducts(pubProductsArray);

      const newArray = pubReturn.map((pub) => {
        const match = pubTypes.find(
          (pubtype) => pubtype.ss_publication_type_id === pub.ss_publication_type_id
        );
        return { ...pub, publication_type: match ? match.description : 'unknown' };
      });

      setPubData(newArray);
    };
    getPubReturn();

    setLoading(false);
  }, [year, month, type]);

  // LOADING FUNCTION
  if (loading) {
    return <LoadingSkeletonStack />;
  }

  return (
    <>
      <Grid2
        container
        spacing={3}
        sx={{ height: '100%', overflowY: 'auto', px: 1, pb: 4 }}
        columns={{ xs: 1, sm: 2, lg: 3, xl: 3 }}
      >
        {pubData.map((pub, idx) => (
          <PublicationCardV3 key={idx} item={pub} pubProducts={pubProducts} />
        ))}
      </Grid2>

      {/* <Grid2
        container
        spacing={3}
        sx={{ height: '100%', overflowY: 'auto', px: 1, pb: 4 }}
        columns={{ xs: 1, sm: 2, lg: 3, xl: 3 }}
      >
        {pubData.map((pub, idx) => (
          <PublicationCard key={idx} item={pub} pubProducts={pubProducts} />
        ))}
      </Grid2> */}
    </>
  );
};

export default PublicationList;
