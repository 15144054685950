import { Autocomplete, Button, Checkbox, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function FacetOptionReplaceOptions( props ){

    const catVal = props.categories
    const facVal = props.facetList
    const facOptVal = props.facetOptionList
    const newFacOptVal = props.newFacetOptionList

    const [optionValue, setOptionValue] = useState('')
    const [confirmReady, setConfirmReady] = useState(false)

    const [filterOption, setFilterOption] = useState(false)

    const [selectedFacet, setSelectedFacet] = useState(null);
    const [selectedFacetOption, setSelectedFacetOption] = useState(null)
    const [selectedNewFacet, setSelectedNewFacet] = useState(null)
    const [selectedNewFacetOption, setSelectedNewFacetOption] = useState(null);

    const [categoryUnselected, setCategoryUnselected] = useState(true)
    const [typeUnselected, setTypeUnselected] = useState(true)
    const [facetUnselected, setFacetUnselected] = useState(true)
    const [facetOptionUnselected, setFacetOptionUnselected] = useState(true)
    const [newFacetUnselected, setNewFacetUnselected] = useState(true)
    const [newFacetOptionUnselected, setNewFacetOptionUnselected] = useState(true)

    useEffect(() => {
        props.setOptionsConfirm({
                                    categorySelected: !categoryUnselected, 
                                    typeSelected: !typeUnselected,
                                    facetSelected: !facetUnselected,
                                    facetOptionSelected: !facetOptionUnselected,
                                    newFacetSelected: !newFacetUnselected,
                                    newFacetOptionSelected: !newFacetOptionUnselected
                                })
    }, [confirmReady])

    const changeTypeButton = (type) => {
        setOptionValue(type)
        setTypeUnselected(false)
        setFacetUnselected(true) 
        setSelectedFacet(null)
        setFacetOptionUnselected(true)
        setSelectedFacetOption(null)
        setSelectedNewFacet(null)
        setNewFacetUnselected(true)
        setSelectedNewFacetOption(null)
        setNewFacetOptionUnselected(true)
        props.setValues('type', type)   
        setConfirmReady(!confirmReady)
        props.tableReset()         
    }

    const handleChange = async (type, id) => {
        //console.log('handleChange')
        if(id !== null){
            props.setValues(type, id)
            switch(type){
                case 'category':
                    setCategoryUnselected(false)
                    setSelectedFacet(null)
                    setSelectedNewFacet(null)
                    setSelectedFacetOption(null)
                    setSelectedNewFacetOption(null)
                    break
                case 'facet':
                    setFacetUnselected(false)
                    setSelectedFacet(id)
                    setSelectedNewFacet(id)
                    setNewFacetUnselected(false)
                    setSelectedFacetOption(null)
                    break
                case 'facetOption':
                    setFacetOptionUnselected(false)
                    setSelectedFacetOption(id)
                    break
                case 'newFacet':
                    setNewFacetUnselected(false)
                    setSelectedNewFacet(id)
                    setSelectedNewFacetOption(null)
                    break
                case 'newFacetOption':
                    setNewFacetOptionUnselected(false)
                    setSelectedNewFacetOption(id)
                    break
                default:
                    break    
            }
        }else{
            props.setValues(type, 'reset')
            switch(type){
                case 'category':
                    setCategoryUnselected(true)
                    setFacetUnselected(true)
                    setNewFacetUnselected(true)
                    setFacetOptionUnselected(true)
                    setNewFacetOptionUnselected(true)
                    setSelectedFacet(null)
                    setSelectedNewFacet(null)
                    setSelectedFacetOption(null)
                    setSelectedNewFacetOption(null)
                    break
                case 'facet':
                    setFacetUnselected(true) 
                    setSelectedFacet(null)
                    setFacetOptionUnselected(true)
                    setSelectedFacetOption(null)
                    if(type !== 'add'){
                        setSelectedNewFacet(null)
                        setNewFacetUnselected(true)
                        setSelectedNewFacetOption(null)
                        setNewFacetOptionUnselected(true)
                    }
                    break
                case 'facetOption':
                    setFacetOptionUnselected(true)
                    setSelectedFacetOption(null)
                    break
                case 'newFacet':
                    setNewFacetOptionUnselected(true)
                    setSelectedNewFacetOption(null)
                    setNewFacetUnselected(true)
                    setSelectedNewFacet(null)
                    
                    break
                case 'newFacetOption':
                    setNewFacetOptionUnselected(true)
                    setSelectedNewFacetOption(null)
                    break
                default:
            }
        }
        setConfirmReady(!confirmReady)
        props.tableReset()
    }

    const handlePreventFilter = ( event ) => {
        setFilterOption(event.target.checked)
        props.filterItems(event.target.checked)
    }

    return(
        <div style={{width:'33%', marginLeft:'5%', paddingLeft:'5%', alignSelf:'center', alignContent:'center', alignItems:'center', textAlign:'center'}}>
            <Stack direction={'row'} textAlign={'center'} alignContent={'center'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} width={'200'} paddingTop={4} spacing={1}>
                <Autocomplete  
                    sx={{minWidth: 250, paddingTop:1, paddingRight:'10'}}              
                    getOptionKey={(cat) => cat.product_category_id}
                    getOptionLabel={(cat) => cat.description}
                    options={catVal}
                    renderInput={(params) => <TextField {...params} label="Category"/>}
                    onChange={(e, val) => handleChange('category', val)}
                />
            
                <Stack spacing={1} >
                    <Button sx={{height:25}} variant={(optionValue === 'replace' ? 'contained' : 'outlined')} onClick={() => changeTypeButton('replace')}>Replace</Button>
                    <Button sx={{height:25}} variant={(optionValue === 'add' ? 'contained' : 'outlined')} onClick={() => changeTypeButton('add')}>Add</Button>
                    <Button sx={{height:25}} variant={(optionValue === 'delete' ? 'contained' : 'outlined')} onClick={() => changeTypeButton('delete')}>Delete</Button>
                </Stack>
            </Stack>
            <div style={{paddingRight:10}}>
            {optionValue === 'add' ?
                <Stack spacing={0} alignContent={'center'} alignItems={'center'} direction={'row'} paddingTop={2}>
                    <Checkbox 
                        checked={filterOption} 
                        onChange={handlePreventFilter}
                        
                    /> 
                    <Typography marginLeft={-1} marginTop={-1} width={150}>Don't Filter by Facet</Typography>
                                
                </Stack>
                : 

                ''
            }
            {optionValue !== '' ? <div>
            <Autocomplete
                sx={{minWidth: 250, paddingTop:1, paddingLeft:'5%'}}                
                getOptionKey={(cat) => (cat.product_facet_id ? cat.product_facet_id : "")}
                getOptionLabel={(cat) => (cat.description ? cat.description : "")}
                disabled={categoryUnselected || typeUnselected}
                value={selectedFacet}
                options={facVal}
                renderInput={(params) => <TextField {...params} label="Facet"/>}
                onChange={(e, val) => handleChange('facet', val)}
            />
            <Autocomplete
                sx={{minWidth: 250, paddingTop:1, paddingLeft:'5%'}}                
                getOptionKey={(cat) => (cat.product_facet_option_id ? cat.product_facet_option_id : "")}
                getOptionLabel={(cat) => (cat.facet_value ? cat.facet_value : "")}
                options={facOptVal}
                value={selectedFacetOption}
                disabled={facetUnselected}
                renderInput={(params) => <TextField {...params} label="Facet Option"/>}
                onChange={(e, val) => handleChange('facetOption', val)}
            /> 
            </div>: ''}
            </div>
            {optionValue === 'replace' || optionValue=== 'add' ? 
            <div>
                <Autocomplete
                    sx={{minWidth: 250, paddingTop:1, paddingLeft:'5%'}}                 
                    getOptionKey={(cat) => (cat.product_facet_id ? cat.product_facet_id : "")}
                    getOptionLabel={(cat) => (cat.description ? cat.description : "")}
                    value={selectedNewFacet}
                    disabled={categoryUnselected || typeUnselected || optionValue !== 'add'}
                    options={facVal}
                    renderInput={(params) => <TextField {...params} label="New Facet"/>}
                    onChange={(e, val) => handleChange('newFacet', val)}
                />
                <Autocomplete
                    sx={{minWidth: 250, paddingTop:1, paddingLeft:'5%'}}                 
                    getOptionKey={(cat) => (cat.product_facet_option_id ? cat.product_facet_option_id : "")}
                    getOptionLabel={(cat) => (cat.facet_value ? cat.facet_value : "")}
                    options={newFacOptVal}
                    value={selectedNewFacetOption}
                    disabled={newFacetUnselected}
                    renderInput={(params) => <TextField {...params} label="New Facet Option"/>}
                    onChange={(e, val) => handleChange('newFacetOption', val)}
                />
            </div>
            : ''}
            </Stack>
        </div>
    )
}