import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  useTheme,
} from '@mui/material';

import {
  insertCreditDeposit,
  getEntityDepositMethods,
  getEntityDepositTypes,
  getAllEntities,
  getEntitiesIdByUserId,
} from '../../../../_GlobalFunctions/accountingFunctions';

import { UploadFile } from '../../../../_GlobalFunctions/UploadFile';
import { useContractContext } from '../../../../../Context/Contracts/ContractsDashboardContext';

const approvedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

const MakeDeposit = ({ userId }) => {
  // INIT's
  const theme = useTheme();

  const [formData, setFormData] = useState({
    user_id: userId,
    deposit_amount: '',
    entity_deposit_method_id: 1,
  });
  const [methods, setMethods] = useState([]);
  const [entities, setEntities] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [types, setTypes] = useState([]);
  const [fileSelectedMessage, setFileSelectedMessage] = useState({ status: 0, name: '' });
  const [file, setFile] = useState([]);
  const [creditMemoIdValue, setCreditMemoIdValue] = useState('');
  const [uploadedFileMess, setUploadedFileMess] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const getMethods = await getEntityDepositMethods();
      const getTypes = await getEntityDepositTypes();
      const getEntities = await getAllEntities();
      const getBrands = await getEntitiesIdByUserId(userId);
      console.log('getEntities: ', getEntities);
      console.log('getBrands: ', getBrands, ' | ', userId);
      setMethods(getMethods);
      setTypes(getTypes);
      setEntities(getEntities);
      setVendors(getEntities.filter((e) => getBrands.includes(e.entity_id)));
    };
    fetchData();
  }, []);

  // FUNCTIONS
  const handleFilesChange = (e) => {
    const file = e.target.files[0]; // Get the selected file

    if (file && approvedTypes.includes(file.type)) {
      console.log('Selected File Details:', {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: new Date(file.lastModified).toLocaleString(),
      });
      setFile(file);
      setFileSelectedMessage({ status: 1, name: file.name });
    } else {
      setFileSelectedMessage({
        status: 0,
        name: 'Something went wrong, maybe check that file type is PDF, JPG,or PNG',
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const insertRes = await insertCreditDeposit(formData);
    console.log('insertRes: ', insertRes);

    const res = await UploadFile(
      file,
      null,
      insertRes.insertId,
      formData.description,
      'PUBLICATION_CREDIT_MEMOS',
      'CONTRACT_CREDIT_MEMO'
    );

    setUploadedFileMess(res.message);
    setFormData({
      deposit_amount: '',
      description: '',
      entity_deposit_method_id: '',
      entity_deposit_type_id: '',
      entity_id: '',
      notes: '',
      user_id: userId,
    });

    console.log('res: ', res);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          marginX: 'auto',
          // marginTop: 6,
          // marginBottom: 6,
          borderRadius: '10px',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: '16px',
          padding: '16px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
          border: '1px solid rgba(0, 0, 0, 0.1)', // Light border for definition
        }}
      >
        <Typography
          align='center'
          sx={{
            fontSize: '2em',
            color: theme.palette.primary.main,
            gridColumn: { sm: 'span 2' },
          }}
        >
          Manage Marketing Funds
        </Typography>
        <TextField
          label='Vendors'
          name='entity_id' // Match the key in formData
          value={formData.entity_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {vendors.map((e, index) => (
            <MenuItem key={index} value={e.entity_id}>
              {e.business_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Deposit Type'
          id='entity_deposit_type_id'
          name='entity_deposit_type_id' // Match the key in formData
          value={formData.entity_deposit_type_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {types.map((e, index) => (
            <MenuItem key={index} value={e.entity_deposit_type_id}>
              {e.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Deposit Method'
          id='deposit_method'
          name='entity_deposit_method_id' // Match the key in formData
          value={formData.entity_deposit_method_id || ''} // Default to empty if undefined
          onChange={handleChange}
          fullWidth
          select
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {methods
            .filter((method) => method.inactive !== 1)
            .map((e, index) => (
              <MenuItem key={index} value={e.entity_deposit_method_id}>
                {e.description}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          label='Deposit Amount'
          variant='outlined'
          name='deposit_amount'
          value={formData.deposit_amount}
          onChange={(e) => {
            if (/^\d*\.?\d{0,2}$/.test(e.target.value)) handleChange(e);
          }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            },
          }}
        />

        {/* <TextField
          label={formData.entity_deposit_method_id === 1 ? 'Credit Memo Id' : 'Description'}
          disabled={!formData.entity_deposit_method_id}
          variant='outlined'
          name='description'
          value={formData.description || ''}
          onChange={handleChange}
          sx={{ gridColumn: { sm: 'span 2' } }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        /> */}

        {/* Credit Memo ID Field */}
        <TextField
          sx={{ gridColumn: 'span 2' }}
          label={formData.entity_deposit_method_id === 1 ? 'Credit Memo Id' : 'Promissory Id'}
          name='description'
          value={formData.description || ''}
          //onChange={(e) => setCreditMemoIdValue(e.target.value)}
          onChange={handleChange}
          variant='outlined'
          fullWidth
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />

        {/* File Selection Button */}
        {formData.entity_deposit_method_id === 1 && (
          <Box sx={{ gridColumn: 'span 2' }}>
            <Button variant='outlined' component='label' fullWidth>
              Select File
              <input type='file' hidden multiple onChange={(e) => handleFilesChange(e)} />
            </Button>

            {fileSelectedMessage ? (
              <Typography
                variant='body2'
                sx={{
                  fontSize: '.8em',
                  textAlign: 'right',
                  paddingRight: '4px',
                  color:
                    fileSelectedMessage.status === 1
                      ? theme.palette.delete.main
                      : theme.palette.primary.main,
                }}
              >
                {fileSelectedMessage.name}
              </Typography>
            ) : (
              ''
            )}
          </Box>
        )}

        <TextField
          multiline
          rows={3}
          label='Notes'
          variant='outlined'
          name='notes'
          value={formData.notes || ''}
          onChange={handleChange}
          sx={{ gridColumn: { sm: 'span 2' } }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />

        <Box sx={{ textAlign: 'center', gridColumn: { sm: 'span 2' } }}>
          <Button
            sx={{ bgcolor: theme.palette.save.main, width: '30%' }}
            variant='contained'
            // onClick={() => {
            //   if (fileSelectedMessage.status !== 0) {
            //     handleSubmit(); // Only call if the button is not disabled
            //   }
            // }}
            onClick={() => handleSubmit()}
            disabled={
              !formData.deposit_amount ||
              !formData.entity_deposit_method_id ||
              !formData.entity_deposit_type_id ||
              !formData.entity_id ||
              !formData.user_id ||
              //!formData.description ||
              formData.deposit_amount <= 0
            }
          >
            Submit
          </Button>
          <Box sx={{ textAlign: 'right', fontSize: '.8em' }}>{uploadedFileMess}</Box>
        </Box>
      </Box>
    </>
  );
};

//entity_deposit_id, entity_id, entity_deposit_type_id, entity_deposit_method_id, user_id, description, deposit_amount, notes, time_created, deleted, version
//entity_deposit_type_id, entity_deposit_method_id, description, deposit_amount, notes

export default MakeDeposit;
