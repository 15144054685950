import React from 'react';
import { Box } from '@mui/material';
import ManufacturerPie from './ManufacturerPie';
import ManufacturerBanner from './ManufacturerBanner';
import ManufacturerSelect from './ManufacturerSelect';
import ManufacturerLineCharts from './ManufacturerLineCharts';
import ManufacturerTable from './ManufacturerTable';
import DivergentBarChart from './ManufacturerDivergent';
import ManufacturerBar from './ManufacturerBar';

const ManufacturerDashboard = () => {
  return (
    <Box>
      <ManufacturerSelect />
      <ManufacturerBanner />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '32px', // Gap between grid items
          maxWidth: '1650px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ gridColumn: '1/3' }}>
          <ManufacturerLineCharts />
          <ManufacturerTable />
        </Box>
        <Box sx={{ gridColumn: '3/4' }}>
          <ManufacturerPie />
          <DivergentBarChart />
          <ManufacturerBar />
        </Box>
      </Box>
    </Box>
  );
};

export default ManufacturerDashboard;
