import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { MarketingContextState } from '../../../Context/Marketing/MarketingContext';
import ContractStatusBoard from './components/ContractStatusBoard';
import ContractsList from './components/ContractsList';
import OverviewSection from './OverviewSection';
import ContractsListSection from './ContractsListSection/ContractsListSection';

const ContractsPage = () => {
  // CONTEXT STATE
  const { contractDueTypeId, userId } = useContext(MarketingContextState);
  return (
    <Box>
      <OverviewSection />
      <ContractsListSection />
      {/* <ContractStatusBoard />
      <ContractsList /> */}
    </Box>
  );
};

export default ContractsPage;
