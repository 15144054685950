import { Breadcrumbs, Link, Button, Grid2, Typography, styled, Paper, Stack, TextField } from "@mui/material"

import DashboardDrawer from "./DashboardDrawer"
import { useState } from "react"

export default function Dashboard(props) {
    const [drawerOpen, setDrawerOpen] = useState(false)

    const DashboardCard = styled(Paper)(({ theme }) => ({
        backgroundColor:'whitesmoke',
        textAlign:'center',
        margin:1,
        padding:5

    }))

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    return(
        <div>
        <Grid2 
            container 
            spacing={2} 
            columns={{xs: 6, md: 9}}
            alignItems={'center'} 
            display={'flex'} 
            justifyContent={'center'}
        >
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        Quicknav PLACEHOLDER
                    </Typography>
                    <Button variant="underlined" onClick={() => {props.switchPage('facetOptionReplace', 0, 0)}}>
                        Facet Option Replacement
                    </Button>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        INFO PLACEHOLDER
                    </Typography>
                    <Stack direction={'column'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <Typography>Info 1</Typography>
                        <Typography>Info 2</Typography>
                    </Stack>

                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>

                </DashboardCard>
            </Grid2>
        </Grid2>
        
        </div>
    )

}