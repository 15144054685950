import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { pub_data } from './PubImportData';

const ProccessPubImportData = () => {
  useEffect(() => {
    let data = [];

    data = pub_data.map((e) => ({
      publication_code: e.publication_code,
      publication_type_id: e.publication_type_id,
      description: e.description,
      detailed_description: e.detailed_description,
      submit_contract_date: dayjs(e.submit_contract_date).format('YYYY-MM-DD'),
      submit_art_date: dayjs(e.submit_art_date).format('YYYY-MM-DD'),
      production_start_date: dayjs(e.production_start_date).format('YYYY-MM-DD'),
      start_date: dayjs(e.start_date).format('YYYY-MM-DD'),
      expiration_date: dayjs(e.expiration_date).format('YYYY-MM-DD'),
    }));

    console.log(data);
  }, []);
  return <div>ProccessPubImportData</div>;
};

export default ProccessPubImportData;
