import React, { useEffect, useState } from 'react';

// import MostOutstanding from '../../../Manufacturers/components/MostOutstanding';
// import { useContractContext } from '../../../../Context/Contracts/ContractsDashboardContext';
// import ContractCreditMemo from '../ContractCreditMemo/ContractCreditMemo';

// import ContractPayment from '../ContractPayment/ContractPayment';
// import ContractAvailableFunds from '../ContractPayment/components/ContractAvailableFunds';

// IMPORT DATA
import { getAllContractsData } from '../../../_GlobalFunctions/contractFunctions';
import { getAllPublication } from '../../../_GlobalFunctions/publicationFunctions';

//IMPORT COMPONENTS
import BillingStatus from '../../../Manufacturers/components/BillingStatus';
import AllInvoices from '../../../Manufacturers/components/AllInvoices/AllInvoices';
import LoadingSkeletonStack from '../../../_GlobalComponents/LoadingSkeletonStack';

const DashBoardV2 = () => {
  // CONTEXT STATE
  // const { publications, loading } = useContractContext();

  // LOCAL STATE
  const [pageLoading, setPageLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [publications, setPublications] = useState([]);

  // FUNCTIONS
  const fetchData = async () => {
    try {
      const res = await getAllContractsData();
      setContracts(res.filter((e) => e.contract_deleted !== 1));
      const getPub = await getAllPublication();
      setPublications(getPub);
      setPageLoading(false);
    } catch (err) {
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (pageLoading) {
    return <LoadingSkeletonStack />;
  }

  return (
    <>
      hello
      <BillingStatus contracts={contracts} />
      {/* <ContractAvailableFunds user_id={} primary_identifier={10} /> */}
      {/* <MostOutstanding publications={publications} contracts={contracts} /> */}
      {/* <ContractCreditMemo /> */}
      {/* <ContractPayment /> */}
      <AllInvoices publications={publications} contracts={contracts} />
    </>
  );
};

export default DashBoardV2;
