import React, { useContext, useEffect, useState } from 'react';
import { EntityContactContextState } from '../../../../../Context/Marketing/EntityContactContext';
import { Box, Button, MenuItem, TextField, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dayjs from 'dayjs';
import { insertOrUpdateContact } from '../../EntityContactMiddleWare';
import SnackBarAlert from '../../../../_GlobalComponents/SnackBarAlert';
import SnackBarV2 from '../../../../_GlobalComponents/SnackBarV2';
import LoadingSkeletonStack from '../../../../_GlobalComponents/LoadingSkeletonStack';

// PRIMARY FORM
const EntityContactForm = ({ contactId }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  const { allEntityContacts, allStates, allTimeZones, setAllEntityContacts } =
    useContext(EntityContactContextState);

  // LOCAL STATE
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackBarObj, setSnackBarObj] = useState({
    open: false,
    message: 'default message',
    severity: 'success',
  });

  const handleDateChange = (newDate) => {
    setData((prev) => [{ ...prev[0], birthdate: newDate ? newDate.format('YYYY-MM-DD') : null }]);
  };

  const handleClick = async (data) => {
    const response = await insertOrUpdateContact(data);

    const success = () => {
      //setAllEntityContacts();
      setSnackBarObj((prev) => ({
        ...prev,
        open: true,
        message: 'Contact Details, Saved!',
        severity: 'success',
      }));
    };

    const failure = () => {
      setSnackBarObj((prev) => ({
        ...prev,
        open: true,
        message: 'Something Went Wrong! Please Try Again.',
        severity: 'alert',
      }));
    };

    response.data.affectedRows > 0 ? success() : failure();
  };

  const ResetOpen = () => {
    setSnackBarObj((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    const fetch = async () => {
      const contactsData = allEntityContacts.filter((e) => e.entity_contact_id === contactId);
      setData([...contactsData]);
      setLoading(false);
    };

    const emptyValues = () => {
      const tempObj = {
        address_1: null,
        address_2: null,
        attention_line: null,
        birthdate: null,
        city: null,
        country_id: 1000,
        date_created: Dayjs().format('YYYY-MM-DD'),
        deleted: 0,
        entity_contact_id: 0,
        first_name: null,
        image_id: null,
        job_title: null,
        last_name: null,
        latitude: null,
        longitude: null,
        notes: null,
        postal_code: null,
        state_id: null,
        timezone_id: null,
        user_id: null,
      };
      setData([tempObj]);
      setLoading(false);
    };
    contactId > 0 ? fetch() : emptyValues();
  }, [allEntityContacts, contactId]);

  // Show loading state
  if (loading) {
    return <LoadingSkeletonStack />;
  }
  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: '850px',
        border: `solid 4px ${theme.palette.primary.alt}`,
        borderRadius: '24px',
        padding: '24px',
        boxShadow: '4px 4px 12px rgba(0,0,0,.3)',
        backgroundColor: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        sx={{
          color: theme.palette.primary.main,
          mb: '24px',
          fontWeight: 'bold',
          fontSize: '1.3em',
        }}
      >
        Entity Contact Form Managment: ({contactId})
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '16px' }}>
        <TextField
          sx={{ gridColumn: 'span 2' }}
          name='user_id'
          label='User ID'
          type='text'
          value={data[0].user_id}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], user_id: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 2' }}
          name='first_name'
          label='First Name'
          type='text'
          value={data[0].first_name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], first_name: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 2' }}
          name='last_name'
          label='Last Name'
          type='text'
          value={data[0].last_name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], last_name: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 6' }}
          name='address_1'
          label='Street Address'
          type='text'
          value={data[0].address_1}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], address_1: e.target.value }])}
        />
        <TextField
          sx={{ gridColumn: 'span 6' }}
          name='address_2'
          label='Alt Street Address'
          type='text'
          value={data[0].address_2}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], address_2: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 3' }}
          name='city'
          label='City'
          type='text'
          value={data[0].city}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], city: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 2' }}
          select
          name='state_id'
          label='State'
          type='text'
          value={data[0].state_id}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], state_id: e.target.value }])}
        >
          {allStates.map((state) => (
            <MenuItem key={state.state_id} value={state.state_id}>
              {state.description}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          sx={{ gridColumn: 'span 1' }}
          name='postal_code'
          label='Zip Code'
          type='text'
          value={data[0].postal_code}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], postal_code: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 3' }}
          select
          name='timezone_id'
          label='Timezone ID'
          type='text'
          value={data[0].timezone_id}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], timezone_id: e.target.value }])}
        >
          {allTimeZones.map((timezone) => (
            <MenuItem key={timezone.timezone_id} value={timezone.timezone_id}>
              {timezone.description}
            </MenuItem>
          ))}
        </TextField>

        <Box sx={{ gridColumn: 'span 3' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              //value={() => Dayjs(data[0].birthdate)}
              value={data[0].birthdate ? Dayjs(data[0].birthdate) : null}
              onChange={handleDateChange}
              label='Birth Date'
            />
          </LocalizationProvider>
        </Box>

        <TextField
          sx={{ gridColumn: 'span 3' }}
          name='latitude'
          label='Latitude'
          type='text'
          value={data[0].latitude}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], latitude: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 3' }}
          name='longitude'
          label='Longitude'
          type='text'
          value={data[0].longitude}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], longitude: e.target.value }])}
        />

        <TextField
          sx={{ gridColumn: 'span 6' }}
          multiline
          rows={4}
          name='notes'
          label='Notes'
          type='text'
          value={data[0].notes}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setData((prev) => [{ ...prev[0], notes: e.target.value }])}
        />
        <Box sx={{ gridColumn: 'span 6', textAlign: 'right' }}>
          <Button variant='contained' color='success' onClick={() => handleClick(data)}>
            {contactId === 0 ? 'Create Contact' : 'Update Contact'}
          </Button>
        </Box>
      </Box>
      <SnackBarV2 doOpen={snackBarObj.open} ResetOpen={ResetOpen} message={snackBarObj.message} />
    </Box>
  );
};

export default EntityContactForm;
