import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Typography, styled, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useManufacturerContext } from './PerformanceDashboardContext';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.2)',
}));

const ManufacturerLineCharts = () => {
  const {
    categorySeries = [],
    pieFilter,
    topItems,
    monthFilter,
    setMonthFilter,
    salesSeries = [],
    selectedYear,
    setSlice,
    skipAnimation,
    model,
  } = useManufacturerContext();
  const [connectNulls, setConnectNulls] = useState(true);
  const theme = useTheme();
  const uniqueYears = Array.from(new Set(salesSeries.map((item) => item.s_year))) // Collect unique s_year values
    .sort((a, b) => a - b) // Sort the integers in ascending order
    .map((year) => year.toString()); // Convert the sorted integers to strings

  if (uniqueYears.length > 2) {
    var lineChartColors = [
      theme.palette.secondary.main, // Less Saturated 3
      theme.palette.primary.main, // Darkest Blue
      theme.palette.accent.main,
    ];
  } else if (uniqueYears.length > 1) {
    var lineChartColors = [
      theme.palette.primary.main, // Darkest Blue
      theme.palette.accent.main,
    ];
  } else {
    var lineChartColors = [theme.palette.accent.main];
  }

  const roundedCategorySeries = Object.entries(categorySeries).reduce((acc, [index, obj]) => {
    acc[index] = Object.entries(obj).reduce((innerAcc, [key, value]) => {
      innerAcc[key] = typeof value === 'number' ? Math.round(value) : value;
      return innerAcc;
    }, {});
    return acc;
  }, {});

  const objectValues = Object.values(roundedCategorySeries);

  const totalSalesById = Array.from(new Set(categorySeries.flatMap((item) => Object.keys(item)))) // Collect all keys from all objects
    .filter((key) => key !== 's_month') // Exclude 's_month'
    .map((key) => ({
      key,
      totalSales: categorySeries.reduce((sum, row) => sum + (parseFloat(row[key]) || 0), 0), // Sum sales for each key
    }))
    .sort((a, b) => b.totalSales - a.totalSales) // Sort in descending order of sales
    .slice(0, 5); // Limit to top 5 labels

  const totalTopItems = Array.from(new Set(topItems.flatMap((item) => Object.keys(item)))) // Collect all keys from all objects
    .filter((key) => key !== 's_month') // Exclude 's_month'
    .map((key) => ({
      key,
      totalSales: topItems.reduce((sum, row) => sum + (parseFloat(row[key]) || 0), 0), // Sum sales for each key
    }))
    .sort((a, b) => b.totalSales - a.totalSales) // Sort in descending order of sales
    .slice(0, 5); // Limit to top 5 labels

  // Extract the top 5 keys and translate axisX to a number
  const seriesKeys = totalTopItems.map((item) => item.key);
  const seriesKeys2 = totalSalesById.map((item) => item.key);
  const seriesKeys3 = Array.from(new Set(salesSeries.map((item) => item.s_year))) // Collect unique s_year values
    .sort((a, b) => a - b) // Sort the integers in ascending order
    .map((year) => year.toString()); // Convert the sorted integers to strings

  const groupedData = salesSeries.reduce((acc, { s_month, s_year, sales }) => {
    // Find existing month entry or create a new one
    let monthEntry = acc.find((item) => item.s_month === s_month);
    if (!monthEntry) {
      monthEntry = { s_month }; // Initialize with the month
      acc.push(monthEntry);
    }
    // Add sales data for the corresponding year
    monthEntry[s_year] = Math.round(sales);
    return acc;
  }, []);

  //map months to xaxis and convert to MonthName -- should already be using selectedYear in the context datasources
  const groupAxis = groupedData.map((item) => item.s_month);
  const trendMonth = groupAxis.map((month) =>
    new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(0, month - 1))
  );

  const topAxis = topItems.map((item) => item.s_month);
  const topMonth = topAxis.map((month) =>
    new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(0, month - 1))
  );

  const catAxis = categorySeries.map((cat) => cat.s_month);
  const monthName2 = catAxis.map((month) =>
    new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(0, month - 1))
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography
          align='center'
          sx={{
            fontWeight: 'bold',
            fontSize: '1.85em',
            color: theme.palette.primary.main,
          }}
        >
          Trending Sales for
          {pieFilter && !model
            ? ` ${pieFilter.label}`
            : model && pieFilter
            ? ` ${model} ${pieFilter.label}`
            : model
            ? ` ${model}`
            : ' All Items'}
        </Typography>
      </Box>
      <Wrapper>
        <LineChart
          skipAnimation={skipAnimation}
          xAxis={[{ data: trendMonth, scaleType: 'point' }]} // Use months as X-axis
          series={seriesKeys3.map((key, index) => ({
            label: key,
            dataKey: key,
            color: lineChartColors[index],
            connectNulls, // Optional: Handle missing data
          }))}
          dataset={groupedData} // Use the transformed dataset
          onAxisClick={(event, chartClick) => setMonthFilter(chartClick)}
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background
            borderRadius: '10px',
            padding: 1.5,
          }}
          grid={{
            vertical: true,
            style: { stroke: '#000000' }, // Grid styling
          }}
        />
      </Wrapper>
      {/* <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '2em',
          color: theme.palette.primary.main,
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        Top Items for{' '}
        {pieFilter ? ` ${pieFilter.label} ${selectedYear}` : ` All Categories  ${selectedYear}`}
      </Typography>
      <Wrapper sx={{ display: 'flex', flexDirection: 'column' }}>
        <LineChart
          skipAnimation={skipAnimation}
          xAxis={[{ data: topMonth, scaleType: 'point' }]}
          series={seriesKeys.map((key, index) => ({
            dataKey: key,
            label: key, // Label series by ID
            color: lineChartColors[index % lineChartColors.length], // Cycle through colors
            connectNulls, // Optional: Handle missing data
          }))}
          dataset={topItems} // Use the transformed data
          onAxisClick={(event, chartClick) => setMonthFilter(chartClick)}
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background
            borderRadius: '10px',
            padding: 1,
          }}
          grid={{
            vertical: true,
            style: { stroke: '#000000' }, // Grid styling
          }}
        />
      </Wrapper>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          fontSize: '2em',
          color: theme.palette.primary.main,
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        Sales By Category {selectedYear}
      </Typography>
      <Wrapper sx={{ display: 'flex', flexDirection: 'column' }}>
        <LineChart
          skipAnimation={skipAnimation}
          xAxis={[{ data: monthName2, scaleType: 'point' }]}
          series={seriesKeys2.map((key, index) => ({
            dataKey: key,
            label: key, // Label series by ID
            color: lineChartColors[index % lineChartColors.length], // Cycle through colors
            connectNulls, // Optional: Handle missing data
          }))}
          dataset={objectValues} // Use the transformed data
          onAxisClick={(event, chartClick) => setMonthFilter(chartClick)}
          height={400}
          sx={{
            bgcolor: `${theme.palette.primary.main}10`, // Light background
            borderRadius: '10px',
            padding: 1.5,
          }}
          grid={{
            vertical: true,
            style: { stroke: '#000000' }, // Grid styling
          }}
        />
      </Wrapper> */}
    </>
  );
};

export default ManufacturerLineCharts;
