import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Box, Button, Modal, Tooltip, useTheme } from '@mui/material';
import Form from './Form';

const EditButton = ({ publicationData }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title={`Id# ${publicationData.ss_publication_id}`}>
        <Button
          variant='contained'
          color='warning'
          sx={{ fontWeight: 'bold', borderRadius: '16px' }}
          startIcon={<Edit />}
          onClick={() => handleOpen()}
        >
          Edit
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby='Contracts'
        aria-describedby="Contact's Contracts"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: `${theme.palette.primary.light}33`,
        }}
      >
        <Box sx={{ width: '90%', maxWidth: '650px' }}>
          <Form publicationData={publicationData} />
        </Box>
      </Modal>
    </>
  );
};

export default EditButton;
