import { Icon, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SimpleMenuButtonDropdown = ({ color, icon, children }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  // FUNCTIONS
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <Icon sx={{ color: color, cursor: 'pointer' }} onClick={(event) => handleClick(event)}>
        {icon}
      </Icon>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Options: 'top', 'bottom', 'center', a number (px), or a percentage
          horizontal: 'left', // Options: 'left', 'center', 'right', a number (px), or a percentage
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default SimpleMenuButtonDropdown;
